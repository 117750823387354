import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import CardContent from '@mui/material/CardContent';
import { BarChart } from '@mui/x-charts/BarChart';
import { API_URL } from '../../constants';
import Typography from '@mui/material/Typography';

const green = {
    50: '#E8F8F5',
    100: '#D4EFDF',
    200: '#7DCEA0',
    300: '#afb8c1',
    400: '#52BE80',
    500: '#27AE60',
    600: '#229954',
    700: '#1E8449',
    800: '#196F3D',
    900: '#0B5345',
    1000: '#08362D',
};

const StyledTabsList = styled('div')(
    ({ theme }) => `
        min-width: 900px;
       --background-color: ${green[100]};
        border-radius: 12px;
        --margin: 6px;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        align-content: space-between;
        box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? '#32383f' : '#d0d7de'};
    `
);

const BarChartSeriesComp = () => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        fetchPagamentos();
    }, []);

    const fetchPagamentos = async () => {
        try {
            const response = await fetch(API_URL + 'pagamento');
            if (!response.ok) {
                throw new Error('Erro ao buscar pagamentos');
            }
            const data = await response.json();
            console.log('Dados de pagamentos:', data);

            const newRows = data.map((pagamento) => ({
                id: pagamento.id,
                colegio: pagamento.colegio,
                inadimplente: pagamento.nomeResponsavelFinanceiro,
                nomeFilho: pagamento.aluno,
                valorAtualizado: parseFloat(pagamento.valorAtualizado), // Converte para número
                parcelas: pagamento.debitos.map((debito) => ({
                    ordem: debito.ordem,
                    dataPagamento: new Date(debito.dataPagamento).toLocaleDateString(),
                    valor: debito.valor,
                    conferido: debito.conferido,
                    upload: debito.upload // Ajustado conforme necessário
                }))
            }));

            setRows(newRows);
            console.log("Dados atualizados:", newRows);

        } catch (error) {
            console.error('Erro ao buscar pagamentos:', error);
            // Tratar erro conforme necessário
        }
    };

    const mesesLabels = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];

    const calcularValoresPorMes = (rows, mesesLabels) => {
        const valoresPorMes = Array.from({ length: 12 }, () => 0);

        rows.forEach((pagamento) => {
            pagamento.parcelas.forEach((parcela) => {
                const mes = new Date(parcela.dataPagamento).getMonth();
                valoresPorMes[mes] += parcela.valor;
            });
        });

        console.log("Valores por Mês:", valoresPorMes);

        return valoresPorMes.map((valor, index) => ({ x: mesesLabels[index], y: valor }));
    };

    const dadosPorMes = calcularValoresPorMes(rows, mesesLabels);
    const colegio =  'COLÉGIO EVANGÉLICO PASTOR JOSÉ QUIRINO LTDA';

    console.log("Dados Por Mês:", dadosPorMes);

    return (
        <CardContent sx={{ m: 1 }}>
            <Typography variant="h6" gutterBottom>
                {colegio}
            </Typography>
            <StyledTabsList>
                <BarChart
                    title="Gráfico de Barras"
                    series={[
                        {
                            data: rows.reduce((acc, pagamento) => {
                                if (pagamento.colegio === colegio) {
                                    pagamento.parcelas.forEach((parcela) => {
                                        const mes = new Date(parcela.dataPagamento).getMonth();
                                        acc[mes] += parcela.valor;
                                    });
                                }
                                return acc;
                            }, Array.from({ length: 12 }, () => 0)),
                            stack: 'Valor Total',
                            label: 'Valor Total',
                            color: 'rgb(46, 150, 255)'

                        },
                        {
                            data: rows.reduce((acc, pagamento) => {
                                if (pagamento.colegio === colegio) {
                                    pagamento.parcelas.forEach((parcela) => {
                                        if (parcela.conferido) {
                                            const mes = new Date(parcela.dataPagamento).getMonth();
                                            acc[mes] += parcela.valor;
                                        }
                                    });
                                }
                                return acc;
                            }, Array.from({ length: 12 }, () => 0)),
                            stack: 'Recebidas',
                            label: 'Recebidas',
                            color:  green[500]

                        },
                        {
                            data: rows.reduce((acc, pagamento) => {
                                if (pagamento.colegio === colegio) {
                                    pagamento.parcelas.forEach((parcela) => {
                                        if (!parcela.conferido) {
                                            const mes = new Date(parcela.dataPagamento).getMonth();
                                            acc[mes] += parcela.valor;
                                        }
                                    });
                                }
                                return acc;
                            }, Array.from({ length: 12 }, () => 0)),
                            stack: 'Em Aberto',
                            label: 'Em Aberto',
                            color:  "#FFBF00"

                        },
                        {
                            data: rows.reduce((acc, pagamento) => {
                                if (pagamento.colegio === colegio) {
                                    pagamento.parcelas.forEach((parcela) => {
                                        if (!parcela.conferido && new Date(parcela.dataPagamento) < new Date()) {
                                            const mes = new Date(parcela.dataPagamento).getMonth();
                                            acc[mes] += parcela.valor;
                                        }
                                    });
                                }
                                return acc;
                            }, Array.from({ length: 12 }, () => 0)),
                            stack: 'Em Atraso',
                            label: 'Em Atraso',
                            color:  "#ae0c00"

                        }
                    ]}
                    height={300} // Aumento da altura para melhor visualização das barras
                    width={700}
                    barLabel="value"
                    xAxis={[{ data: mesesLabels, scaleType: 'band' }]}
                    tooltip={{
                        renderer: (tooltipProps) => {
                            const { index, value } = tooltipProps;
                            const mes = mesesLabels[index];
                            return (
                                <div style={{ background: 'white', padding: '10px', border: '1px solid #ccc' }}>
                                    <Typography variant="body2" align="center">
                                        <strong>{mes}</strong>
                                    </Typography>
                                    <Typography variant="body2" align="center">
                                        Valor: R$ {value.toFixed(2)} {/* Formatação do valor */}
                                    </Typography>
                                </div>
                            );
                        }
                    }}
               
                />
            </StyledTabsList>
        </CardContent>
    );
};

export default BarChartSeriesComp;
