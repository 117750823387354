import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { API_URL } from '../../constants';


function handleSaveClick(){
  console.log("Ola");
}


export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}


const columns = [

  { 
    field: 'nomeEscritorio',
   headerName: 'Nome do Escritório',
    width: 150,
    editable: true
  },
  {
    field: 'emailEscritorio',
    headerName: 'Email do Escritório',
    width: 150,
    editable: true,
  }, 
  {
    field: 'cnpjEscritorio',
    headerName: 'Cnpj',
    width: 200,
    editable: true,
  },
  {
    field: 'enderecoEscritorio',
    headerName: 'Endereço',
    width: 200,
    editable: true,
  },
  {
    field: 'numeroEscritorio',
    headerName: 'Endereço',
    width: 200,
    editable: true,
  },
  {
    field: 'cepEscritorio',
    headerName: 'Cep',
    width: 200,
    editable: true,
  },
  {
    field: 'estadoEscritorio',
    headerName: 'Estado',
    width: 200,
    editable: true,
  },
  {
    field: 'cidadeEscritorio',
    headerName: 'Estado',
    width: 200,
    editable: true,
  },
  {
    field: 'pastaEscritorioGoogleDrive',
    headerName: 'Pasta do Google Drive',
    width: 200,
    editable: true,
  },
  {
    field: 'idPastaEscritorioGoogleDrive',
    headerName: 'idPastaEscritorioGoogleDrive',
    width: 200,
    editable: true,
  },
  {
    field: 'logoGoogleDrive',
    headerName: 'Logo',
    width: 200,
    editable: true,
  },
  {
    field: 'idLogoGoogleDrive',
    headerName: 'idLogoGoogleDrive',
    width: 200,
    editable: true,
  },
  {
    field: 'pastaColegiosGoogleDrive',
    headerName: 'pastaColegiosGoogleDrive',
    width: 200,
    editable: true,
  },

  {
    field: 'idPastaColegiosGoogleDrive',
    headerName: 'idPastaColegiosGoogleDrive',
    width: 200,
    editable: true,
  }

  
];


const DataGridEscritorio = () => {

  const [tableData, setTableData] = useState([])

  useEffect(() => { 
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('GET', 'POST', 'OPTIONS');
 
    console.log(API_URL + "escritorios",{
      method: 'GET',
      mode: 'no-cors',
      headers: headers,
      allowedHeaders: 'Content-Type'
    });


    fetch(API_URL + "escritorios",{
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // Adicione outros cabeçalhos necessários aqui
      }
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))
    
  }, [])
  console.log("DATA ARQUIVO");
  console.log(tableData)

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
     <Button size="small" variant="outlined" onClick={DataGridEscritorio} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Atualiza</Button>

    </div>
  )
}

export default DataGridEscritorio

 




 
