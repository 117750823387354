import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { gapi } from 'gapi-script';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { API_URL } from '../../../constants';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Inadimplente from '../../../DTO/Inadimplente';



const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES = 'https://www.googleapis.com/auth/documents';
let colegio;


export default class MergeAndEditDocument {
  //  export default function MergeAndEditDocument(rootURL, colegio, inadimplente, aluno) {


  async mergeDataInDocument(params, open, colegio) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const fileId = '1dAi4C1vf-BGKAwjjLRujxim70XNFCldoNw9MNPgKS6s';

    console.log(params)

    let req = new Array();



    for (let i = 0; i < params.length; i++) {
    
      if (!(params[i].row.colegio && params[i].row.nomeResponsavelFinanceiro && params[i].row.aluno)) {
        console.log("ERRO EM : ");

      console.log(params[i].row.colegio + params[i].row.nomeResponsavelFinanceiro +  params[i].row.aluno);
        return;
      }

      //colegio = params[i].row.colegio;
 
      console.log(API_URL + "contrato/" + params[i].row.colegio + "/" + params[i].row.nomeResponsavelFinanceiro + "/" + params[i].row.aluno);
      const resposta = await fetch(API_URL + "contrato/" + params[i].row.colegio + "/" + params[i].row.nomeResponsavelFinanceiro + "/" + params[i].row.aluno, {
        method: 'GET',
        mode: 'cors'
      })
        .then((response) => response.json() )
        .then((json) => {
          let contrato = json;
          if (!contrato || !contrato.aluno || !contrato.inadimplente || !contrato.inadimplente.nomeResponsavelFinanceiro) {
            console.log("PROBLEMA NA CHAMADA: " +  params[i].row.nomeResponsavelFinanceiro);  
            console.log('CONTRATO');
            console.log(contrato);
            return;
          }
          req.push({
            replaceAllText: {
              containsText: {
                text: '<<Nome Completo' + [i] + '>>', // Texto a ser substituído
                matchCase: false,
              },
              replaceText: contrato.inadimplente.nomeResponsavelFinanceiro, // Novo texto a ser inserido
            },
          },
            {
              replaceAllText: {
                containsText: {
                  text: '<<Endereço' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.endereco, // Novo texto a ser inserido
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<número' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.numero, // Novo texto a ser inserido
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<complemento' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.complemento, // Novo texto a ser inserido
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<Bairro' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.bairro, // Novo texto a ser inserido
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<CEP' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.cep, // Novo texto a ser inserido
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<Cidade' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.cidade,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<Estado' + [i] + '>>', // Texto a ser substituído
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.estado,
              },
            },
          );
        })
    }   
    console.log(req);


    // const rootFolderId = params[i].row.urlRootInadimplente;
    const requestData = {
      title: 'Etiqueta - ' + colegio,
      name: 'Etiquetas - ' + colegio,
      //parents: ['1q8bqAKT2rWWd4YS8wox5sxeJKkOUGfwM'],
      parents: ['1Huk5xvm_WynB7xaUg7W_6CeqWXnf7ghW'],
    };


    ///////////CHAMA API GOOGLEAPI
    fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((val) => {
        const newDocumentId = val.id; // ID do novo documento criado
        gapi.client.load('https://docs.googleapis.com/$discovery/rest?version=v1')
          .then(() => {
            // Substituir variáveis no documento
            gapi.client.docs.documents.batchUpdate({
              documentId: newDocumentId,
              requests: req

            })

              .then(function (response) {
                // Manipule a resposta aqui
                if (open != "FECHADO") {
                  console.log('pagina criaca');
                  console.log(response.result.documentId);
                  const newWindow = window.open("https://docs.google.com/document/d/" + response.result.documentId, '_blank');
                  //  pdfMake.createPdf("https://docs.google.com/document/d/" + response.result.documentId).open({}, window.open('', '_blank'));

                }
                console.log("Variável substituída com sucesso", response);
              })

          });

      });

    }

 
 }
