import React from "react";
import ReceberAR from "../DTO/ReceberAR";
import Requests from "../api/requests";


let receberAR = new ReceberAR();
let req = new Requests();

export default class CrudReceberAR {
  constructor(value) {
    this.receberAR = value;
  }
  cadastraReceberARAPI(param) {


    if (param != null) {
      let req = new Requests();
      const { v4: uuidv4 } = require('uuid');
      
      param.forEach(function (event, index) {
        if(event.nomeResponsavelFinanceiro!=null){
          console.log("ENVIO RECEBER AR");
          console.log(JSON.stringify({
            id: uuidv4(),          
            nomeResponsavelFinanceiro: event.nomeResponsavelFinanceiro,
            colegio: event.colegio,
            aluno: event.aluno,
            dataEnvio: event.dataEnvio,
            dataRecebimento: event.dataRecebimento,
            idPastaGoogle: event.urlRootInadimplente,
          }));

          setTimeout(function () {


        req.requestPOSTparam(JSON.stringify({
          id: uuidv4(),          
          nomeResponsavelFinanceiro: event.nomeResponsavelFinanceiro,
          colegio: event.colegio,
          aluno: event.aluno,
          dataEnvio: event.dataEnvio,
          dataRecebimento: event.dataRecebimento,
          idPastaGoogle: event.urlRootInadimplente,
        }), 'receberAR')}   , index * 5000);
      }
      });

    }
  }

}