
import { FormControl } from '@mui/base/FormControl';
import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import DataGridInadimplente from '../datagrids/DataGridCadastroInadimplente';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import SocioColegio from '../../DTO/SocioColegio';
import CrudSocioColegio from '../../Service/CrudSocioColegio';
import Autocomplete from '@mui/material/Autocomplete';
import ValidaTextField from '../Helper/ValidaTextField.js';
import DataGridCadastraSocioColegio from '../datagrids/DataGridCadastroSocioColegio';


import BusinessIcon from '@mui/icons-material/Business';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArticleIcon from '@mui/icons-material/Article';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
const Listacolegio = [
  { label: 'Colegio Santa Marina', id: 1 },
  { label: 'Colegio Augusto Maia', id: 2 },

]

const estadosCivil = [
  { label: 'solteiro(a)', id: 1 },
  { label: 'casado(a)', id: 2 },
  { label: 'divorciado(a)', id: 3 },
  { label: 'viuvo(a)', id: 4 }
];


const nacionalidade = [
  { label: 'brasileiro(a)', id: 1 },
  { label: 'Estrangeiro(a)', id: 2 },

]
const genero = [
  { label: 'feminino', id: 1 },
  { label: 'masculino', id: 2 },

]
const cidade = [
  { label: 'São Paulo', id: 1 },
  { label: 'Rio de Janeiro', id: 2 },

]
const estado = [
  { label: 'São Paulo', id: 1 },
  { label: 'Rio de Janeiro', id: 2 },

]
const socioColegio = new SocioColegio;
const crudSocioColegio = new CrudSocioColegio();

function validaEmail(email) {
  const validacao = new ValidaTextField("");

  validacao.validacaoEmail(email)
  socioColegio.setemail(email);

}

function CadastraSocioColegio() {
  crudSocioColegio.socioColegioAPI(socioColegio);

}

function CepSearch() {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState({});

  const fetchCepData = () => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        setAddress(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  socioColegio.setcep(address.cep);
  socioColegio.setendereco(address.logradouro);
  socioColegio.setbairro(address.bairro);
  socioColegio.setcidade(address.localidade);
  socioColegio.setestado(address.uf);

  const limpaCep = () => {
    setAddress("");
    setCep("");
  };


  return (
    <div>
      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
        variant="standard"
        sx={{ width: '30%', marginTop:1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button variant="standard" onClick={fetchCepData}
        sx={{ marginTop: 3, marginLeft: -2, justifyContent: "center", width: "5%" }}
        color="success" endIcon={<SendIcon />}> </Button>

      <TextField
        label="Numero do Endereço"
        color='secondary'
        value={socioColegio.getnumero()}
        onChange={e => socioColegio.setnumero(e.target.value)}
        variant="standard"
        sx={{ width: '25%', marginLeft: 2, marginTop:1 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Complemento"
        color='secondary'
        value={socioColegio.getcomplemento()}
        onChange={e => socioColegio.setcomplemento(e.target.value)}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '30%', marginLeft: 2, marginTop:1}}
      />

      <div>
        {address.cep && (
          <div>
            <br />
            <h6>Informações do Endereço:</h6>
            <p>CEP: {address.cep}</p>
            <p>Logradouro: {address.logradouro}</p>
            <p>Bairro: {address.bairro}</p>
            <p>Cidade: {address.localidade}</p>
            <p>Estado: {address.uf}</p>

          </div>
        )}

      </div>
    </div>


  );
}



export default function CadastroSocioColegio() {


  const [colegios, setColegios] = useState([]); // Initialize with empty array

  useEffect(() => {
    async function fetchEscritorios() {
      const colegiosData = await crudSocioColegio.buscacolegioAPI();
      if (colegiosData) {
        setColegios(colegiosData);
      }
    }
    fetchEscritorios();
  }, []); // Run once on component mount




  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",

    }} >
      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',
      }} >
        <Box sx={{
          width: '50%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2

        }} >
          <FormControl defaultValue="">
            <Stack spacing={0} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 3, align: "Center" }}>
              <h5>Cadastro Socio(a) do Colégio</h5>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                color='secondary'
                options={colegios}
                onChange={(e, value) => socioColegio.setcolegio(value && value.label ? value.label : " ")}
                sx={{ width: "100%", fontGrid: "small", marginBottom: 1 }}
                renderInput={(params) => <TextField {...params} label="Colegio" variant='standard' />}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Nome do Sócio"
                onChange={e => socioColegio.setnome(e.target.value)}
                value={socioColegio.getnome()}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
              />

              <Box sx={{ display: "flex" }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={nacionalidade}
                  onChange={(e, value) => socioColegio.setnacionalidade(value && value.label ? value.label :  "brasileiro(a)")}
                  sx={{ width: "100%", marginRight: 2, fontGrid: "small" }}
                  renderInput={(params) => <TextField {...params} label="Nacionalidade" variant='standard' />}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="RG"
                  onChange={e => socioColegio.setrg(e.target.value)}
                  value={socioColegio.getrg()}
                  sx={{ width: "100%", marginRight: 2, marginBottom: 1, fontGrid: "small" }}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="CPF"
                  onChange={e => socioColegio.setcpf(e.target.value)}
                  value={socioColegio.getcpf()}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            
              <Box sx={{ display: "flex", marginTop: 1 }}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={genero}
                  onChange={(e, value) => socioColegio.setgenero(value && value.label ? value.label : " ")}
                  sx={{ width: "100%", fontGrid: "small", marginRight: 2 }}
                  renderInput={(params) => <TextField {...params} label="Genero" variant='standard' />}
                  fullWidth
                  required
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={estadosCivil}
                  onChange={(e, value) => socioColegio.setestadoCivil(value && value.label ? value.label :  "casado(a)")}
                  sx={{ width: "100%", fontGrid: "small", marginRight: 2 }}
                  renderInput={(params) => <TextField {...params} label="Estado Civil" variant='standard' />}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Profissão"
                  onChange={e => socioColegio.setprofissao(e.target.value)}
                  value={socioColegio.getprofissao()}
                  fullWidth
                  required
                />
              </Box>
   

              <Box sx={{ justifyContent: "center", width: "100%" }} >
                <Button variant="outlined" onClick={CadastraSocioColegio} sx={{ marginTop: 3, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>
                <Button variant="outlined" onClick={CadastraSocioColegio} sx={{ marginTop: 3, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>
            </Stack>

          </FormControl>

        </Box>
        <Box sx={{ justifyContent: "right", width: "25%", position: "relative", flexGrow: 2 }} >
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%" }}
            image="https://mediavueltadigital.com/wp-content/uploads/2020/07/concepto-de-administrador-1-1.png"
          />
        </Box>
      </Card>


      <Divider variant="middle" sx={{ margin: 2 }} />




      <Card variant="outlined">
        <Box component="bxgridCadastroAdv" sx={{
          margin: "0px",
          p: 0,
          height: '90%',
          width: '30%%',
          justifyContent: 'center',
          background: '#fff'
        }} >

          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h5>Inadimplentes Cadastrados</h5>

            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DataGridCadastraSocioColegio />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>

  );

}


        /*      <Box sx={{ display: "flex" }}>
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="WhatsApp"
                  onChange={e => socioColegio.setwhatsapp(e.target.value)}
                  value={socioColegio.getwhatsapp()}
                  sx={{ width: "60%", marginRight: 2, marginBottom: 1, marginTop: 1 }}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WhatsAppIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Telefone"
                  onChange={e => socioColegio.settelefone(e.target.value)}
                  value={socioColegio.gettelefone()}
                  sx={{ width: "60%", marginBottom: 1, marginTop: 1 }}
                  fullWidth
                  required
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocalPhoneIcon />
                      </InputAdornment>
                    ),
                  }}
                />
          </Box>    
                     <CepSearch />
          
            <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Email"
                onBlur={e => validaEmail((e.target.value))}
                value={socioColegio.getemail()}
                fullWidth
                required
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
              />
          */
