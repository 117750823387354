import * as React from 'react';
import { styled } from '@mui/system';
import {Tabs} from '@mui/base/Tabs';
import {TabsList} from '@mui/base/TabsList';
import {TabPanel} from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import {Tab} from '@mui/base/Tab';
import { tabClasses } from '@mui/base/Tab';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CadastroAdvogado from './TabCadastrosAdministrativos/CadastroAdvogado';
import CadastroColegio from './TabCadastrosAdministrativos/CadastroColegio';
import CadastroEscritorio from './TabCadastrosAdministrativos/CadastroEscritorioAdvocacia';
import CadastroIndice from './TabCadastrosAdministrativos/CadastroIndice';
import CadastraSocioColegio from './TabCadastrosAdministrativos/CadastroSocioColegio'; 
import CadastroSteps from './TabCadastrosAdministrativos/CadastroSteps'; 



const card1 = (
    <React.Fragment>
        <CardContent> <CadastroEscritorio /></CardContent>
    </React.Fragment>
);

const card2 = (
    <React.Fragment>
        <CardContent>  <CadastroAdvogado /></CardContent>
    </React.Fragment>
);

const card3 = (
    <React.Fragment>
        <CardContent> <CadastroColegio /></CardContent>
    </React.Fragment>
);
const card4 = (
    <React.Fragment>
    <CardContent> 

    <CadastraSocioColegio /></CardContent>
    </React.Fragment>
)
const card5 = (
    <React.Fragment>
        <CardContent> <CadastroIndice /></CardContent>
    </React.Fragment>
);
const card6 = (
    <React.Fragment>
        <CardContent> <CadastroSteps /></CardContent>
    </React.Fragment>
);



export default function TabCadastrosIniciais() {

   /* const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };
*/
    return (

        <Card sx={{  width: '100%', m:0 }}>
            <CardContent sx={{   m:0  }}>
                <Tabs defaultValue={1} sx={{  width: '100%', m:0  }}>
                    <StyledTabsList>
                        <StyledTab value={1}>Cadastro Escritório</StyledTab>
                        <StyledTab value={2}>Cadastro Advogados</StyledTab>
                        <StyledTab value={3}>Cadastro Colégios </StyledTab>
                        <StyledTab value={4}>Cadastro Sócio do Colégio</StyledTab>
                        <StyledTab value={5}>Cadastro Indice</StyledTab>
                    </StyledTabsList>
                    <StyledTabPanel value={1}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card1}</Card></Box>
                    </StyledTabPanel>
                    <StyledTabPanel value={2}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card2}</Card></Box>
                    </StyledTabPanel>
                    <StyledTabPanel value={3}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card3}</Card></Box>
                    </StyledTabPanel>
                    <StyledTabPanel value={4}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card4}</Card></Box>
                    </StyledTabPanel>
                    <StyledTabPanel value={5}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card5}</Card></Box>
                    </StyledTabPanel>
                 
                </Tabs>

            </CardContent>

        </Card>




    );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const green = {

    50: '#E8F8F5',
    100: '#D4EFDF',
    200: '#7DCEA0',
    300: '#afb8c1',
    400: '#52BE80',
    500: '#27AE60',
    600: '#229954',
    700: '#1E8449',
    800: '#196F3D',
    900: '#0B5345',
    1000: '#08362D',
}





const StyledTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 3px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${green[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.675rem;

`;

const StyledTabsList = styled(TabsList)(
    ({ theme }) => `
  min-width: 900px;
  background-color: ${green[800]};
  border-radius: 12px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);