
import { FormControl } from '@mui/base/FormControl';
import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import { Advogado } from '../../DTO/Advogado.js';
import Box from '@mui/material/Box';
import DatagridCadastroAdv from "../datagrids/DataGridCadastroAdvogado.js";
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CardMedia from '@mui/material/CardMedia';
import CrudAdvogado from '../../Service/CrudAdvogado.js';
import Autocomplete from '@mui/material/Autocomplete';
import ValidaTextField from '../Helper/ValidaTextField.js';
import crudEscritorioAdvocacia from '../../Service/CrudEscritorio.js';
import BusinessIcon from '@mui/icons-material/Business';

import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ArticleIcon from '@mui/icons-material/Article';

const adv = new Advogado;
const crudadv = new CrudAdvogado();
const crudescr = new crudEscritorioAdvocacia();

function validaEmail(email) { 
  const validacao = new ValidaTextField("");
  validacao.validacaoEmail(email)
  adv.setEmailAdvogado(email);

}

function CepSearch() {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState({});

  const fetchCepData = () => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        setAddress(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  adv.setcep(address.cep);
  adv.setEndereçoAdvogado(address.logradouro);
  adv.setbairro(address.bairro);
  adv.setcidade(address.localidade);
  adv.setestado(address.uf);

  const limpaCep = () => {
    setAddress("");
    setCep("");
  };


  return (
    <div>
      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
        variant="standard"
        sx={{ width: '30%' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <Button variant="standard" onClick={fetchCepData}
        sx={{ marginTop: 2, marginLeft: 0, justifyContent: "center", width: "5%" }}
        color="success" endIcon={<SendIcon />}> </Button>
      <TextField
        label="Numero do Endereço"
        color='secondary'
        value={adv.getnumero()}
        onChange={e => adv.setnumero(e.target.value)}
        variant="standard"
        sx={{ width: '20%', marginLeft: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Complemento"
        color='secondary'
        value={adv.getcomplemento()}
        onChange={e => adv.setcomplemento(e.target.value)}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '30%', marginLeft: 2 }}
      />
      <div>
        {address.cep && (
          <div> <br />
            <h6>Informações do Endereço:</h6>
            <p>CEP: {address.cep}</p>
            <p>Logradouro: {address.logradouro}</p>
            <p>Bairro: {address.bairro}</p>
            <p>Cidade: {address.localidade}</p>
            <p>Estado: {address.uf}</p>

          </div>
        )}

      </div>
    </div>


  );
}


function cadastraAdvogado() {
  crudadv.cadastraAdvogadoAPI(adv);
}

function limpaform() {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
  );
}

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const estadosCivil = [
  { label: 'solteiro(a)', id: 1 },
  { label: 'casado(a)', id: 2 },
  { label: 'divorciado(a)', id: 3 },
  { label: 'viuvo(a)', id: 4 }
];


const nacionalidade = [
  { label: 'brasileiro(a)', id: 1 },
  { label: 'Estrangeiro(a)', id: 2 },

]

/*
const escritoriosAdvogados= [
  { label: 'Elizabeth Ribeiro Curi Advogados', id: 1 },
  { label: 'Fudaba & Curi Advogados', id: 2 },

] */



const genero = [
  { label: 'masculino', id: 1 },
  { label: 'feminino', id: 2 },
  { label: 'transgenero', id: 3 },

]


export default function CadastroAdvogado() {



  const cargo = [
    { label: 'advogado', id: 1 },
    { label: 'advogada', id: 1 },

  ]


  const [escritoriosAdvogados, setEscritoriosAdvogados] = useState([]); // Initialize with empty array

  useEffect(() => {
    async function fetchEscritorios() {
      const escritoriosData = await crudescr.buscaEscritoriosAPI();
      if (escritoriosData) {
        setEscritoriosAdvogados(escritoriosData);
      }
    }
    fetchEscritorios();
  }, []); // Run once on component mount



  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",
    }} >

      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',
      }} >
        <Box sx={{
          width: '50%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2
        }} >
          <FormControl defaultValue="">
            <Stack spacing={0.4} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5, align: "Center" }}>
              <h5>Cadastro Advogado</h5>
              <br /><br />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Nome Advogado"
                onChange={e => adv.setNomeAdvogado(e.target.value)}
                value={adv.getNomeAdvogado()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  )
                }}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Email Advogado"
                onBlur={e => validaEmail((e.target.value))}
                value={adv.getEmailAdvogado()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                require
              />
              <Box>
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="OAB"
                  onChange={e => adv.setOAB(e.target.value)}
                  value={adv.getOAB()}
                  sx={{ width: "30%", fontGrid: "small", marginRight: 3 }}
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="WhatsApp"
                  sx={{ width: "30%", fontGrid: "small", marginRight: 3 }}
                  onChange={e => adv.setWhatsApp(e.target.value)}
                  value={adv.getWhatsApp()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WhatsAppIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="CPF"
                  sx={{ width: "30%", fontGrid: "small", marginRight: 0 }}
                  onChange={e => adv.setCpfAdvogado(e.target.value)}
                  value={adv.getCpfAdvogado()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ArticleIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                /></Box>
              <Box sx={{ display: 'flex', width: "100%" }}   >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={genero}
                  sx={{ width: "30%", fontGrid: "small", marginRight: 2 }}
                  onChange={(e, value) => adv.setgenero(value && value.label ? value.label: "indefinido")}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Genero "
                    variant='standard'
                    sx={{ width: "100%", fontGrid: "small" }} />}
                  required
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={cargo}
                  sx={{ width: "30%", fontGrid: "small", marginRight: 2 }}
                  onChange={(e, value) => { adv.setcargo(value && value.label ? value.label: "advogado(a)") }}
                  renderInput={(params) => <TextField {...params} sx={{ width: "100%", fontGrid: "small" }} label="Cargo " variant='standard' />}
                  required
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={estadosCivil}
                  sx={{ width: "35%", fontGrid: "small" }}
                  onChange={(e, value) => adv.setEstadoCivil(value && value.label ? value.label: "casado(a)")}
                  renderInput={(params) => <TextField {...params} sx={{ width: "100%", fontGrid: "small" }} label="Estado Civil" variant='standard' />}
                  required
                />
              </Box>
              <Box sx={{ display: 'flex', width: "100%" }}   >
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={nacionalidade}
                  onChange={(e, value) => adv.setNacionalidade(value && value.label ? value.label: "brasileiro(a)")}
                  sx={{ width: "30%", fontGrid: "small", marginRight: 2 }}
                  renderInput={(params) => <TextField {...params} label="Nacionalidade" variant='standard' />}
                  fullWidth
                  required
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={escritoriosAdvogados}
                  onChange={(e, value) => adv.setEscritorio(value && value.label ? value.label : "Elizabeth Ribeiro Curi Advogados")}
                  sx={{ width: "70%", fontGrid: "small" }}
                  renderInput={(params) => <TextField {...params} label="Escritório de Advocacia" variant='standard' />}
                  fullWidth
                  required
                />
              </Box>
              <CepSearch />
              <Box sx={{ justifyContent: "center", width: "100%" }} >
                <Button variant="outlined" onClick={cadastraAdvogado} sx={{ marginTop: 5, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>
                <Button variant="outlined" onClick={limpaform} sx={{ marginTop: 5, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>
            </Stack>
          </FormControl>

        </Box>
        <Box sx={{ justifyContent: "right", width: "30%", position: "relative", flexGrow: 2 }} >
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "80%" }}
            image="https://uploaddeimagens.com.br/images/004/560/098/original/lawyer.jpg?1690564013"
            alt="Live from space album cover"
          />
        </Box>
      </Card>

      <Divider variant="middle" sx={{ margin: 2 }} />



      <Card variant="outlined">

        <Box component="Bxgridcadastroadv" sx={{
          margin: "0px",
          p: 3,
          height: '90%',
          width: '100%',
          justifyContent: 'center',
          background: '#fff'
        }} >


          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h2>Advogados Cadastrados</h2>

            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DatagridCadastroAdv />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>

  );

}
