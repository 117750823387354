import { DataGrid } from '@mui/x-data-grid';
import Requests from "../../api/requests";
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';



function handleSaveClick(){
  console.log("Ola");
}

let req = new Requests();

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}


const columns = [

  { 
    field: 'nomeColegio',
   headerName: 'Nome do Colegio',
    width: 300,
    editable: true
  },

  {
    field: 'enderecoColegio',
    headerName: 'Endereço do Colegio',
    width: 200,
    editable: true,
  },
  {
    field: 'cnpjColegio',
    headerName: 'CNPJ do Colegio',
    width: 150,
    editable: true,
  },
  {
    field: 'logoColegio',
    headerName: 'Logo do Colegio',
    width: 150,
    editable: true,
  },
  {
    field: 'escritorio',
    headerName: 'Escritorio Vinculado',
    width: 350,
    editable: true,
  }
];


const DataGridColegio = () => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "colegios",{
      method: 'GET',
      allowedHeaders: 'Content-Type'
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])
  console.log("tableData");
  console.log(tableData);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridColegio

 




 /*  {
    field: 'emailColegio',
    headerName: 'Email do Colegio',
    width: 150,
    editable: true,
  },*/
