import React from "react";

let id;
let colegioAluno;
let inadimplenteAluno;
let nomeAluno;
let codigoAluno;
let turmaAluno;
let dataEnsino;
let generoAluno;


export class Aluno {



    constructor() {
  
    }


    //id;
    getid() {
        return this.id;
    }
    setid(value) {
        this.id = value;
    }
    //colegioAluno;
    getcolegioAluno() {
        return this.colegioAluno;
    }
    setcolegioAluno(value) {
        this.colegioAluno = value;
    }
    //inadimplenteAluno;
    getinadimplenteAluno() {
        return this.inadimplenteAluno;
    }
    setinadimplenteAluno(value) {
        this.inadimplenteAluno = value;
    }
    //nomeAluno;
    getnomeAluno() {
        return this.nomeAluno;
    }
    setnomeAluno(value) {
        this.nomeAluno = value;
    }
    //codigoAluno;
    getcodigoAluno() {
        return this.codigoAluno;
    }
    setcodigoAluno(value) {
        this.codigoAluno = value;
    }
    //turmaAluno;
    getturmaAluno() {
        return this.turmaAluno;
    }
    setturmaAluno(value) {
        this.turmaAluno = value;
    }
    //generoAluno;
    getgeneroAluno() {
        return this.generoAluno;
    }
    setgeneroAluno(value) {
        this.generoAluno = value;
    }

     //dataEnsino;
     getdataEnsino() {
        return this.dataEnsino;
    }
    setdataEnsino(value) {
        this.dataEnsino = value;
    }

}