import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { green } from '@mui/material/colors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';



import { API_URL } from '../../constants';



const handleFileUpload = (selectedFile, pastaGoogle, colegio, nomeResponsavelFinanceiro, aluno, ordem) => {
  if (selectedFile) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const metadata = {
      name: 'ComprovantePagamento - ' + new Date().toLocaleDateString(), // Nome do arquivo enviado
      mimeType: 'application/pdf', // Definindo o tipo de arquivo como PDF
      parents: [pastaGoogle]
    };


    const formData = new FormData();
    formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    formData.append('file', selectedFile);

    fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('File uploaded:', data);

        alert("Arquivo PDF enviado com sucesso! Parabens! Você concluiu o acordo!");
        // Aqui você pode adicionar lógica adicional, como atualizar o estado ou recarregar a página

        // Exemplo de recarregar a página
       fetch( API_URL + "pagamento/"+  colegio +"/"+ nomeResponsavelFinanceiro+"/"+ aluno +"/"+ordem , {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        })
        
       // window.location.reload();
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }
};
const PagamentosPendentes = () => {
  const [rows, setRows] = useState([]);

  const [open, setOpen] = useState(false);
  const [modalParams, setModalParams] = useState({});
  const [idRootGoogleDrive, setIdRootGoogleDrive] = useState('');
  const [parcela, setParcela] = useState('');

  
  useEffect(() => {
    fetchPagamentos();
    console.log("pagamento", fetchPagamentos());
  }, []);

  const fetchPagamentos = async () => {
    try {
      const response = await fetch(API_URL + 'pagamento');
      if (!response.ok) {
        throw new Error('Erro ao buscar pagamentos');
      }
      const data = await response.json();
      console.log('Dados de pagamentos:', data);

      const newRows = data.map((pagamento) => ({
        id: pagamento.id,
        colegio: pagamento.colegio,
        inadimplente: pagamento.nomeResponsavelFinanceiro,
        nomeFilho: pagamento.aluno,
        valorAtualizado: pagamento.valorAtualizado,
        parcelas: pagamento.debitos.map((debito) => ({
          ordem: debito.ordem,
          dataPagamento: new Date(debito.dataPagamento).toLocaleDateString(), // Formatando a data para exibição
          valor: debito.valor,
          conferido: debito.conferido,
          upload: true // Ajustar conforme necessário
        }))
      }));

      setRows(newRows);
    } catch (error) {
      console.error('Erro ao buscar pagamentos:', error);
      // Tratar erro conforme necessário
    }
  };

  const handleUpload = () => {
    // Implementar lógica para upload de documentos se necessário
    console.log('Implementar upload de documentos');
  };


  const handleOpenModal = (params, parcelasel) => {
    console.log(params);
    let aluno = params.nomeFilho;
    let colegio = params.colegio;
    let nomeResponsavelFinanceiro = params.inadimplente;


    fetch(API_URL + 'inadimplentes/' + colegio + '/' + nomeResponsavelFinanceiro + '/' + aluno)
      .then((response) => response.json())
      .then((data) => {
        console.log(data[0].idPastaInadimplenteGoogleDrive);
        setIdRootGoogleDrive(data[0].idPastaInadimplenteGoogleDrive);
        //setModalParams(params.row);
        setModalParams(data[0]);
        console.log(modalParams);
        setParcela(parcelasel);

        setOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  
  const handleConferidoClick = async (parcela) => {
    if (!parcela.conferido) {
      // Lógica para marcar como conferido no servidor

      try {
        const response = await fetch(
          API_URL + `updateconferirInadimplentes/${parcela.colegio}/${parcela.inadimplente}/${parcela.nomeFilho}/${parcela.fieldName}/${parcela.valueField}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              // Incluir os campos necessários para a atualização
            })
          }
        );

        if (!response.ok) {
          throw new Error('Erro ao atualizar dados');
        }

        parcela.conferido = true;
        setRows((prevRows) => [...prevRows]); // Atualizar estado para refletir a mudança

      } catch (error) {
        console.error('Erro ao marcar como conferido:', error);
        // Tratar erro conforme necessário
      }
    }
  };

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    

    return (
      <React.Fragment>
        <TableRow sx={{ '': { borderBottom: 'unset' }, background: '#f6ffe2' }}>
          <TableCell>
            <IconButton aria-label="expand row" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            {row.colegio}
          </TableCell>
          <TableCell>{row.inadimplente}</TableCell>
          <TableCell align="right">{row.nomeFilho}</TableCell>
          <TableCell align="right">{row.valorAtualizado}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1}}>
              <Typography variant="h6" sx={{ background: '#eaebe9' }}>
                  Parcelas de Pagamento
                </Typography>

                <Table size="small" aria-label="parcelas">
                  <TableHead>
                  <TableRow sx={{ '': { borderBottom: 'unset' }, background: '#f6ffe2' }}>
                      <TableCell align="left">Ordem</TableCell>
                      <TableCell align="left">Data do Pagamento</TableCell>
                      <TableCell align="left">Valor</TableCell>
                      <TableCell align="left">Upload</TableCell>
                      <TableCell align="left">Conferido</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.parcelas.map((parcela) => (

                      <TableRow key={parcela.ordem} sx={{ '': { borderBottom: 'unset' }, background: '#fffff' }}>
                        <TableCell component="th" scope="row" align="left">
                          {parcela.ordem}
                        </TableCell>
                        <TableCell align="left">{parcela.dataPagamento}</TableCell>
                        <TableCell align="left">{parcela.valor}</TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpenModal(row, parcela)}
                            disabled={parcela.conferido}
                          >
                            <CloudUploadIcon
                              sx={{ color: parcela.conferido ? green[600] : 'grey' }}
                            />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleConferidoClick(parcela)}
                            disabled={parcela.conferido}
                          >
                            <DoneAllIcon
                              sx={{ color: parcela.conferido ? green[600] : 'grey' }}
                            />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Colegio</TableCell>
            <TableCell>Inadimplente</TableCell>
            <TableCell align="right">Nome do Filho</TableCell>
            <TableCell align="right">Valor Atualizado&nbsp;(R$)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.id} row={row} />
          ))}
        </TableBody>
      </Table>


      <Box height={400} width="100%">

      <Modal open={open} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h3">
            <b>Upload do comprovante de Pagamento </b>
          </Typography>

          <Typography sx={{ m: 2 }}>
           Responsável Financeiro: <b>{ modalParams.nomeResponsavelFinanceiro}</b>
           Aluno: <b>{ modalParams.nomeAluno}</b>
           Ordem: <b>{ parcela.ordem }</b>
           Colegio: <b>{ modalParams.colegio}</b>

          </Typography>
          <Typography sx={{ m: 2 }}>
            Pasta no Google Docs: <b>{idRootGoogleDrive}</b>
          </Typography>
          <Box sx={{ justifyContent: "center", width: "100%" }} >
            <Button
              variant="outlined"
              color="success"
              startIcon={<UploadFileIcon />}
              component="label"
              htmlFor="file-upload"
              style={{
                width: '70%',
                marginLeft: 10,
                marginRight: 30,
                cursor: 'pointer', // Adicionado para indicar que é clicável
              }}
            >
              <b>Upload do comprovante de Pagamento</b>
              <input
                id="file-upload"
                type="file"
                accept="application/pdf" // Aceitando apenas arquivos PDF
                onChange={(event) => handleFileUpload(event.target.files[0], idRootGoogleDrive,  modalParams.colegio, modalParams.nomeResponsavelFinanceiro, modalParams.nomeAluno,parcela.ordem )}
                style={{
                  display: 'none', // Removido para exibir o botão
                }}
              />
            </Button>
          </Box>

        </Box>
      </Modal>
    </Box>
      
    </TableContainer>











  );
};

export default PagamentosPendentes;
