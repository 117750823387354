import { gapi } from 'gapi-script';
import { API_URL } from '../../../constants';
import { CollectionsOutlined, Construction } from '@mui/icons-material';

const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES = 'https://www.googleapis.com/auth/documents';

// TRABALHOS COM DEBITO
let itemDebito = '';
let debito = new Array;
let valoresAtualizados = '';
let custas = '';
let custasTotais = '';
let totaisPlanilha = '';
let cabecalho = '';



// Obtém a data atual
const dataAtual = new Date();
const dia = dataAtual.getDate();
const mes = dataAtual.getMonth() + 1; // Mês começa de 0, então adiciona 1
const ano = dataAtual.getFullYear();
const dataFormatada = `${dia}/${mes}/${ano}`;
console.log("dataFormatada", dataFormatada);

function geraPlanilha(urlRootInadimplente,colegio, inadimplente, aluno) {
  const pastainadimplente = urlRootInadimplente;
  console.log(pastainadimplente);

  // Obtém o token de acesso
  const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
  // Define o ID da pasta raiz
  const rootFolderId = urlRootInadimplente;
  // Define o ID do arquivo
  const fileId = '1B1VcdkXvT_r1xMxs9nXungrvsaw186I-H0KVE6_wI5o';
  //const fileId =  '1GxCg0J9YERBnvBl54LkxYXhQhxaIvvBE'; // template to excel

  // Define os dados da solicitação
  const requestData = {
    title: 'Planilha',
    name: '02.PLANILHA',
    parents: [pastainadimplente]
  };

  try {
    // Faz uma solicitação para copiar o arquivo
    fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((val) => {
        const newDocumentId = val.id;
        gapi.client.load('sheets', 'v4').then(() => {
          console.log('valores a serem atualizados');
          console.log("CABECALHO", cabecalho[0]);
          console.log("DEBITOOO", debito[0]);
          
          // Atualiza a planilha com novos valores
          gapi.client.sheets.spreadsheets.values.batchUpdate({
            "spreadsheetId": newDocumentId,
            "resource": {
              "data": [cabecalho, debito],
              "valueInputOption": "RAW"
            }
          })      .then((val) => {
            console.log(val);
            const response = fetch(API_URL + "debito/" + colegio + "/" + inadimplente + "/" + aluno + "/" + newDocumentId, {
              method: 'PUT', // Especificando o método PUT
              headers: {
                'Content-Type': 'application/json', // Especificando o tipo de conteúdo como JSON, se necessário
                // Aqui você pode adicionar outros headers, se necessário
              },
              // Aqui você pode adicionar o corpo da requisição, se necessário
            });


          }).then((val) => {
            // Formata as células como datas
            formatarCelasComoData(newDocumentId, debito);
            // Abre o novo documento em uma nova janela
            //window.open("https://docs.google.com/spreadsheets/d/" + newDocumentId, '_blank');
            if(newDocumentId!=null){
            exportarParaExcel(newDocumentId, urlRootInadimplente);
            } 

          });
        });
      });
  } catch (error) {
    console.error("Erro ao carregar os serviços do Google:", error);
  }
}



// Exemplo usando a API do Google Drive e JavaScript para exportar para Excel

function exportarParaExcel(spreadsheetId, idPastaDestino) {
  //var spreadsheetId = "ID_DA_PLANILHA_AQUI"; // Substitua pelo ID da planilha que deseja exportar
  //var accessToken = "SEU_TOKEN_DE_AUTORIZAÇÃO_AQUI"; // Obtenha o token de autorização adequado

  var url = "https://www.googleapis.com/drive/v3/files/" + spreadsheetId + "/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + accessToken,
    }
  })
  .then(response => response.blob())
  .then(blob => {
    var a = document.createElement('a');
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = '02.PLANILHA.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
     uploadParaGoogleDrive(blob, '02.PLANILHA.xlsx',idPastaDestino);

  })
  .catch(error => console.error('Erro ao exportar para Excel:', error));
}  

async function uploadParaGoogleDrive(blob, nomeArquivo, idPastaDestino) {
  try {
    // URL para upload de arquivo no Google Drive
    const url = `https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart`;

    // Obtém o token de acesso atual
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;


    const metadata = {
      name: nomeArquivo,
     // mimeType: selectedImage.type,
      parents: [idPastaDestino]
    };

    const formData = new FormData();
    formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    formData.append('file', blob);

    // Faz a solicitação para o Google Drive usando fetch
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      },
      body: formData,
    });

    // Verifica se a resposta foi bem-sucedida
    if (!response.ok) {
      throw new Error('Falha ao fazer upload do arquivo para o Google Drive.');
    }

    const data = await response.json();
    console.log('Arquivo enviado para o Google Drive:', data);

      // Obtém o ID do novo arquivo criado
      const newDocumentId = data.id;

      // Abre o novo documento Excel em uma nova janela
      window.open(`https://docs.google.com/spreadsheets/d/${newDocumentId}`, '_blank');
     return data;
  } catch (error) {
    console.error('Erro ao fazer upload do arquivo para o Google Drive:', error);
    throw error;
  }
}

function createFormData(spreadsheetId) {
  const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

  var formData = new FormData();
  var url = "https://www.googleapis.com/drive/v3/files/" + spreadsheetId + "/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

  fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': 'Bearer ' + accessToken,
    }
  })
  .then(response => response.blob())
  .then(blob => {
    formData.append('file', blob, 'planilha.xlsx');
  })
  .catch(error => console.error('Erro ao exportar para Excel:', error));

  return formData;
}




function formatarCelasComoData(spreadsheetId, debito) {
  const requests = debito
    .filter(item => item.range.startsWith("Atualização de Valores!E"))
    .map(item => {
      const rowIndex = parseInt(item.range.match(/\d+/)[0], 10) - 1;
      return {
        "repeatCell": {
          "range": {
            "sheetId": 0, // Substitua pelo ID da sua aba se não for a primeira
            "startRowIndex": rowIndex,
            "endRowIndex": rowIndex + 1,
            "startColumnIndex": 4, // Coluna E (índice 4)
            "endColumnIndex": 5
          },
          "cell": {
            "userEnteredFormat": {
              "numberFormat": {
                "type": "DATE",
                "pattern": "dd/MM/yyyy"
              }
            }
          },
          "fields": "userEnteredFormat.numberFormat"
        }
      };
    });

/*  gapi.client.sheets.spreadsheets.batchUpdate({
    spreadsheetId,
    resource: {
      requests
    }*/


  gapi.client.load('sheets', 'v4').then(() => {

      // Atualiza a planilha com novos valores
      gapi.client.sheets.spreadsheets.values.batchUpdate({
        "spreadsheetId": spreadsheetId,
        "resource": {
          requests
        }
      })  
  }).then((response) => {
    console.log('Células formatadas como datas', response);
  }).catch((error) => {
    console.error('Erro ao formatar células como datas', error);
  });
}

export default class MergeAndEditDocument {

  async mergeDataInDocument(urlRootInadimplente, colegio, inadimplente, aluno, open) {
    try {
      console.log("entrei", urlRootInadimplente, colegio, inadimplente, aluno, open);
    } catch (event) {
      console.log(event);
    }

    console.log(API_URL + "debito/" + colegio + "/" + inadimplente + "/" + aluno);

    const resposta = fetch(API_URL + "debito/" + colegio + "/" + inadimplente + "/" + aluno, {
      method: 'GET',
      mode: 'cors'
    }).then((response) => response.json())
      .then((json) => {
        console.log(json);
        itemDebito = json.itemDebito;
        console.log("itemDebito");

        let linha = 9;
        for (let i = 0; i < itemDebito.length; i++) {
          console.log(i);
          
          const formatarData = (data) => {
            if (!data) return "";
            const [dia, mes, ano] = data.split('/');
            const dataFormatada = new Date(`${ano}-${mes}-${dia}`);
            if (isNaN(dataFormatada)) return "";
            const diaFormatado = dataFormatada.getDate().toString().padStart(2, '0');
            const mesFormatado = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
            const anoFormatado = dataFormatada.getFullYear();
            return `${diaFormatado}/${mesFormatado}/${anoFormatado}`;
          };

          debito.push(
            {
              "range": "Atualização de Valores!C" + (linha + i),
              "values": [
                [parseFloat(i + 1).toFixed(0) || ""]
              ]
            },
            {
              "range": "Atualização de Valores!D" + (linha + i),
              "values": [
                [itemDebito[i].descricao || ""]
              ]
            },
            {
              "range": "Atualização de Valores!E" + (linha + i),
              "values": [
                [formatarData(itemDebito[i].dataVencimento) || ""]
              ]
            },
            {
              "range": "Atualização de Valores!F" + (linha + i),
              "values": [
                [itemDebito[i].valor || ""]
              ]
            }
          );
        }

        console.log('funciona????', debito);

        cabecalho = [
          {
            "range": "Atualização de Valores!D2",
            "values": [[json.colegio] || ""]
          }, {
            "range": "Atualização de Valores!D3",
            "values": [[json.nomeResponsavelFinanceiro] || ""]
          }, {
            "range": "Atualização de Valores!D4",
            "values": [[json.aluno] || ""]
          }, {
            "range": "Atualização de Valores!E5",
            "values": [[dataFormatada] || ""]
          }, {
            "range": "Atualização de Valores!G5",
            "values": [[1]]
          }
        ];

        valoresAtualizados = [
          {
            "range": "Atualização de Valores!F24",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!I24",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!J24",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!F37",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!K24",
            "values": [["Assss1"]]
          }
        ];

        custas = [
          {
            "range": "Atualização de Valores!C27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!D27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!E27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!F27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!G27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!H27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!H27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!I27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!J27",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!K27",
            "values": [["Assss1"]]
          }
        ];

        custasTotais = [
          {
            "range": "Atualização de Valores!I37",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!J37",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!K37",
            "values": [["Assss1"]]
          }
        ];

        totaisPlanilha = [
          {
            "range": "Atualização de Valores!F39",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!I39",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!J39",
            "values": [["Assss1"]]
          }, {
            "range": "Atualização de Valores!K39",
            "values": [["Assss1"]]
          }
        ];

        console.log("vou chamar a planilha");
        setTimeout(() => geraPlanilha(urlRootInadimplente,colegio, inadimplente, aluno), 3000);
      })
      .catch((error) => console.log('Authorization failed: ' + error.message));
    console.log("debito populado", debito);
  }
}
    