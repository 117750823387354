import React, { useEffect } from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import Checkbox from '@mui/material/Checkbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { green } from '@mui/material/colors';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from '@mui/material/Modal';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import DataGridConsultaDebito from '../datagrids/DataGridConsultaDebito.js';
import { StyledEngineProvider } from '@mui/material/styles';
import { API_URL } from '../../constants';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { v4 as uuidv4 } from 'uuid';




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

let updateHistorico;
let setupdateHistorico;




function semAcordo (documentosRow) {

  
  const uuid = uuidv4();

  console.log(documentosRow,uuid);
  try {
    const response = fetch(API_URL + "judiciais",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: uuid,
          nomeResponsavelFinanceiro: documentosRow.responsavelFinanceiro,
          colegio: documentosRow.colegio,
          aluno: documentosRow.nomeAluno,

        }),
      });




  } catch (error) {
    console.error('Erro:', error);
    // Trate o erro conforme necessário
  }


}




function comAcordo (documentosRow) {

  
  const uuid = uuidv4();

  console.log(documentosRow,uuid);
  try {
    const response = fetch(API_URL + "gerarMinuta",
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: uuid,
          nomeResponsavelFinanceiro: documentosRow.responsavelFinanceiro,
          colegio: documentosRow.colegio,
          aluno: documentosRow.nomeAluno,

        }),
      });




  } catch (error) {
    console.error('Erro:', error);
    // Trate o erro conforme necessário
  }


}



const handleParcelamentoChange = (selecionado, setSelecionado, indice, debito) => {

  // console.log(checked);
  setSelecionado(indice);

  console.log("indice", indice, "debito", debito);

  let parcelamento = 0;
  switch (indice) {

    case 1:
      parcelamento = parseFloat(debito.valorAtualizado / 1).toFixed(2);
      break;
    case 2:
      parcelamento = parseFloat(debito.valorAtualizado / 5).toFixed(2);
      break;
    case 3:
      parcelamento = parseFloat(debito.valorAtualizado / 12).toFixed(2);
      break;
    case 4:
      parcelamento = parseFloat(debito.valorAtualizado / 24).toFixed(2);
      break;

  }

  console.log(parcelamento, {
    nomeResponsavelFinanceiro: debito.inadimplente,
    colegio: debito.colegio,
    aluno: debito.nomeFilho,
    parcelas: indice,
    valorParcelas: parcelamento,
    valorTotal: debito.valorAtualizado
  });
  try {
    const response = fetch(API_URL + "updateconferirParcelas" + "/" +
      debito.colegio + "/" +
      debito.inadimplente + "/" +
      debito.nomeFilho + "/",
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          nomeResponsavelFinanceiro: debito.inadimplente,
          colegio: debito.colegio,
          aluno: debito.nomeFilho,
          parcelas: indice,

          valorParcelas: parcelamento,
          valorTotal: debito.valorAtualizado
        }),
      });

    if (!response.ok) {
      throw new Error('Erro ao atualizar dados');
    }



  } catch (error) {
    console.error('Erro:', error);
    // Trate o erro conforme necessário
  }

};





const handleConferidoClick = async (documentosRow, setDocumentos, documentos) => {

  const updatedDocumentos = documentos.map((doc) =>
    doc.item === documentosRow.item ? { ...doc, conferido: true } : doc
  );
  console.log("documentosRow", documentosRow, "documentos", documentos, "documentos[6].dadosDocumento", documentos[6].dadosDocumento);
  if (!documentosRow.conferido) {

    let fieldName, valueField;
    switch (documentosRow.item) {
      case "1":
        fieldName = "rg";
        valueField = documentos[1].dadosDocumento;
        documentosRow.dadosDocumento = documentos[1].dadosDocumento;
        break;
      case "2":
        fieldName = "cpf";
        valueField = documentos[2].dadosDocumento;
        documentosRow.dadosDocumento = documentos[2].dadosDocumento;
        break;
      case "3":
        fieldName = "profissao";
        valueField = documentos[6].dadosDocumento;
        documentosRow.dadosDocumento = documentos[6].dadosDocumento;
        break;
      case "4":
        fieldName = "whatsapp"
        valueField = documentos[3].dadosDocumento;
        documentosRow.dadosDocumento = documentos[3].dadosDocumento;
        break;
      case "5":
        fieldName = "email"
        valueField = documentos[4].dadosDocumento;
        documentosRow.dadosDocumento = documentos[4].dadosDocumento;
        break;
      case "6":
        fieldName = "estadoCivil"
        valueField = documentos[5].dadosDocumento;
        documentosRow.dadosDocumento = documentos[5].dadosDocumento;
        break;

    }



    try {
      const response = await fetch(API_URL + "updateconferirInadimplentes" + "/" +
        documentos[0].colegio + "/" +
        documentos[0].responsavelFinanceiro + "/" +
        documentos[0].nomeAluno + "/" +
        fieldName + "/" +
        valueField,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            nomeResponsavelFinanceiro: documentos[0].responsavelFinanceiro,
            colegio: documentos[0].colegio,
            aluno: documentos[0].nomeAluno,
            fieldName: fieldName,
            valueField: valueField,
          }),
        });

      if (!response.ok) {
        throw new Error('Erro ao atualizar dados');
      }

      const data = await response.json();
      documentosRow.conferido = true;
      setDocumentos(updatedDocumentos);


    } catch (error) {
      console.error('Erro:', error);
      // Trate o erro conforme necessário
    }

  }
};


const handleSalvarAlteracao = (documentosRow) => {
  console.log('DocumentosRow clicado:', documentosRow.row);
  // Aqui você pode realizar qualquer operação necessária com os dados de documentosRow
};


function abrirPlanilha(urlPlanilha) {
  window.open("https://docs.google.com/spreadsheets/d/" + urlPlanilha, '_blank');
}
const handleClose = () => { };

const handleUploadFile = (e: any) => console.log(e.target.files[0]);


function cadastraConferirdocumentos() { 
try {
    const response = fetch(API_URL + "updateConferirHistorico" + "/" +
      documentosRow.colegio + "/" +
      documentosRow.responsavelFinanceiro + "/" +
      documentosRow.nomeAluno + "/",
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          nomeResponsavelFinanceiro: documentosRow.responsavelFinanceiro,
          colegio: documentosRow.colegio,
          aluno: documentosRow.nomeAluno,
          historico: event + " " + historico,
          data: data
        }),
      });

    if (!response.ok) {
      throw new Error('Erro ao atualizar dados');
    }



  } catch (error) {
    console.error('Erro:', error);
    // Trate o erro conforme necessário
  }



}


//function createData(colegio, inadimplente, nomeFilho, valorAtualizado, documentos, urlPlanilha) {
function createData(inadimplente, tableData) {
  const ina = inadimplente.inadimplentes[0];
  const debitos = inadimplente.debitos;

  let parcelamento = 0;
  return {
    colegio: ina.colegio ? ina.colegio : "",
    inadimplente: ina.nomeResponsavelFinanceiro ? ina.nomeResponsavelFinanceiro : "",
    nomeFilho: ina.nomeAluno ? ina.nomeAluno : "",
    valorAtualizado: debitos.total_debito_atualizado ? debitos.total_debito_atualizado : "",
    urlPlanilha: debitos.idPlanilha ? debitos.idPlanilha : "",
    parcelamento: parcelamento ? parcelamento : "",
    documentos: [
      {
        item: 0,
        nomeAluno: ina.nomeAluno,
        responsavelFinanceiro: ina.nomeResponsavelFinanceiro,
        colegio: ina.colegio
      },
      {
        item: 1,
        descricao: "RG",
        dadosDocumento: ina.rg ? ina.rg : "",
        conferido: tableData[0].rg,
        upload: tableData[0].rg
      },
      {
        item: '2',
        descricao: 'CPF',
        dadosDocumento: ina.cpf ? ina.cpf : "",
        conferido: tableData[0].cpf,
        upload: tableData[0].cpf

      },
      {
        item: '4',
        descricao: 'Numero de Telefone',
        dadosDocumento: ina.whatsapp ? ina.whatsapp : "",
        conferido: tableData[0].whatsapp,
        upload: false

      },
      {
        item: '5',
        descricao: 'Email',
        dadosDocumento: ina.email ? ina.email : "",
        conferido: tableData[0].email,
        upload: false
      },
      {
        item: '6',
        descricao: 'Estado civil',
        dadosDocumento: ina.estadoCivil ? ina.estadoCivil : "",
        conferido: tableData[0].estadoCivil,
        upload: false
      },
      {
        item: '3',
        descricao: 'Profissão Atual ',
        dadosDocumento: ina.profissao ? ina.profissao : "",
        conferido: tableData[0].profissao,
        upload: false
      },
    ],

  };
}



const handleDadosDocumentoChange = (event, documentosRow, setDocumentos, documentos) => {
  const newValue = event.target.value;
  const updatedDocumentos = documentos.map((row) =>
    row.item === documentosRow.item ? { ...row, dadosDocumento: newValue } : row
  );
  setDocumentos(updatedDocumentos);

};


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [documentos, setDocumentos] = useState(row.documentos);
  const [documentosRow, setDocumentosRow] = useState(row.documentosRow);
  const [selecionado, setSelecionado] = useState(false);
  const [historico, setHistorico] = useState(row.documentosRow);


  // Estados para controlar os textfields e checkboxes
  const [item1Text, setItem1Text] = useState('');
  const [item2Text, setItem2Text] = useState('');
  const [item3Text, setItem3Text] = useState('');
  const [item4Text, setItem4Text] = useState('');
  const [item1Checked, setItem1Checked] = useState(false);
  const [item2Checked, setItem2Checked] = useState(false);
  const [item3Checked, setItem3Checked] = useState(false);
  const [item4Checked, setItem4Checked] = useState(false);






const cadastraHistorico= () => {
console.log(row.documentosRow);
  console.log(updateHistorico);
  let hist = JSON.parse(updateHistorico);
  console.log(API_URL + "updateConferirHistorico" + "/" +
  hist.colegio + "/" +
  hist.nomeResponsavelFinanceiro + "/" +
  hist.aluno );


   try {
    const response = fetch(API_URL + "updateConferirHistorico" + "/" +
    updateHistorico.colegio + "/" +
    updateHistorico.responsavelFinanceiro + "/" +
    updateHistorico.nomeAluno ,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: updateHistorico
      });

   
    setItem1Text('');
    setItem2Text('');
    setItem3Text('');
    setItem4Text('');
    setItem1Checked(false);
    setItem2Checked(false);
    setItem3Checked(false);
    setItem4Checked(false);
alert("Cadastrado");

  } catch (error) {
    console.error('Erro:', error);
    // Trate o erro conforme necessário
  }

}


const handleHistoricoChange = (event, item, documentosRow) => {


  function obterDataAtual() {
    const dataAtual = new Date();
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Mês é indexado a partir de zero
    const ano = dataAtual.getFullYear();
    return `${ano}-${mes}-${dia}`;
  }
  // setSelecionado(event.target.checked);
  //setSelecionado(true);
  console.log(documentosRow);

  let historico;

  switch (item) {

    case 1:
      historico = document.getElementById('item1').value
      setItem1Checked(true);

      break;
    case 2:
      historico = document.getElementById('item2').value
      setItem2Checked(true);

      break;
    case 3:
      historico = document.getElementById('item3').value
      setItem3Checked(true);
      break;
    case 4:
      historico = document.getElementById('item4').value
      setItem4Checked(true);

      break;

  }
  console.log(event);

  let data = obterDataAtual();
  updateHistorico=JSON.stringify({
    nomeResponsavelFinanceiro: documentosRow.responsavelFinanceiro,
    colegio: documentosRow.colegio,
    aluno: documentosRow.nomeAluno,
    historico: event + " " + historico,
    data: data
  });


};





  const handleUpload = () => {
    // Aqui você pode implementar a lógica para enviar os dados para o servidor
    console.log('Dados a serem enviados:', documentos);
  };

  return (
    ////////// //tabela inicial
    <React.Fragment>
      <TableRow sx={{ '': { borderBottom: 'unset' }, background: '#f6ffe2' }}>
        <TableCell >
          <IconButton
            aria-label="root"
            onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"  > {row.colegio} </TableCell>
        <TableCell component="th" scope="row"  > {row.inadimplente} </TableCell>
        <TableCell align="right">{row.nomeFilho}</TableCell>
        <TableCell align="right">{row.valorAtualizado}</TableCell>

        {  /*<TableCell align="right" >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)} >
            {open ? <PublishedWithChangesOutlinedIcon /> : <PublishedWithChangesOutlinedIcon />}
          </IconButton>
        </TableCell> 
        */
        }
      </TableRow>


      <TableRow >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 0 }}>
              <Typography variant="h6" sx={{ background: '#eaebe9' }}>
                Documentos a Conferir
              </Typography>

              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Item</TableCell>
                    <TableCell align="left">Descrição</TableCell>
                    <TableCell align="left" >Dados Preenchidos</TableCell>
                    <TableCell align="left">Dados a Alterar</TableCell>
                    <TableCell align="left" >Upload</TableCell>
                    <TableCell align="left">Conferido</TableCell>
                    {/*  <TableCell align="left" >Salvar</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {//row.documentos.map((documentosRow) => (


                    row.documentos.map((documentosRow) => {
                      // Verifica se o item é igual a zero
                      if (documentosRow.item === 0) {
                        return null; // Pula essa linha
                      }
                      return (

                        <TableRow key={documentosRow.item}>
                          <TableCell component="th" scope="row" align="left"> {documentosRow.item} </TableCell>
                          <TableCell align="left">{documentosRow.descricao}</TableCell>
                          <TableCell align="left">{documentosRow.dadosDocumento}</TableCell>
                          <TableCell align="left" >
                            <TextField
                              style={{ display: documentosRow.conferido ? 'none' : 'block' }}
                              id="dadosAlterados"
                              // value={documentosRow.dadosDocumento}
                              type="text"
                              variant='standard'
                              color='secondary'
                              size="small"
                              onChange={(e) => handleDadosDocumentoChange(e, documentosRow, setDocumentos, documentos)}

                            // onChange={(e) => handleDadosDocumentoChange(event, documentosRow, setDocumentos, setDocumentosRow, documentos)}
                            />
                          </TableCell>
                          <TableCell align="left" >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={handleUpload}
                            >
                              {documentosRow.upload && !documentosRow.conferido ? (
                                <CloudUploadIcon sx={{ color: documentosRow.conferido ? green[600] : "grey" }} />
                              ) : (
                                null
                              )}
                            </IconButton>
                          </TableCell>
                          <TableCell align="left" >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => handleConferidoClick(documentosRow, setDocumentos, documentos)}
                            >
                              <DoneAllIcon sx={{ color: documentosRow.conferido ? green[600] : "grey" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <Button
                variant="contained"
                onClick={() => abrirPlanilha(row.urlPlanilha)} // Usando uma função anônima
                sx={{
                  width: "100%",
                  bgcolor: '#81c784', // Esta é uma tonalidade de verde claro
                  color: '#000'
                }}
                endIcon={<SendIcon />}
              >
                Abrir Planilha
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      { //////////////////////**************************************************************////////PARCELAMENTO}
      }

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
              <Typography variant="h6" sx={{ background: '#eaebe9' }}>
                Opções de Parcelamento
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Opçāo</TableCell>
                    <TableCell>Parcelamento</TableCell>
                    <TableCell align="right">Valores</TableCell>
                    <TableCell align="right">Selecione</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">1</TableCell>
                    <TableCell>"Em 1x"</TableCell>
                    <TableCell align="right">R$ {parseFloat(row.valorAtualizado).toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <Radio
                        checked={selecionado === 1}
                        onChange={(e) => handleParcelamentoChange(selecionado, setSelecionado, 1, row)}
                        value="1"
                        name="parcelamento"
                        inputProps={{ 'aria-label': '1x' }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">2</TableCell>
                    <TableCell>"Em 5x"</TableCell>
                    <TableCell align="right">R$ {parseFloat(row.valorAtualizado / 5).toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <Radio
                        checked={selecionado === 2} // Se row.parcelamento for igual a 2, então esta opção será selecionada
                        onChange={(e) => handleParcelamentoChange(selecionado, setSelecionado, 2, row)}
                        value="2"
                        name="parcelamento"
                        inputProps={{ 'aria-label': '5x' }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">3</TableCell>
                    <TableCell>"Em 12x"</TableCell>
                    <TableCell align="right">R$ {parseFloat(row.valorAtualizado / 12).toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <Radio
                        checked={selecionado === 3} // Se row.parcelamento for igual a 2, então esta opção será selecionada
                        onChange={(e) => handleParcelamentoChange(selecionado, setSelecionado, 3, row)}
                        value="3"
                        name="parcelamento"
                        inputProps={{ 'aria-label': '12x' }}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">4</TableCell>
                    <TableCell>"Em 24x"</TableCell>
                    <TableCell align="right">R$ {parseFloat(row.valorAtualizado / 24).toFixed(2)}</TableCell>
                    <TableCell align="right">
                      <Radio
                        checked={selecionado === 4} // Se row.parcelamento for igual a 2, então esta opção será selecionada
                        onChange={(e) => handleParcelamentoChange(selecionado, setSelecionado, 4, row)}
                        value="4"
                        name="parcelamento"
                        inputProps={{ 'aria-label': '24x' }}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/*  <Button variant="contained" onClick={cadastraConferirdocumentos(selecionado)}
                sx={{
                  width: "100%",
                  bgcolor: '#81c784', // Esta é uma tonalidade de verde claro
                  color: '#000'
                }} endIcon={<SendIcon />}>Cadastrar Parcelamento</Button>
             */} </Box>
          </Collapse>
        </TableCell>


        { //////////////////////**************************************************************////////HISTORICO}
        }   </TableRow><TableRow>

        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ marginTop: 1, marginBottom: 2 }}>
              <Typography variant="h6" sx={{ background: '#eaebe9' }}>
                Comunicação
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">Motivo</TableCell>
                    <TableCell align="right">Selecione</TableCell>
                  </TableRow>
                </TableHead>
                {   /*************  1 */}
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row"> 1</TableCell>
                    <TableCell>"Tentei Entrar em contato, mas o usuário nāo atende o telefone e nem responde o WhatsApp"</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        variant='standard'
                        color='secondary'
                        id="item1"
                        value={item1Text}
                        onChange={(e) => setItem1Text(e.target.value)}
                      /></TableCell>
                    <TableCell align="right" >
                      <Checkbox
                      checked={item1Checked}
                        onChange={(e) => handleHistoricoChange("Tentei Entrar em contato, mas o usuário nāo atende o telefone e nem responde o WhatsApp", 1, documentos[0])}
                      />
                    </TableCell>
                  </TableRow>
                  {   /*************  2 */}
                  <TableRow >
                    <TableCell component="th" scope="row"> 2</TableCell>
                    <TableCell>"Entrei em contato, conversei com o Responsavel Financeiro e ele me disse que não quer negociar, pois "</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        variant='standard'
                        color='secondary'
                        id="item2"
                        value={item2Text}
                        onChange={(e) => setItem2Text(e.target.value)}  
                      /></TableCell>
                    <TableCell align="right" >
                      <Checkbox
                                            checked={item2Checked}

                        onChange={(e) => handleHistoricoChange("Entrei em contato, conversei com o Responsavel Financeiro e ele me disse que não quer negociar, pois ", 2, documentos[0])}
                      />
                    </TableCell>
                  </TableRow>
                  {   /*************  3 */}
                  <TableRow >
                    <TableCell component="th" scope="row"> 3</TableCell>
                    <TableCell>"Tentei Entrar em contato, mas"</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        variant='standard'
                        color='secondary'
                        id="item3"
                        value={item3Text}
                        onChange={(e) => setItem3Text(e.target.value)}
                      />
                    </TableCell>
                    <TableCell align="right" >
                      <Checkbox
                        checked={item3Checked}
                        onChange={(e) => handleHistoricoChange("Tentei Entrar em contato, mas ", 3, documentos[0])}
                      />
                    </TableCell>
                  </TableRow>
                  {   /*************  4 */}
                  <TableRow >
                    <TableCell component="th" scope="row"> 4</TableCell>
                    <TableCell>"Nao entrei em contato, "</TableCell>
                    <TableCell>
                      <TextField
                        type="text"
                        variant='standard'
                        color='secondary'
                        id="item4"
                        value={item4Text}
                        onChange={(e) => setItem4Text(e.target.value)}
                      />
                    </TableCell>
                    <TableCell align="right" >
                      <Checkbox
                        checked={item4Checked}
                        onChange={(e) => handleHistoricoChange("Nao entrei em contato, ", 4, documentos[0])}
                      />
                    </TableCell>
                  </TableRow>
                  {   /**************/}

                </TableBody>
              </Table>
              <Button
                variant="contained"
                onClick={cadastraHistorico}
                sx={{
                  width: "100%",
                  bgcolor: '#81c784', // Esta é uma tonalidade de verde claro
                  color: '#000'
                }}
                endIcon={<SendIcon />}
              >
                Cadastrar Tentativa Comunicacao
              </Button>
            </Box>
          </Collapse>
        </TableCell>


      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
       
            <Button
              variant="contained"
              onClick={() => semAcordo(documentos[0])}
              color='error' 
              sx={{
                width: "50%",
                bgcolor: 'error.main',  // Definindo a cor de fundo para vermelho
                '&:hover': {
                  bgcolor: 'error.dark',  // Mudando a cor de fundo no hover
                }
              }}
              endIcon={<SendIcon />}
            >
             Nāo teve Acordo
            </Button>
            <Button
              variant="contained"
              onClick={() => comAcordo(documentos[0])}
              color='success'
              sx={{
                width: "50%"
              }}
              endIcon={<SendIcon />}
            >
              Concluir Acordo
            </Button>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
/*
Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    // carbs: PropTypes.number.isRequired,
    // fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    ordem: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};
*/


export default function ConferirDocumentos() {
  const [rows, setRows] = useState([]);

  let promiseTimeout;


  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "conferirInadimplentes")
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])

  async function buscaInadimplente(responsavelFinanceiro, colegio, aluno) {
    try {
      // URLs para as requisições
      const inadimplentesURL = `${API_URL}inadimplentes/${responsavelFinanceiro}`;
      const debitosURL = `${API_URL}debito/${colegio}/${responsavelFinanceiro}/${aluno}`;

      // Fazendo as requisições em paralelo usando Promise.all
      const [inadimplentesResponse, debitosResponse] = await Promise.all([
        fetch(inadimplentesURL),
        fetch(debitosURL)
      ]);

      // Verificando se ambas as requisições foram bem sucedidas
      if (!inadimplentesResponse.ok) {
        throw new Error('Erro ao buscar inadimplente');
      }
      if (!debitosResponse.ok) {
        throw new Error('Erro ao buscar débitos');
      }

      // Convertendo as respostas para JSON
      const inadimplentesData = await inadimplentesResponse.json();
      const debitosData = await debitosResponse.json();

      // Combinando os dados em um objeto único para retorno
      const resultado = {
        inadimplentes: inadimplentesData,
        debitos: debitosData
      };

      // Retornando o resultado combinado
      return resultado;
    } catch (error) {
      console.error('Erro na busca do inadimplente:', error);
      throw error; // Propaga o erro para quem chamou a função
    }
  }


  async function processarTableData(tableData) {
    try {
      if (!tableData || tableData.length === 0) {
        console.log('tableData está vazio ou não está definido.');
        return;
      } else {

        console.log('Dados recebidos:', tableData); // Mostra todo o array tableData

        // Processar cada item em tableData de forma assíncrona
        for (let i = 0; i < tableData.length; i++) {
          console.log('Buscando inadimplente:', tableData[i].nomeResponsavelFinanceiro);

          if (tableData[i].nomeResponsavelFinanceiro == null || tableData[i].colegio == null || tableData[i].aluno == null) {

            continue;
          }
          let inadimplente = await buscaInadimplente(tableData[i].nomeResponsavelFinanceiro, tableData[i].colegio, tableData[i].aluno);
          console.log('Resultado da busca:', inadimplente); // Exibe os dados do inadimplente


          if (inadimplente.inadimplentes != null && inadimplente.debitos != null) {

            setRows((prevRows) => [
              ...prevRows,
              createData(inadimplente, tableData)
            ]);

          }
        }
      }
    } catch (error) {
      console.error('Erro ao processar tableData:', error);
      // Trate o erro conforme necessário
    }
  }

  // Chamada da função para processar tableData
  const [dataProcessed, setDataProcessed] = useState(false);

  useEffect(() => {
    if (tableData && tableData.length > 0 && !dataProcessed) {
      processarTableData(tableData);
      setDataProcessed(true);
    }
  }, [tableData, dataProcessed]);


  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell />
            <TableCell>Colegio </TableCell>
            <TableCell>Inadimplente </TableCell>
            <TableCell align="right">Nome do Filho</TableCell>
            <TableCell align="right">Valor Atualizadoo&nbsp;(R$)</TableCell>
            {/*    <TableCell align="right">Atualizar</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}