import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';



function handleSaveClick(){
  console.log("Ola");
}


export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}


const columns = [

  { 
    field: 'id',
   headerName: 'id',
    width: 150,
    editable: true
  },

  {
    field: 'mes_ano',
    headerName: 'Mes/Ano',
    width: 80,
    editable: true,
  },
  { 
    field: 'indice',
   headerName: 'Indice',
    width: 150,
    editable: true
  },

];


const DataGridIndice = () => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "indices", {
      method: 'GET',
      allowedHeaders: 'Content-Type'
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])
  console.log("tableData");
  console.log(tableData);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [{ field: 'ano', sort: 'desc' }],
          },
        }}
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridIndice

 




 
