import React from "react";
import { Aluno } from '../DTO/Aluno.js';
import Requests from "../api/requests.js";


let aluno = new Aluno();
let req = new Requests();

export default class CrudAluno {

 

    constructor(value) {
        this.aluno = value;
   }
   buscacolegioAPI() {
    let req = new Requests();
    return req.requestGETparamColegiosTextfield("colegios");
  }

  buscaInadimplenteAPI(colegio) {
    let req = new Requests();
    return req.requestGETparamColegiosInadimplentesTextfield("inadimplentesAlunos", colegio);
  }
    cadastraAlunoAPI(event){
      const { v4: uuidv4 } = require('uuid');
    aluno = event;
    console.log(      
      JSON.stringify({
        id: uuidv4(),        
        colegioAluno: aluno.getcolegioAluno(),
        inadimplenteAluno: aluno.getinadimplenteAluno(), 
        nomeAluno: aluno.getnomeAluno(), 
        codigoAluno: aluno.getcodigoAluno(), 
        turmaAluno : aluno.getturmaAluno(),
        generoAluno: aluno.getgeneroAluno(),
        dataEnsino: aluno.getdataEnsino()
      })
    );
  
    req.requestPOSTparam(JSON.stringify({
        id: uuidv4(),  
        colegioAluno: aluno.getcolegioAluno(),
        inadimplenteAluno: aluno.getinadimplenteAluno(), 
        nomeAluno: aluno.getnomeAluno(), 
        codigoAluno: aluno.getcodigoAluno(), 
        turmaAluno : aluno.getturmaAluno(),
        generoAluno: aluno.getgeneroAluno(),
        dataEnsino: aluno.getdataEnsino()
    }), 'aluno');

    req.requestPUTparam('alunoInadimplente', (JSON.stringify({
      colegioAluno: aluno.getcolegioAluno(),
      inadimplenteAluno: aluno.getinadimplenteAluno(), 
      nomeAluno: aluno.getnomeAluno(), 
     
  })),'');

    
  

  }
  
}