import React, { useEffect, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { API_URL } from '../../constants';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';

const handleFileUpload = (selectedFile, pastaGoogle, colegio, nomeResponsavelFinanceiro, aluno) => {
  if (selectedFile) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const metadata = {
      name: '10.HISTORICO WHATSAPP', // Nome do arquivo enviado
      mimeType: 'application/pdf', // Definindo o tipo de arquivo como PDF
      parents: [pastaGoogle]
    };


    const formData = new FormData();
    formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
    formData.append('file', selectedFile);

    fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('File uploaded:', data);

        alert("Arquivo PDF enviado com sucesso! Parabens! Você concluiu o acordo!");
        // Aqui você pode adicionar lógica adicional, como atualizar o estado ou recarregar a página

        // Exemplo de recarregar a página
       /* fetch( API_URL + "minutaAcordoAssinada/"+  colegio +"/"+ nomeResponsavelFinanceiro+"/"+ aluno +"/"+pastaGoogle , {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          body: formData,
        })
        */
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
      });
  }
};

export default function GerarEvidencias() {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalParams, setModalParams] = useState({});
  const [idRootGoogleDrive, setIdRootGoogleDrive] = useState('');

  useEffect(() => {
    fetch(API_URL + 'geraEvidencias')
      .then((response) => response.json())
      .then((data) => {
        const updatedData = data.map((item, index) => ({
          ...item,
          id: item._id,
        }));
        setTableData(updatedData);
        console.log(updatedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleImprimirMinuta = (params) => {
    let aluno = params.row.aluno;
    let colegio = params.row.colegio;
    let nomeResponsavelFinanceiro = params.row.nomeResponsavelFinanceiro;

    fetch(API_URL + 'inadimplentes/' + colegio + '/' + nomeResponsavelFinanceiro + '/' + aluno)
      .then((response) => response.json())
      .then((data) => {
        console.log(data[0].idMinuta);
        window.open('https://docs.google.com/document/d/' + data[0].idMinuta, '_blank');
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleOpenModal = (params) => {
    let aluno = params.row.aluno;
    let colegio = params.row.colegio;
    let nomeResponsavelFinanceiro = params.row.nomeResponsavelFinanceiro;

    fetch(API_URL + 'inadimplentes/' + colegio + '/' + nomeResponsavelFinanceiro + '/' + aluno)
      .then((response) => response.json())
      .then((data) => {
        console.log(data[0].idPastaInadimplenteGoogleDrive);
        setIdRootGoogleDrive(data[0].idPastaInadimplenteGoogleDrive);
        setModalParams(params.row);
        setOpen(true);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const columns = [
    { field: 'colegio', headerName: 'Colégio', width: 250 },
    { field: 'nomeResponsavelFinanceiro', headerName: 'Responsável Financeiro', width: 250 },
    { field: 'aluno', headerName: 'Aluno', width: 250 },
    {
      field: 'upload',
      type: 'actions',
      headerName: 'Conversa do WhatsApp',
      width: 150,
      cellClassName: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          icon={<CloudUploadIcon />}
          label='Conversa do WhatsApp'
          className="textPrimary"
          onClick={() => handleOpenModal(params)}
          color="inherit"
        />,
      ],
    },
  ];

  return (
    <Box height={400} width="100%">
      <DataGrid rows={tableData} columns={columns} pageSize={8} />

      <Modal open={open} onClose={handleCloseModal}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
          <Typography variant="h6" component="h3">
            <b>Upload das Conversas do Whastapp com: </b>
          </Typography>

          <Typography sx={{ m: 2 }}>
            Responsável Financeiro: <b>{modalParams.nomeResponsavelFinanceiro}</b>
          </Typography>
          <Typography sx={{ m: 2 }}>
            Pasta no Google Docs: <b>{idRootGoogleDrive}</b>
          </Typography>
          <Box sx={{ justifyContent: "center", width: "100%" }} >
            <Button
              variant="outlined"
              color="success"
              startIcon={<UploadFileIcon />}
              component="label"
              htmlFor="file-upload"
              style={{
                width: '70%',
                marginLeft: 10,
                marginRight: 30,
                cursor: 'pointer', // Adicionado para indicar que é clicável
              }}
            >
              <b>Upload Minuta Assinada em PDF</b>
              <input
                id="file-upload"
                type="file"
                accept="application/pdf" // Aceitando apenas arquivos PDF
                onChange={(event) => handleFileUpload(event.target.files[0], idRootGoogleDrive,  modalParams.colegio, modalParams.nomeResponsavelFinanceiro, modalParams.aluno)}
                style={{
                  display: 'none', // Removido para exibir o botão
                }}
              />
            </Button>
          </Box>

        </Box>
      </Modal>
    </Box>
  );
}
