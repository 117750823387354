import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';

function createData(inadimplente, nomeFilho, valorAtualizado) {
  return {
    inadimplente,
    nomeFilho,
    valorAtualizado,
    documentos: [
      {
        item: '1',
        descricao: 'RG',
        URL: 1000,
        conferido: true
      },
      {
        item: '2',
        descricao: 'CPF',
        URL: 1000,
        conferido: true
      },
      {
        item: '3',
        descricao: 'Comprovante de Endereço',
        URL: 1000,
        conferido: true
      },
    ],
    debitos: [
      {
        ordem: '1',
        titulo: 'Mensalidade',
        valorPrimitivo: 1000,
        indiceMes: 89.000,
        indiceAtual: 89.000,
        subTotal: 1100,
        juros: 100,
        total: 1500
      },
      {
        ordem: '2',
        titulo: 'Mensalidade',
        valorPrimitivo: 1000,
        indiceMes: 89.000,
        indiceAtual: 89.000,
        subTotal: 1100,
        juros: 100,
        total: 1500
      },
      {
        ordem: '3',
        titulo: 'Mensalidade',
        valorPrimitivo: 1000,
        indiceMes: 89.000,
        indiceAtual: 89.000,
        subTotal: 1100,
        juros: 100,
        total: 1500
      },
      {
        ordem: '',
        titulo: 'Totais',
        valorPrimitivo: 3000,
        indiceMes: '',
        indiceAtual:  '',
        subTotal: 3300,
        juros: 200,
        total: 4500
      },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ '': { borderBottom: 'unset' } }}>
        <TableCell >
          <IconButton
            aria-label="root"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>


        <TableCell component="th" scope="row"  > {row.inadimplente} </TableCell>
        <TableCell align="right">{row.nomeFilho}</TableCell>
        <TableCell align="right">{row.valorAtualizado}</TableCell>



 

        <TableCell align="right" >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <PublishedWithChangesOutlinedIcon /> : <PublishedWithChangesOutlinedIcon />}
          </IconButton>
        </TableCell>




      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Documentos a Conferir
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell align="right">URL</TableCell>
                    <TableCell align="right">Conferido</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {row.documentos.map((documentosRow) => (
                    <TableRow key={documentosRow.item}>
                      <TableCell component="th" scope="row"> {documentosRow.item} </TableCell>
                      <TableCell>{documentosRow.descricao}</TableCell>
                      <TableCell align="right">{documentosRow.URL}</TableCell>
                      <TableCell align="right" >
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => setOpen(!open)}
                        >
                          {open ? <PublishedWithChangesOutlinedIcon /> : <PublishedWithChangesOutlinedIcon />}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}

                </TableBody>

              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Débitos em Aberto
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Ordem</TableCell>
                    <TableCell>Titulo</TableCell>
                    <TableCell align="right">Valor Primitivo(R$)</TableCell>
                    <TableCell align="right">Indice do Mes</TableCell>
                    <TableCell align="right">Indice Atual</TableCell>
                    <TableCell align="right">SubTotal</TableCell>
                    <TableCell align="right">Juros(R$)</TableCell>
                    <TableCell align="right">Total(R$)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {row.debitos.map((debitosRow) => (
                    <TableRow key={debitosRow.ordem}>
                      <TableCell component="th" scope="row"> {debitosRow.ordem} </TableCell>
                      <TableCell>{debitosRow.titulo}</TableCell>
                      <TableCell align="right">{debitosRow.valorPrimitivo}</TableCell>
                      <TableCell align="right">{debitosRow.indiceMes}</TableCell>
                      <TableCell align="right">{debitosRow.indiceAtual}</TableCell>
                      <TableCell align="right">{debitosRow.subTotal}</TableCell>
                      <TableCell align="right">{debitosRow.juros}</TableCell>
                      <TableCell align="right">{debitosRow.total}</TableCell>

                    </TableRow>
                  ))}

                </TableBody>

              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    // carbs: PropTypes.number.isRequired,
    // fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    ordem: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

const rows = [
  createData('Paulo Henrique', "Pedrinho", 1000, "89.0000", "89.0000", 1100, 1200, 120, 1500),
  createData('Paula Freitas', "Mariazinha", 1000, "89.0000", "89.0000", 1100, 1200, 120, 1500),

  /* createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
   createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
   createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
   createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),*/
];

export default function ListaEnvioCofreios() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow sx={{ '& > *': { borderBottom: 'unset', background: '#E8F8F5' } }}>
            <TableCell />
            <TableCell>Inadimplente </TableCell>
            <TableCell align="right">Nome do Filho</TableCell>
            <TableCell align="right">Valor Atualizadoo&nbsp;(R$)</TableCell>
            <TableCell align="right">Atualizar</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}