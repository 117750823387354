import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PieChart } from '@mui/x-charts/PieChart';
import { API_URL } from '../../constants';
import Typography from '@mui/material/Typography';


const green = {
    50: '#E8F8F5',
    100: '#D4EFDF',
    200: '#7DCEA0',
    300: '#afb8c1',
    400: '#52BE80',
    500: '#27AE60',
    600: '#229954',
    700: '#1E8449',
    800: '#196F3D',
    900: '#0B5345',
    1000: '#08362D',
};

const StyledTabsList = styled('div')(
    ({ theme }) => `
        min-width: 900px;
        --background-color: ${green[100]};
        border-radius: 12px;
       -- margin: 5px;
        display: flex;
        padding: 10px;
        align-items: center;
        justify-content: center;
        align-content: space-between;
        box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? '#32383f' : '#d0d7de'};
    `
);





const PieChartComp = () => {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        fetchPagamentos();
    }, []);

    const fetchPagamentos = async () => {
        try {
            const response = await fetch(API_URL + 'pagamento');
            if (!response.ok) {
                throw new Error('Erro ao buscar pagamentos');
            }
            const data = await response.json();
            console.log('Dados de pagamentos:', data);

            const newRows = data.map((pagamento) => ({
                id: pagamento.id,
                colegio: pagamento.colegio,
                inadimplente: pagamento.nomeResponsavelFinanceiro,
                nomeFilho: pagamento.aluno,
                valorAtualizado: parseFloat(pagamento.valorAtualizado), // Converte para número
                parcelas: pagamento.debitos.map((debito) => ({
                    ordem: debito.ordem,
                    dataPagamento: new Date(debito.dataPagamento).toLocaleDateString(),
                    valor: debito.valor,
                    conferido: debito.conferido,
                    upload: debito.upload // Ajustado conforme necessário
                }))
            }));

            setRows(newRows);
            console.log("Dados atualizados:", newRows);

        } catch (error) {
            console.error('Erro ao buscar pagamentos:', error);
            // Tratar erro conforme necessário
        }
    };

    const calcularTotaisParcelas = (rows) => {
        let totalEmAberto = 0;
        let totalEmAtraso = 0;
        let totalRecebidos = 0;

        rows.forEach((pagamento) => {
            pagamento.parcelas.forEach((parcela) => {
                if (!parcela.conferido) {
                    totalEmAberto += parcela.valor;
                } else if (parcela.conferido) {
                    totalRecebidos += parcela.valor;
                } else if (!parcela.conferido && new Date(parcela.dataPagamento) > new Date()) {
                    totalEmAtraso += parcela.valor;
                }
            });
        });

        return [
            { id: 0, value: totalEmAberto,  label: 'Pagamentos Em Aberto' ,  color:'rgb(46, 150, 255)'},
            { id: 1, value: totalEmAtraso, label: 'Pagamentos Em Atraso' , color:  "#FFBF00" },
            { id: 2, value: totalRecebidos, label: 'Pagamentos Recebidos' , color: green[500] },
        ];
    };




    const totaisParcelas = calcularTotaisParcelas(rows);
    // const mesesLabels = Array.from({ length: 12 }, (_, index) => `Janeiro ${index + 1}`);


    return (
        <CardContent sx={{ m: 1 }}>
            <Typography variant="h6" gutterBottom>
                Total dos Valores Mes
            </Typography>
            <StyledTabsList>

            <PieChart
                    series={[{ data: totaisParcelas }]}
                    width={700}
                    height={250}
            
                    labelAccessor={(datum) => `${datum.label}: R$ ${datum.value.toFixed(2)}`}
                />
            </StyledTabsList>


        </CardContent>



    );
};







export default PieChartComp;
