
import { FormControl } from '@mui/base/FormControl';
import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import { Colegio } from '../../DTO/Colegio.js';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import crudColegio from "../../Service/CrudColegio.js";
import Autocomplete from '@mui/material/Autocomplete';
import DataGridColegio from '../datagrids/DataGridCadastroColegio.js';
import ValidaTextField from '../Helper/ValidaTextField.js';
import PickerGoogleDrive from '../../googledrive/PickerGoogleDrive.js';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import crudEscritorioAdvocacia from '../../Service/CrudEscritorio.js';
import CreateFolderID from '../googleApi/Cria Pastas /createFolderIDColegio.js';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import BusinessIcon from '@mui/icons-material/Business';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';


const colegio = new Colegio();
let crudsColegio = new crudColegio();
const crudescr = new crudEscritorioAdvocacia();


function cadastraColegio() {
  crudsColegio.cadastraColegioAPI(colegio);

}

function limpaform() {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
  );
}

function validaEmail(email) {
  const validacao = new ValidaTextField("");

  validacao.validacaoEmail(email)
  colegio.setEmailColegio(email);

}

const tipoConta = [
  { label: 'CORRENTE', id: 1 },
  { label: 'POUPANCA', id: 2 },

]


const bancos = [{ label: "A.J. RENNER S.A.", id: 654 },
{ label: "ABC-BRASIL S.A.	", id: 246 },
{ label: "ARBI S.A.	", id: 213 },
{ label: "AZTECA DO BRASIL S.A.	", id: 19 },
{ label: "BANCO ALFA	", id: 25 },
{ label: "Banco Classico S.A	", id: 241 },
{ label: "BANCO DA CHINA BRASIL S.A.	", id: 83 },
{ label: "BANCO DE LA NACION ARGENTINA	", id: 300 },
{ label: "BANCO DE LA PROVINCIA DE BUENOS AIRES	", id: 495 },
{ label: "BANCO DE LA REPUBLICA ORIENTAL DEL URUGUAY	", id: 494 },
{ label: "BANCO DO BRASIL	", id: 1 },
{ label: "BANCO DO ESTADO DO PARÁ S.A	", id: 37 },
{ label: "BANCO TOKYO MITSUBISH UFJ BRASIL S.A	", id: 456 },
{ label: "BANCO WESTLB DO BRASIL	", id: 370 },
{ label: "BANCOOB	", id: 756 },
{ label: "BANCOOB	", id: 756 },
{ label: "BANESE	", id: 47 },
{ label: "BANESPA	", id: 33 },
{ label: "BANESTES	", id: 21 },
{ label: "BANIF-BANCO INTERNACIONAL DO FUNCHAL (BRASIL) S.A	", id: 719 },
{ label: "BANK OF AMERICA MERRILL LYNCH BANCO MULTIPLO S.A.	", id: 755 },
{ label: "BANRISUL	", id: 41 },
{ label: "BARCLAYS S.A.	", id: 740 },
{ label: "BASA	", id: 3 },
{ label: "BBM S.A	", id: 107 },
{ label: "BBN BANCO BRASILEIRO DE NEGOCIOS S.A	", id: 81 },
{ label: "BCV - BANCO DE CREDITO E VAREJO S.A	", id: 250 },
{ label: "BEM	", id: 36 },
{ label: "BERJ S.A	", id: 122 },
{ label: "BES INVESTIMENTO DO BRASIL SA - BANCO DE INVESTIM.	", id: 78 },
{ label: "BGN S.A.	", id: 739 },
{ label: "BIC BANCO	", id: 320 },
{ label: "BM&F DE SERV. DE LIQUIDACAO E CUSTODIA S.A	", id: 96 },
{ label: "BMC S.A.	", id: 394 },
{ label: "BMG S.A.	", id: 318 },
{ label: "BNB	", id: 4 },
{ label: "BNP PARIBAS BRASIL S.A	", id: 752 },
{ label: "BNY MELLON S.A.	", id: 17 },
{ label: "BOA VISTA INTERATLANTICO S.A	", id: 248 },
{ label: "BONSUCESSO S.A.	", id: 218 },
{ label: "BPN BRASIL BANCO MULTIPLO S.A	", id: 69 },
{ label: "BRACCE S.A.	", id: 65 },
{ label: "BRADESCO	", id: 237 },
{ label: "BRASCAN S.A.	", id: 225 },
{ label: "BRASIL PLURAL S.A. BANCO MULTIPLO	", id: 125 },
{ label: "BRB	", id: 70 },
{ label: "BRICKELL S A CREDITO, FINANCIAMENTO E INVESTIMENTO	", id: 92 },
{ label: "BTG PACTUAL S.A.	", id: 208 },
{ label: "CACIQUE S.A.	", id: 263 },
{ label: "CAIXA ECON. FEDERAL	", id: 104 },
{ label: "CAIXA GERAL - BRASIL S.A.	", id: 473 },
{ label: "CAPITAL S.A.	", id: 412 },
{ label: "CARGILL S.A	", id: 40 },
{ label: "CC UNICRED BRASIL CENTRAL	", id: 112 },
{ label: "CC UNIPRIME NORTE DO PARANA	", id: 84 },
{ label: "CECOOPES-CENTRAL DAS COOP DE ECON E CRED MUTUO DO	", id: 114 },
{ label: "CECREDI	", id: 85 },
{ label: "CEDULA S.A.	", id: 266 },
{ label: "CIFRA S.A.	", id: 233 },
{ label: "CITIBANK	", id: 745 },
{ label: "Citibank N.A.	", id: 477 },
{ label: "COOPERATIVA CENTRAL DE CREDITO DO ESTADO DE SP	", id: 90 },
{ label: "COOPERATIVA CENTRAL DE CREDITO NOROESTE BRASILEIRO	", id: 97 },
{ label: "COOPERATIVA DE CREDITO RURAL DA REGIAO DA MOGIANA	", id: 89 },
{ label: "CR2 S.A	", id: 75 },
{ label: "CREDIALIANCA COOPERATIVA DE CREDITO RURAL	", id: 98 },
{ label: "CREDIT AGRICOLE BRASIL S.A.	", id: 222 },
{ label: "CREDIT SUISSE (BRASIL) S.A.	", id: 505 },
{ label: "DAYCOVAL	", id: 707 },
{ label: "DEUTSCHE BANK S. A. - BANCO ALEMAO	", id: 487 },
{ label: "DIBENS S.A.	", id: 214 },
{ label: "FATOR S.A.	", id: 265 },
{ label: "FIBRA	", id: 224 },
{ label: "FICSA S.A.	", id: 626 },
{ label: "GERADOR S.A.	", id: 121 },
{ label: "GUANABARA S.A.	", id: 612 },
{ label: "HIPERCARD BANCO MULTIPLO S.A	", id: 62 },
{ label: "HSBC	", id: 399 },
{ label: "IBI	", id: 63 },
{ label: "INDUSTRIAL DO BRASIL S. A.	", id: 604 },
{ label: "INDUSVAL S.A.	", id: 653 },
{ label: "ING BANK N.V.	", id: 492 },
{ label: "INTERCAP S.A.	", id: 630 },
{ label: "INTERMEDIUM S.A.	", id: 77 },
{ label: "Investcred Unibanco	", id: 249 },
{ label: "ITAÚ	", id: 341 },
{ label: "ITAU HOLDING FINANCEIRA S.A	", id: 652 },
{ label: "Itaú-BBA	", id: 184 },
{ label: "J. SAFRA S.A.	", id: 74 },
{ label: "J.P. MORGAN S.A.	", id: 376 },
{ label: "JOHN DEERE S.A.	", id: 217 },
{ label: "JPMORGAN CHASE BANK	", id: 488 },
{ label: "KDB DO BRASIL S.A	", id: 76 },
{ label: "KEB DO BRASIL S.A.	", id: 757 },
{ label: "Luso Brasileiro	", id: 600 },
{ label: "MAXIMA S.A.	", id: 243 },
{ label: "NUBANK ", id: 260 },
{ label: "MERCANTIL DO BRASIL	", id: 389 },
{ label: "MODAL S.A.	", id: 746 },
{ label: "MORGAN STANLEY DEAN WITTER S.A	", id: 66 },
{ label: "NATIXIS BRASIL S.A. - BANCO MòLTIPLO	", id: 14 },
{ label: "NBC BANK BRASIL S.A.- BANCO MULTIPLO	", id: 753 },
{ label: "OPPORTUNITY S.A.	", id: 45 },
{ label: "ORIGINAL DO AGRONEGOCIO S.A.	", id: 79 },
{ label: "ORIGINAL S.A.	", id: 212 },
{ label: "PANAMERICANO	", id: 623 },
{ label: "PARANA BANCO S.A.	", id: 254 },
{ label: "PAULISTA	", id: 611 },
{ label: "PECUNIA S.A.	", id: 613 },
{ label: "PETRA S.A.	", id: 94 },
{ label: "PINE S.A.	", id: 643 },
{ label: "POTTENCIAL S.A.	", id: 735 },
{ label: "RABOBANK INTERNATIONAL BRASIL S.A.	", id: 747 },
{ label: "RANDON S.A.	", id: 88 },
{ label: "RENDIMENTO S.A.	", id: 633 },
{ label: "RIBEIRÃO PRETO	", id: 741 },
{ label: "RODOBENS S.A	", id: 120 },
{ label: "RURAL	", id: 453 },
{ label: "RURAL MAIS S.A	", id: 72 },
{ label: "SAFRA	", id: 422 },
{ label: "SCOTIABANK BRASIL S.A BANCO MULTIPLO	", id: 751 },
{ label: "SEMEAR S.A.	", id: 743 },
{ label: "SICREDI	", id: 748 },
{ label: "SIMPLES S.A.	", id: 749 },
{ label: "SOCIETE GENERALE BRASIL S.A	", id: 366 },
{ label: "SOFISA S.A.	", id: 637 },
{ label: "SUMITOMO MITSUI BRASILEIRO S.A.	", id: 464 },
{ label: "TOPAZIO S.A.	", id: 82 },
{ label: "Triangulo	", id: 634 },
{ label: "UNICARD BANCO MULTIPLO S.A	", id: 230 },
{ label: "UNICRED CENTRAL RS - CENTRAL DE COOP ECON CRED MUT	", id: 91 },
{ label: "UNICRED CENTRAL SANTA CATARINA	", id: 87 },
{ label: "UNIPRIME CENTRAL - CENTRAL INT DE COOP DE CRED LTD	", id: 99 },
{ label: "VOTORANTIM	", id: 655 },
{ label: "VR S.A.	", id: 610 },
{ label: "WESTERN UNION DO BRASIL S.A.	", id: 119 },
{ label: "WOORI BANK DO BRASIL S.A	", id: 124 }]

function CepSearch() {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState({});

  const fetchCepData = () => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        setAddress(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  colegio.setcepcolegio(address.cep);
  colegio.setenderecoColegio(address.logradouro);
  colegio.setbairroColegio(address.bairro);
  colegio.setcidadeColegio(address.localidade);
  colegio.setestadoColegio(address.uf);

  const limpaCep = () => {
    setAddress("");
    setCep("");
  };


  return (
    <div>
      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        variant="standard"
        sx={{ width: '30%' }}
      />
      <Button variant="standard" onClick={fetchCepData}
        sx={{ marginTop: 2, marginLeft: 0, justifyContent: "center", width: "5%" }}
        color="success" endIcon={<SendIcon />}> </Button>

      <TextField
        label="Numero do Endereço"
        color='secondary'
        value={colegio.getnumerocolegio()}
        onChange={e => colegio.setnumerocolegio(e.target.value)}
        variant="standard"
        sx={{ width: '20%', marginLeft: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Complemento"
        color='secondary'
        value={colegio.getcomplementocolegio()}
        onChange={e => colegio.setcomplementocolegio(e.target.value)}
        variant="standard"
        sx={{ width: '30%', marginLeft: 2 }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
      />


      <div>
        {address.cep && (
          <div>
            <br />
            <h6>Informações do Endereço:</h6>
            <p>CEP: {address.cep}</p>
            <p>Logradouro: {address.logradouro}</p>
            <p>Bairro: {address.bairro}</p>
            <p>Cidade: {address.localidade}</p>
            <p>Estado: {address.uf}</p>

          </div>
        )}

      </div>
    </div>


  );
}


export default function CadastroColegio() {
  const [idLogoGoogleDrive, setIdLogoGoogleDrive] = useState('');

  const [escritoriosAdvogados, setEscritoriosAdvogados] = useState([]); // Initialize with empty array

  useEffect(() => {
    async function fetchEscritorios() {
      const escritoriosData = await crudescr.buscaEscritoriosAPI();
      if (escritoriosData) {
        setEscritoriosAdvogados(escritoriosData);
      }
    }
    fetchEscritorios();
  }, []); // Run once on component mount



  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",
    }} >
      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',


      }} >



        <Box sx={{
          width: '100%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2

        }} >
          <FormControl defaultValue="">
            <Stack spacing={0} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 3, align: "Center" }}>
              <h4>Cadastro do Colégio</h4>
              <br />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Nome do Colégio"
                name="Colegio"
                sx={{ marginBottom: 1 }}
                onChange={e => colegio.setNomeColegio(e.target.value)}
                value={colegio.getNomeColegio()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                required
              />


              <Box sx={{ display: "flex" }}>
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="CNPJ do Colégio"
                  sx={{ width: "30%", marginRight: 2, marginBottom: 2, fontGrid: "small" }}
                  onChange={e => colegio.setcnpjColegio(e.target.value)}
                  value={colegio.getcnpjColegio()}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AssignmentIndIcon />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  required
                />
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={escritoriosAdvogados}
                  onChange={(e, value) => colegio.setEscritorio(value && value.label ? value.label : "Elizabeth Ribeiro Curi Advogados")}
                  sx={{ width: "70%", fontGrid: "small" }}
                  onBlur={e =>
                    escritoriosAdvogados.forEach(item => {
                      if (item.label == e.target.value) {
                        console.log("Item ID:", item.id);
                        console.log("Item Label:", item.label);
                        console.log("Item idRoot:", item.idPastaRootColegio);
                        colegio.setidRootGoogleDrive(item.idPastaRootColegio);
                        setIdLogoGoogleDrive(colegio.getidRootGoogleDrive());
                      }
                    })
                  }
                  renderInput={(params) => <TextField {...params} label="Escritório de Advocacia" variant='standard' />}
                  fullWidth
                  required
                />

              </Box>
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="id Root"
                name="idRootEscritorio"
                value={idLogoGoogleDrive}
                sx={{ display: 'none' }}
                fullWidth
                required
              />


              <Box sx={{ display: "flex" }}>

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={bancos}
                  sx={{ width: "30%", fontGrid: "small", marginRight: 2 }}
                  onChange={(e, value) => colegio.setbanco(value.label)}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Banco"
                    variant='standard'
                    sx={{ width: "100%", fontGrid: "small" }} />}
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Agencia"
                  name="agencia"
                  sx={{ width: "30%", marginRight: 2, marginBottom: 2, fontGrid: "small" }}
                  onChange={(e, value) => colegio.setagencia(e.target.value)}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Conta"
                  name="Conta"
                  sx={{ width: "30%", marginRight: 2, marginBottom: 2, fontGrid: "small" }}
                  onChange={(e, value) => colegio.setconta(e.target.value)}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />
              </Box>
              <Box sx={{ display: "flex" }}>

                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="PIX"
                  name="PIX"
                  sx={{ width: "30%", marginRight: 2, marginBottom: 2, fontGrid: "small" }}
                  onChange={(e, value) => colegio.setPIX(e.target.value)}

                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BusinessIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  required
                />



                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  color='secondary'
                  options={tipoConta}
                  sx={{ width: "30%", fontGrid: "small", marginRight: 2 }}
                  onChange={(e, value) => colegio.settipoConta(value.label)}
                  renderInput={(params) => <TextField
                    {...params}
                    label="Tipo de Conta "
                    variant='standard'
                    sx={{ width: "100%", fontGrid: "small" }} />}
                  required
                />
              </Box>


              <CepSearch />


              <Box sx={{ align: "middle", width: "100%" }} >
                <Button variant="outlined" onClick={cadastraColegio} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>
                <Button variant="outlined" onClick={limpaform} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>

            </Stack>

          </FormControl>

        </Box>
        <Box sx={{ justifyContent: "right", width: "50%", position: "relative", flexGrow: 2 }} >
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%" }}
            image="https://best.tur.br/wp-content/uploads/2022/02/HIGH-SCHOOL-1.jpg"
            alt="Live from space album cover"
          />
        </Box>
      </Card>
      <br />
      <CreateFolderID />

      <Divider variant="middle" sx={{ margin: 2 }} />
      <Card variant="outlined">
        <Box component="BxgridCadastroCol" sx={{
          margin: "0px",
          p: 3,
          height: '90%',
          width: '100%',
          justifyContent: 'center',
          background: '#fff'
        }} >

          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h2>Colégios Cadastrados</h2>
            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DataGridColegio />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>


  );

}
