import React from "react";
import ItemDebito from './ItemDebito';


let id;
let colegio;
let nomeResponsavelFinanceiro;
let aluno;
let rootUrlInadimplente;
let itemDebito = [];
let total_debito_atualizado=0;
let total_valor_primitivo=0;
let total_subtotal=0;
let total_juros=0;




/*
  // Exemplo de uso da classe DebitoItem
  const debitoItem = new DebitoItem(1, "1", "Mensalidade", "10/08/2023", 600);
  debitoItem.indiceVencimento = 1.05;
  debitoItem.indiceAtual = 1.1;
  debitoItem.indiceMes = 1.08;
  debitoItem.subtotal = 648;
  debitoItem.juros = 20;
  debitoItem.total = 668;
  debitoItem.valorDebito = 800;
  
  console.log(debitoItem); */
export default class Debito {


    constructor() {
        this.id = null;
        this.colegio = null;
        this.nomeResponsavelFinanceiro = null;
        this.aluno = null;
        this.itemDebito = []; // Inicializa o array vazio
        this.rootUrlInadimplente = null;
    }

    //id;
    getid() {
        return this.id;
    }
    setid(value) {
        this.id = value;
    }


    //  colegio;
    getcolegio() {
        return this.colegio;
    }
    setcolegio(value) {
        this.colegio = value;
    }

    getaluno() {
        return this.aluno;
    }
    setaluno(value) {
        this.aluno = value;
    }

    //nomeResponsavelFinanceiro;
    getnomeResponsavelFinanceiro() {
        return this.nomeResponsavelFinanceiro;
    }
    setnomeResponsavelFinanceiro(value) {
        this.nomeResponsavelFinanceiro = value;
    }

    getitemDebito() {
        return this.itemDebito;
    }

    setitemDebito(value) {
        this.itemDebito = value;
    }


    adicionarItemDebito(item) {
        this.itemDebito.push(item);
    }
    //  url Inadimplente;
    getRootUrlInadimplente() {
        return this.rootUrlInadimplente;
    }
    setRootUrlInadimplente(value) {
        this.rootUrlInadimplente = value;
    }
//  let total_debito_atualizado;
getTotal_debito_atualizado() {
    return this.total_debito_atualizado;
}
setTotal_debito_atualizado(value) {
    this.total_debito_atualizado = value;
}
//  let total_debito_atualizado;
getTotal_valor_primitivo() {
    return this.total_valor_primitivo;
}
setTotal_valor_primitivo(value) {
    this.total_valor_primitivo = value;
}
//  let total_debito_atualizado;
getTotal_subtotal() {
    return this.total_subtotal;
}
setTotal_subtotal(value) {
    this.total_subtotal = value;
}
// total_juros
getTotal_juros() {
    return this.total_juros;
}
setTotal_juros(value) {
    this.total_juros= value;
}
 

}

