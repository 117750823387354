import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { gapi } from 'gapi-script';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { API_URL } from '../../../constants';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Inadimplente from '../../../DTO/Inadimplente';
import GeraPDF from '../CriaContratos/mesclaDocumentosPDF';


const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
//const SCOPES = 'https://www.googleapis.com/auth/documents, https://www.googleapis.com/auth/drive.file, https://www.googleapis.com/auth/docs ';
//const SCOPE = "https://www.googleapis.com/auth/drive"  
const SCOPES = 'https://www.googleapis.com/auth/documents';

 
const pdf = new GeraPDF();

export default class MergeAndEditDocument {
  //  export default function MergeAndEditDocument(rootURL, colegio, inadimplente, aluno) {


  async mergeDataInDocument(rootURL, colegio, inadimplente, aluno, open) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const rootFolderId = rootURL;
    const fileId = '1K0AldailaJc0Z2OFCYfHG2B6s23Xdhi18Oa4T7FaUlA';
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];

    const dataAtual = new Date();
    const dia = dataAtual.getDate();
    const mes = meses[dataAtual.getMonth()];
    const ano = dataAtual.getFullYear();

    const dataFormatada = `${dia} de ${mes} de ${ano}`;
    // const contrato = populaContrato(colegio, inadimplente, aluno); // Chama a função dentro do useEffect


    // Criação do template


    const requestData = {
      title: 'Procuracao',
      name: '02.PROCURACAO',
      parents: [rootFolderId],
    };

    console.log(API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno);
    try {
      const resposta = await fetch(API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno, {
        method: 'GET',
        mode: 'cors'
      })
        .then((response) => response.json())
        .then((json) => {

          const contrato = json;
          if (!contrato || !contrato.aluno || !contrato.inadimplente || !contrato.inadimplente.nomeResponsavelFinanceiro) {
            console.log("PROBLEMA NA CHAMADA: " +  API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno );  
            console.log('CONTRATO');
            console.log(contrato);
       
            return;
          }
          console.log('CONTRATO');
          console.log(contrato);
          console.log('DADOS DO COLEGIO');
          console.log(contrato.colegio.nomeColegio)
          ///////////CHAMA API GOOGLEAPI
          fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(requestData),
          })
            .then((res) => res.json())
            .then((val) => {
              console.log(val);

              const newDocumentId = val.id; // ID do novo documento criado

              // Carregar a API do Google Docs
              gapi.client.load('https://docs.googleapis.com/$discovery/rest?version=v1')
                .then(() => {



                  
                  // Substituir variáveis no documento
                  gapi.client.docs.documents.batchUpdate({
                    documentId: newDocumentId,
                      requests: [
                        {
                          replaceAllText: {
                            containsText: {
                              text: '<<Logo Escritorio>>', // Texto a ser substituído
                              matchCase: false,
                            },
                            replaceText: contrato.escritorio.idLogoGoogleDrive, // Novo texto a ser inserido
                          },
                        },
                      ],

                      requests:  [
                     /* {
                        insertInlineImage: {
                          location: {
                            index: 1, // O índice onde você deseja inserir a imagem
                          },
                          uri: 'https://uploaddeimagens.com.br/images/004/621/915/original/Elizabeth_Logo.png?1695942550', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                        // uri: '../../../../public/Imagens/Elizabeth_Logo.png',
                         //contentUri: 'https://drive.google.com/file/d/1pEbQd6raRMFBM8XJiZFo4oRQjze0JZA4/view?usp=sharing', // Substitua 'URL_DA_IMAGEM_DO_GOOGLE_DRIVE' pela URL de compartilhamento da imagem no Google Drive
                         objectSize: {
                            width: {
                              magnitude: 150, // Largura da imagem em unidades de pixel
                              unit: 'PT', // Unidade de medida (pixels)
                            },
                            height: {
                              magnitude: 150, // Altura da imagem em unidades de pixel
                              unit: 'PT', // Unidade de medida (pixels)
                            },
                          },
                        },
                      },*/
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Logo Escritorio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: '', // Deixe vazio para remover o texto
                        },
                      },
                      // Outras substituições de texto

                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.nomeColegio, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio CNPJ>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.cnpjColegio, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio Endereço>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.enderecoColegio + ' nº'
                            + contrato.colegio.numerocolegio + ', '
                            //+ (contrato.colegio.complementocolegio==undefined ? '': contrato.colegio.complementocolegio + ' - ' 
                            + contrato.colegio.bairroColegio + ', no CEP: '
                            + contrato.colegio.cepColegio + ', '
                            + contrato.colegio.cidadeColegio + ' - '
                            + contrato.colegio.estadoColegio
                        }
                      },
                  

                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio Socio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText:  (contrato.socioColegio !== null && contrato.socioColegio.NomeSocio!== null) ?

                            (
                              (contrato.socioColegio.NomeSocio ? contrato.socioColegio.NomeSocio.toUpperCase() : "") + ', ' +
                              (contrato.socioColegio.nacionalidade || "") + ', ' +
                              (contrato.socioColegio.estadoCivil || "") + ', ' +
                              (contrato.socioColegio.profissao || "") + ', portadora da cédula de identidade RG n° ' +
                              (contrato.socioColegio.rg || "") + ', e do CPF/MF n° ' +
                              (contrato.socioColegio.cpf || "")
                            ) : '', // Novo texto a ser inserido
                        },
                      },

                     {
                        replaceAllText: {
                          containsText: {
                            text: '<<Advogados>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: "Elizabeth Ribeiro Curi e Antonio Roberto Fudaba, brasileiros, casados, inscritos respectivamente na OAB/SP sob os números 276.192 e 88.599"
                  
       /*                       contrato.advogado.enderecoAdvogado + ', '
                            + contrato.advogado.nacionalidade + ', '
                            + contrato.advogado.estadoCivil + ', inscritos respectivamente na OAB/SP sob os números '
                            + contrato.advogado.OAB*/
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Nome Completo>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.nomeResponsavelFinanceiro, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<nacionalidade>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.nacionalidade, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<estado civil>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.estadoCivil, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Profissão>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.profissao,  // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<RG>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.rg, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<CPF>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.cpf, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Endereço>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.endereco, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<número>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.numero, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<complemento>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.complemento, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Bairro>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.bairro, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<CEP>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.cep, // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Cidade>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.cidade,
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Estado>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.estado,
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<E-mail>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.email,
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<WhatsApp>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.whatsapp,
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<data>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: dataFormatada, // Novo texto a ser inserido
                        },
                      },
                  /*   {
                        insertInlineImage: {
                          location: {
                            index: 1995, // O índice onde você deseja inserir a imagem
                          },
                        //  uri: 'https://uploaddeimagens.com.br/images/004/465/674/original/Screenshot_2023-05-14_at_02.43.00.png', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                          uri: 'https://uploaddeimagens.com.br/images/004/465/674/original/Screenshot_2023-05-14_at_02.43.00.png', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                        
                        objectSize: {
                            width: {
                              magnitude: 400, // Largura da imagem em unidades de pixel
                              unit: 'PT', // Unidade de medida (pixels)
                            },
                            height: {
                              magnitude: 30, // Altura da imagem em unidades de pixel
                              unit: 'PT', // Unidade de medida (pixels)
                            },
                          },
                        },
                      },*/
                     
                     {
                        replaceAllText: {
                          containsText: {
                            text: '<<Footer do Escritório>>', // Texto a ser substituído
                            matchCase: true,
                          },
                          replaceText: '', // Deixe vazio para remover o texto
                        },
                      },
                    ],
                  })
                    .then(function (response) {
                      // Manipule a resposta aqui
                      console.log('pagina criada');
                      console.log(response.result.documentId);
                      pdf.mergeDataInDocument(rootURL, response.result.documentId, "O2.PROCURACAO")
                      if(open!="FECHADO"){
                      const newWindow = window.open("https://docs.google.com/document/d/" + response.result.documentId, '_blank');
                      //  pdfMake.createPdf("https://docs.google.com/document/d/" + response.result.documentId).open({}, window.open('', '_blank'));

                      console.log("Variável substituída com sucesso", response);
                      }


                    })
                    .catch(function (err) {
                      console.error("Erro ao substituir variável", err);
                    });

                    
                });
            });
          ///////FINALIZA BUSCA API 
        });
      return resposta;
    } catch (error) {
      console.error(error);
    }
  }

  /*return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={mergeDataInDocument}>
      <LocalPrintshopIcon />
      </Button>
    </Stack>
  );*/
  //}

}
