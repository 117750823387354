import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { TextField, Stack, Divider } from '@mui/material';
import { gapi } from 'gapi-script';
import Box from '@mui/material/Box';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Inadimplente from '../../../DTO/Inadimplente.js';
import CrudInadimplente from '../../../Service/CrudInadimplente.js';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';


function CreateFolderButton() {

  //const [idLogoGoogleDrive, setIdLogoGoogleDrive] = useState('');

  const inadimplente = new Inadimplente();

  const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
  const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
  const SCOPES = 'https://www.googleapis.com/auth/drive';

  //BUSCA PARA DO ESCRITORIO COMO ROOT
  function createFolderInRoot() {

    // Autenticação
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

    // SETA DADOS DA PASTA INADIMPLENTE
    const newFolderName = document.getElementsByName('responsavelFinanceiro')[0].value;
    inadimplente.setnomeResponsavelFinanceiro(newFolderName); // Nome da nova pasta

    //SETA DADOS PASTA ROOT
    const rootFolderId = document.getElementsByName('idRootInadimplente')[0].value;
    inadimplente.setidRootGoogleDrive(rootFolderId);
    // ID da pasta raiz

    //CRIA PASTA DO INADIMPLENTE
    const requestData = {
      mimeType: 'application/vnd.google-apps.folder',
      name: newFolderName,
      parents: [rootFolderId],
    };
    console.log(requestData);
    fetch('https://www.googleapis.com/drive/v3/files', {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((pastaGD) => {
        console.log("ID DA PASTA INADIMPLENTE");
        console.log(pastaGD);
        inadimplente.setpastaInadimplenteGoogleDrive(pastaGD.name);
        inadimplente.setidPastaInadimplenteGoogleDrive(pastaGD.id);


        //CHAMA AQUI A API
        const crudescr = new CrudInadimplente();
        crudescr.incluiPastasInadimplenteAdvocaciaAPI(document.getElementsByName('responsavelFinanceiro')[0].value, inadimplente);
        alert("Pasta Criada com sucesso noo Google Drive!");
        window.location.reload();

      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }



  return (
    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 1,
      background: "#f3f6f37d",
    }} >
      <Divider>
        <Chip label="Passo 2" />
      </Divider><br />
      <Box sx={{ justifyContent: "center", width: "100%" }} >
        <Card variant="outlined" sx={{
          width: '100%',
          justifyContent: "left",
          flexDirection: 'row',
          display: 'flex',
        }} >
          <Box sx={{
            width: '80%',
            justifyContent: "left",
            position: "relative",
          }} >
            <Stack spacing={0.4} sx={{ marginLeft: 5, marginRight: 2, marginTop: 2, marginBottom: 2, align: "Center" }}>
              <Box sx={{ justifyContent: "center", width: "100%", display: "flex" }} >
                <h2 >Crie uma pasta para o inadimplente:</h2>
                <Button variant="contained"  startIcon={<CreateNewFolderIcon />} onClick={() => createFolderInRoot()}
                  sx={{ width: "30%", marginLeft: 3 }} color="success">
                  Cria Pasta
                </Button>
              </Box>
            </Stack>
          </Box> </Card> </Box>
    </Box>
  );
}

export default CreateFolderButton;
