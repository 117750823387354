import React from "react";
import Requests from "../api/requests.js";
import SocioColegio from '../DTO/SocioColegio.js';

let socio = new SocioColegio();
let req = new Requests();

export default class CrudInadimplente {



  constructor(value) {
    this.socio = value;
  }

  

  socioColegioAPI(value) {
    const { v4: uuidv4 } = require('uuid');

    socio = value;
    req.requestPOSTparam(JSON.stringify({
      id: uuidv4(),      
      NomeSocio: socio.getnome(),
      Colegio: socio.getcolegio(),
      whatsapp: socio.getwhatsapp(),
      telefone: socio.gettelefone(),
      nacionalidade: socio.getnacionalidade(),
      rg: socio.getrg(),
      cpf: socio.getcpf(),
      endereco: socio.getendereco(),
      numero: socio.getnumero(),
      complemento: socio.getcomplemento(),
      bairro: socio.getbairro(),
      cep: socio.getcep(),
      cidade: socio.getcidade(),
      estado: socio.getestado(),
      email: socio.getemail(),
      genero: socio.getgenero(),
      estadoCivil: socio.getestadoCivil(),
      profissao: socio.getprofissao(),
    }), 'socioColegio');

  }


  buscacolegioAPI() {
    let req = new Requests();
    return req.requestGETparamColegiosTextfield("colegios");
  }


}