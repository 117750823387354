import React from "react";
import Debito from "../DTO/Debito";
import Requests from "../api/requests";


 let req = new Requests();
 
export default class CrudDebitoGestao {


  constructor( ) {
   }


  buscaGestaoDebito(){
    let req = new Requests();
    return req.requestGETDebitoGestao("debitos_gestao_notificacao");
  }

}