import React from "react";
import { Advogado } from '../DTO/Advogado.js';
import Requests from "../api/requests.js";


let adv = new Advogado();
let req = new Requests();

export default class CrudAdvogado {

 

    constructor(value) {
        this.adv = value;
   }


 cadastraAdvogadoAPI(value) {
  adv = value;

const { v4: uuidv4 } = require('uuid');

  
    req.requestPOSTparam(JSON.stringify({
      id: uuidv4(),
      OAB: adv.getOAB(),
      cargo: adv.getcargo(),
      nomeAdvogado: adv.getNomeAdvogado(),
      emailAdvogado: adv.getEmailAdvogado(),
      escritorio: adv.getEscritorio(),
      estadoCivil: adv.getEstadoCivil(),
      nacionalidade: adv.getNacionalidade(),
      whatsApp: adv.getWhatsApp(),
      numero: adv.getnumero(),
      cpfAdvogado: adv.getCpfAdvogado(),
      enderecoAdvogado: adv.getEnderecoAdvogado(),
      complementoAdvogado : adv.getcomplemento(),
      cepAdvogado: adv.getcep(),
      cidadeAdvogado: adv.getcidade(),
      bairroAdvogado: adv.getbairro(),
      estadoAdvogado: adv.getestado()
    }), 'advogado');
  
  
  }
}