import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { gapi } from 'gapi-script';
import TextField from '@mui/material/TextField';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';



const UploadImage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const uploadImageToDrive = () => {
    if (selectedImage) {
      const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
      const metadata = {
        name: selectedImage.name,
        mimeType: selectedImage.type,
        parents: [document.getElementsByName('idLogo')[0].value]
      };

      const formData = new FormData();
      formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      formData.append('file', selectedImage);

      fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded:', data);
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }
  };




  return (
    <Box sx={{ justifyContent: "center", width: "100%" }} >
    <br />      <br />
    
      <label
        htmlFor="file-upload"
        style={{
          color: '#4CAF50',
          padding: '10px 3x`0px',
          fontSize: '15px',
          border: 1,
          cursor: 'pointer',
          width: '50%',
          marginLeft: 10,
          marginRight: 30
        }}
      >
        <b><a>CLIQUE AQUI</a></b> e selecione uma imagem
      </label>
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{
          display: 'none'
        }}
      />
    <Button size="small" variant="outlined" startIcon={<UploadFileIcon />} color="success" onClick={uploadImageToDrive}>
        Upload
      </Button>
    </Box>
  );
};

export default UploadImage;

