import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { gapi } from 'gapi-script';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { API_URL } from '../../../constants';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Inadimplente from '../../../DTO/Inadimplente';


const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES = 'https://www.googleapis.com/auth/documents';
const numero = require('numero-por-extenso');

export default class MergeAndEditDocument {
  //  export default function MergeAndEditDocument(rootURL, colegio, inadimplente, aluno) {


  async mergeDataInDocument(rootURL, colegio, inadimplente, aluno, open) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const rootFolderId = rootURL;
    const fileId = '1Kup3jAv7oTR_IkCPiQheqdMixzmInpwVxpIKuCCnuKI';
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];

    const dataAtual = new Date();
    const dia = dataAtual.getDate();
    const mes = meses[dataAtual.getMonth()];
    const ano = dataAtual.getFullYear();

    const dataFormatada = `${dia} de ${mes} de ${ano}`;
    // const contrato = populaContrato(colegio, inadimplente, aluno); // Chama a função dentro do useEffect


    // Criação do template


    const requestData = {
      title: 'Cobrança',
      name: '05.COBRANCA',
      mimeType: 'application/pdf',
      parents: [rootFolderId],
    };

    console.log(API_URL + "contrato/" + colegio + "/" + inadimplente + "/" + aluno);
    try {
      const resposta = await fetch(API_URL + "contrato/" + colegio + "/" + inadimplente + "/" + aluno, {
        method: 'GET',
        mode: 'cors'
      })
        .then((response) => response.json())
        .then((json) => {

          const contrato = json;
          if (!contrato || !contrato.aluno || !contrato.inadimplente || !contrato.inadimplente.nomeResponsavelFinanceiro) {
            console.log("PROBLEMA NA CHAMADA: " +  API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno );  
            return;
          }
          console.log('CONTRATO');
          console.log(contrato);
          console.log('DADOS DO COLEGIO');
          console.log(contrato.colegio.nomeColegio)
          ///////////CHAMA API GOOGLEAPI
          fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
            method: 'POST',
            headers: new Headers({
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify(requestData),
          })
            .then((res) => res.json())
            .then((val) => {
              console.log(val);

              const newDocumentId = val.id; // ID do novo documento criado

              // Carregar a API do Google Docs
              gapi.client.load('https://docs.googleapis.com/$discovery/rest?version=v1')
                .then(() => {
             // Substituir variáveis no documento
                  gapi.client.docs.documents.batchUpdate({
                    documentId: newDocumentId,
                    requests: [
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Logo Escritorio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.escritorio.idLogoGoogleDrive|| "", // Novo texto a ser inserido
                        },
                      },
                    ],

                    requests: [
                      /* {
                         insertInlineImage: {
                           location: {
                             index: 1, // O índice onde você deseja inserir a imagem
                           },
                           uri: 'https://uploaddeimagens.com.br/images/004/621/915/original/Elizabeth_Logo.png?1695942550', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                         // uri: '../../../../public/Imagens/Elizabeth_Logo.png',
                          //contentUri: 'https://drive.google.com/file/d/1pEbQd6raRMFBM8XJiZFo4oRQjze0JZA4/view?usp=sharing', // Substitua 'URL_DA_IMAGEM_DO_GOOGLE_DRIVE' pela URL de compartilhamento da imagem no Google Drive
                          objectSize: {
                             width: {
                               magnitude: 150, // Largura da imagem em unidades de pixel
                               unit: 'PT', // Unidade de medida (pixels)
                             },
                             height: {
                               magnitude: 150, // Altura da imagem em unidades de pixel
                               unit: 'PT', // Unidade de medida (pixels)
                             },
                           },
                         },
                       },*/
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Logo Escritorio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: '', // Deixe vazio para remover o texto
                        },
                      },
                      // Outras substituições de texto
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<FORO>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.foro|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.nomeColegio|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio CNPJ>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.cnpjColegio|| "", // Novo texto a ser inserido
                        },
                      }, 
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Sócio do Colégio>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.socioColegio.NomeSocio|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Endereço do Escritório>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.enderecoColegio || ""// Novo texto a ser inserido
                            + " " + contrato.colegio.cidadeColegio|| ""
                            + " " + contrato.colegio.cepColegio|| ""

                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Data da Notificação Extrajudicial>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: dataFormatada || "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Colegio Endereço>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.colegio.enderecoColegio|| "" + ' nº'
                            + contrato.colegio.numerocolegio|| "" + ', '
                            //+ (contrato.colegio.complementocolegio==undefined ? '': contrato.colegio.complementocolegio + ' - ' 
                            + contrato.colegio.bairroColegio|| "" + ', no CEP: '
                            + contrato.colegio.cepColegio|| "" + ', '
                            + contrato.colegio.cidadeColegio|| "" + ' - '
                            + contrato.colegio.estadoColegio|| ""
                        }
                      },
               
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Advogados>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: "Elizabeth Ribeiro Curi e Antonio Roberto Fudaba, brasileiros, casados, inscritos respectivamente na OAB/SP sob os números 276.192 e 88.599"

                          /*                       contrato.advogado.enderecoAdvogado + ', '
                                               + contrato.advogado.nacionalidade + ', '
                                               + contrato.advogado.estadoCivil + ', inscritos respectivamente na OAB/SP sob os números '
                                               + contrato.advogado.OAB*/
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Nome Completo>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.nomeResponsavelFinanceiro|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<nacionalidade>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.nacionalidade|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<anodeensino>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.aluno.dataEnsino || "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<estado civil>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.estadoCivil|| "" // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Profissão>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.profissao|| "",  // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<RG>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.rg|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<CPF>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.cpf|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Endereço>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.endereco|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<número>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.numero|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<complemento>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.complemento|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Bairro>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.bairro|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<CEP>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.cep|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Cidade>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.cidade|| "",
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Estado>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.estado|| "",
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: ' <<telefone>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.whatsapp|| "",
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<E-mail>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.email|| "",
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<WhatsApp>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.whatsapp|| "",
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<data>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: dataFormatada|| "", // Novo texto a ser inserido
                        },
                      },

                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Requerido>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.inadimplente.genero == "feminino" ? "requerida" : "requerido", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Requerente>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: "Requerente", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Aluno>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.aluno.nomeAluno|| "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<valorprimitivo>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.debito.total_valor_primitivo || "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<valorprimitivoextenso>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: (contrato.debito.total_valor_primitivo!= null) ?  numero.porExtenso(contrato.debito.total_valor_primitivo, numero.estilo.monetario) : "", // Novo texto a ser inserido

                        },
                      },

                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<valoratualizadododebito>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.debito.total_debito_atualizado || "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<valoratualizadododebitoporextenso>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: (contrato.debito.total_debito_atualizado!= null)? numero.porExtenso(contrato.debito.total_debito_atualizado, numero.estilo.monetario): "", // Novo texto a ser inserido
                        },
                      },
                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Códigodealuno>>', // Texto a ser substituído
                            matchCase: false,
                          },
                          replaceText: contrato.aluno.codigoAluno|| "", // Novo texto a ser inserido
                        },
                      },
                      /*   {
                            insertInlineImage: {
                              location: {
                                index: 1995, // O índice onde você deseja inserir a imagem
                              },
                            //  uri: 'https://uploaddeimagens.com.br/images/004/465/674/original/Screenshot_2023-05-14_at_02.43.00.png', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                              uri: 'https://uploaddeimagens.com.br/images/004/465/674/original/Screenshot_2023-05-14_at_02.43.00.png', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                            
                            objectSize: {
                                width: {
                                  magnitude: 400, // Largura da imagem em unidades de pixel
                                  unit: 'PT', // Unidade de medida (pixels)
                                },
                                height: {
                                  magnitude: 30, // Altura da imagem em unidades de pixel
                                  unit: 'PT', // Unidade de medida (pixels)
                                },
                              },
                            },
                          },*/

                      {
                        replaceAllText: {
                          containsText: {
                            text: '<<Footer do Escritório>>', // Texto a ser substituído
                            matchCase: true,
                          },
                          replaceText: '', // Deixe vazio para remover o texto
                        },
                      },
                    ],
                  })
                   .then(function (response) {
                      // Manipule a resposta aqui
                      console.log('pagina criada');
                      console.log(response.result.documentId);
                      if (open != "FECHADO") {
                        const newWindow = window.open("https://docs.google.com/document/d/" + response.result.documentId, '_blank');
                        //  pdfMake.createPdf("https://docs.google.com/document/d/" + response.result.documentId).open({}, window.open('', '_blank'));

                        console.log("Variável substituída com sucesso", response);
                      }
                    })
                  })   .then(async function (response) {

             /*       
                      const pdfContent = await gapi.client.docs.documents.get({
                        documentId: '1iC6UXfsulz8tKGVY__MtxVVxOncGqHXFXDd9YF37uQo'
                    });
            
                    console.log(pdfContent);
                    // Criação do PDF
                    const pdfDoc = pdfMake.createPdf(pdfContent.result);
                    console.log(pdfDoc);

                    
                  /*  const createpdf =  pdfDoc.getBlob(async (blob) => {
                      // Salva o PDF no Google Drive
                      const createpdf2 =    gapi.client.docs.documents.create({
                        resource: {
                            name: 'nome_do_arquivo.pdf', // Nome do arquivo PDF
                            mimeType: 'application/pdf',
                            parents: ['1Bhlh6gPJF8z93fJgZDeKeyoNAvQ8WtQo'], // Pasta onde o PDF será salvo
                        },
                        media: {
                            mimeType: 'application/pdf',
                            body: blob,
                        }
                        })
                      })
                      console.log(createpdf);
               
                    })*/

                 /*   setTimeout( pdfDoc.getBlob((pdfBlob) => {
                                // Agora você tem o blob do PDF gerado
                                // Aqui você pode salvar, exibir ou fazer o que precisar com o blob
                                // Por exemplo, para exibir o PDF em uma nova aba do navegador:
                                const pdfUrl = URL.createObjectURL(pdfBlob);
                                window.open(pdfUrl);
                            }), 50000);
                            
*/

                });
            });
          ///////FINALIZA BUSCA API 
        });
      return resposta;
    } catch (error) {
      console.error(error);
    }
  }

  /*return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={mergeDataInDocument}>
      <LocalPrintshopIcon />
      </Button>
    </Stack>
  );*/
  //}

}
