import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined';
import Checkbox from '@mui/material/Checkbox';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { green } from '@mui/material/colors';

import DataGridConsultaDebito from './datagrids/DataGridConsultaDebito.js';
import { StyledEngineProvider } from '@mui/material/styles';
import DataGridConsultaInadimplente from './datagrids/DataGridConsultaInadimplente';
import { styled } from '@mui/material/styles';
import { DataGrid, GridEditInputCell } from '@mui/x-data-grid';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


import {FormControl } from '@mui/base/FormControl';


import { TextField, Stack } from '@mui/material';

import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import Debito from '../DTO/Debito.js';
import CrudDebito from '../Service/CrudDebito.js';
import DataGridDebito from './datagrids/DataGridCadastroDebito.js';
import Autocomplete from '@mui/material/Autocomplete';
import DataGridCalculoDebito from './datagrids/DataGridCalculoDebito.js';



import {
    GridRowModes,
    GridToolbar,
    GridToolbarContainer,
    GridActionsCellItem,
    GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
    randomCreatedDate,
    randomTraderName,
    randomId,
    randomArrayItem,
} from '@mui/x-data-grid-generator';
import { parseConfigFileTextToJson } from 'typescript';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { data } from './impressaoDocumentos/data';
import { Impressao } from './impressaoDocumentos/impressao';
import { ImpressaoNotificacao } from './impressaoDocumentos/notificacao';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


const styleGrid = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

//teste 
const classeImpressao = new Impressao(data);
const documento = classeImpressao.PreparaDocumento();



const debito = new Debito();
const crudDebito = new CrudDebito();
 
const ListaInadimplente = [
    { label: 'Fulano', id: 1 },
    { label: 'Beltrano', id: 2 },

]


const ListaAluno = [
    { label: 'Fulano', id: 1 },
    { label: 'Beltrano', id: 2 },

]

function cadastraDebito() {
    crudDebito.cadastraDebitoAPI(debito);

}


function limpaform() {
    Array.from(document.querySelectorAll("input")).forEach(
        input => (input.value = "")
    );
}




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const StyledBox = styled(Box)(({ theme }) => ({
    height: 400,
    width: '100%',
    '& .MuiDataGrid-cell--editable': {
        backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
        '& .MuiInputBase-root': {
            height: '100%',
        },
    },
    '& .Mui-error': {
        backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
        color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
    },
}));

let promiseTimeout;
function validateName(username) {
    const existingUsers = rows.map((row) => row.name.toLowerCase());

    return new Promise((resolve) => {
        promiseTimeout = setTimeout(() => {
            const exists = existingUsers.includes(username.toLowerCase());
            resolve(exists ? `${username} is already taken.` : null);
        }, Math.random() * 500 + 100); // simulate network latency
    });
}

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.error.main,
        color: theme.palette.error.contrastText,
    },
}));

function NameEditInputCell(props) {
    const { error } = props;

    return (
        <StyledTooltip open={!!error} title={error}>
            <GridEditInputCell {...props} />
        </StyledTooltip>
    );
}

function renderEditName(params) {
    return <NameEditInputCell {...params} />;
}

const handleClose = () => { };

const handleUploadFile = (e: any) => console.log(e.target.files[0]);

function cadastraConferirdocumentos() { }

function createData(inadimplente, nomeFilho, valorAtualizado) {


    return {
        inadimplente,
        nomeFilho,
        valorAtualizado,

        parcelamento: [
            {
                item: '1',
                descricao: 'Procuracao',
                valor: 900,
                valortotal: 900,
                conferido: true
            },
            {
                item: '2',
                descricao: 'Notificação ',
                valor: "10 x 100",
                valortotal: 1000,
                conferido: true

            },
            {
                item: '3',
                descricao: 'Etiqueta',
                valor: "20 x 52",
                valortotal: 1300,
                conferido: true

            },
            {
                item: '4',
                descricao: 'Envelope',
                valor: "Descreva a pendencia",
                valortotal: 1300,
                conferido: true
            },
            {
                item: '5',
                descricao: 'Monitoria',
                valor: "Descreva o motivo",
                valortotal: 1300,
                conferido: true
            },
        ],
    };
}

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const [color, setColor] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);


    const [cardFile, setCardFile] = useState();
    const handleUploadFile = (e: any) => console.log(e.target.files[0]);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    React.useEffect(() => {
        return () => {
            clearTimeout(promiseTimeout);
        };
    }, []);

    const columns = [
        /*  {
            field: 'name',
            headerName: 'MUI Contributor',
            width: 180,
            editable: true,
            preProcessEditCellProps,
            renderEditCell: renderEditName,
          }, */
        {
            field: 'inadimplente',
            headerName: 'Responsavel Financeiro',
            width: 250,
            editable: true,
            preProcessEditCellProps,
            renderEditCell: renderEditName,
        },
        {
            field: 'endereco',
            headerName: 'Endereço',
            width: 250,
            editable: true,
            preProcessEditCellProps,
            renderEditCell: renderEditName,
        },
 
        {
            field: 'procuracao',
            type: 'actions',
            headerName: 'Procuração',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<LocalPrintshopIcon />}
                        label="Imprimir Procuração"
                        className="textPrimary"
                        onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
                        color="inherit"
                    />
                ];
            },
        },
        {
            field: 'notificacao',
            type: 'actions',
            headerName: 'Notificação',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<LocalPrintshopIcon />}
                        label="Imprimir Notificação"
                        className="textPrimary"
                        onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
                        color="inherit"
                    />
                ];
            },
        },
        {
            field: 'envelope',
            type: 'actions',
            headerName: 'Envelope',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<LocalPrintshopIcon />}
                        label="Imprimir Envelope"
                        onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'etiqueta',
            type: 'actions',
            headerName: 'Etiqueta',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<LocalPrintshopIcon />}
                        label="Imprimir Etiqueta"
                        onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
                        color="inherit"
                    />,
                ];
            },
        },
        {
            field: 'monitoria',
            type: 'actions',
            headerName: 'Monitoria',
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                return [
                    <GridActionsCellItem
                        icon={<LocalPrintshopIcon />}
                        label="Imprimir Etiqueta"
                        onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    return (
        <React.Fragment >
            <TableRow sx={{ '': { borderBottom: 'unset' }, background: '#f6ffe2' }}>
                <TableCell >
                    <IconButton
                        aria-label="root"
                        onClick={() => setOpen2(!open)}>
                        {open2 ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row"  > {row.inadimplente} </TableCell>
                <TableCell align="right">{row.nomeFilho}</TableCell>
                <TableCell align="right">{row.valorAtualizado}</TableCell>

                <TableCell align="right" >
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen2(!open2)} >
                        {open2 ? <PublishedWithChangesOutlinedIcon /> : <PublishedWithChangesOutlinedIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} >
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ background: '#eaebe9' }}>
                                Dados do Inadimplente
                            </Typography>

                            <Card variant="outlined">
                                <Box component="bxgridCadastroAdv" sx={{
                                    margin: "0px",
                                    p: 0,
                                    height: '90%',
                                    width: '100%',
                                    justifyContent: 'center',
                                    background: '#fff'
                                }} >
                                    <Stack spacing={0} sx={{ margin: 2, align: "Center"}}>
                                        <h3>Inadimplente Cadastrado</h3>

                                        <React.StrictMode>
                                            <StyledEngineProvider injectFirst>
                                                <DataGridConsultaInadimplente />
                                            </StyledEngineProvider>
                                        </React.StrictMode>
                                    </Stack>
                                </Box>
                            </Card>

                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ background: '#eaebe9' }}>
                                Débitos em Aberto
                            </Typography>
                            <React.StrictMode>
                                <StyledEngineProvider injectFirst>
                                    <DataGridConsultaDebito />
                                </StyledEngineProvider>
                            </React.StrictMode>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 1, paddingTop: 0 }} colSpan={5}>
                    <Collapse in={open2} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div" sx={{ background: '#eaebe9' }}>
                                Documentos Gerados
                            </Typography>

                            <StyledBox>


                                <DataGrid
                                    rows={rows2}
                                    columns={columns}
                                    pageSize={8}

                                    isCellEditable={(params) => params.row.id === 5}
                                // checkboxSelection

                                />
                            </StyledBox>
                        </Box>
                    </Collapse>
                </TableCell>


            </TableRow>




        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        // carbs: PropTypes.number.isRequired,
        // fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        ordem: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

const rows = [
    createData('Paulo Henrique', "Pedrinho", 1000, "89.0000", "89.0000", 1100, 1200, 120, 1500),
    createData('Paula Freitas', "Mariazinha", 1000, "89.0000", "89.0000", 1100, 1200, 120, 1500),

    /* createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
     createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
     createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
     createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),*/
];

const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
    return { ...params.props, error: errorMessage };
};

export default function ConsultaInadimplente() {

    const [colegios, setColegios] = useState([]); // Initialize with empty array
  const [inadimpletes, setInadimplentes] = useState([]); // Initialize with empty array
  const [alunos, setAlunos] = useState([]); // Initialize with empty array


  const [selectedColegioName, setSelectedColegioName] = useState(null);
  const [selectedAlunosName, setSelectedAlunosName] = useState(null);

   
  const fetchInadimplentes = async (colegioName) => {
    // Fetch data and set it to the inadimplentes state
    const inadimplenteData = await crudDebito.buscaInadimplenteAPI(colegioName);
    if (inadimplenteData) {
      setInadimplentes(inadimplenteData);
    }
  };


  useEffect(() => {
    if (selectedColegioName) {
      fetchInadimplentes(selectedColegioName);
    }
  }, [selectedColegioName]);


  const fetchAlunos = async (inadimplenteNome) => {
    // Fetch data and set it to the inadimplentes state
    const alunosData = await crudDebito.requestGETparamAlunosTextfield(inadimplenteNome);
    if (alunosData) {
      setAlunos(alunosData);
    }
  };


  useEffect(() => {
    if (selectedAlunosName) {
      fetchAlunos(selectedAlunosName);
    }
  }, [selectedAlunosName]);

  useEffect(() => {
    async function fetchColegios() {
      const colegiosData = await crudDebito.buscacolegioAPI();
      if (colegiosData) {
        setColegios(colegiosData);
      }
    }
    fetchColegios();

  }, []); // Run once on component mount
  /////////////////////////////////////////////////////////////////////////////////////////////////////

    

    return (
        <Box sx={{
            width: '99%',
            justifyContent: "Center",
            p: 0,
            background: "#f3f6f37d",

        }} >


                <Card variant="outlined" sx={{
                    width: '100%',
                    justifyContent: "left",
                    flexDirection: 'row',
                    display: 'flex',
                }} >

                    <Box sx={{
                        width: '50%',
                        justifyContent: "left",
                        position: "relative",
                        flexGrow: 2

                    }} >
                        <FormControl defaultValue="">

                            <Stack spacing={1} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5, align: "Center" }}>
                                <h2>Cadastro do Débito do Inadimplente</h2>

                                <Autocomplete
                disablePortal
                id="combo-box-demo"
                color="secondary"
                name="colegio_tf"
                options={colegios} // Use colegios array as options
                // value={selectedColegioName} // Use selected colegio's name
                onChange={(e, value) => {
                  debito.setcolegio(value.label); // Set colegio ID in your state
                  setSelectedColegioName(value ? value.label : null); // Set selected colegio's name or null if deselected
                }}
                getOptionLabel={(option) => option.label} // Display colegio names
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField {...params} label="Colegio" name="colegio" variant='standard' />}
                fullWidth
                required
              />
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                color="secondary"
                name="inadimplente_tf"
                options={inadimpletes}
                onChange={(e, value) => {
                  debito.setnomeResponsavelFinanceiro(value.label); // Set colegio ID in your state
                  setSelectedAlunosName(value ? value.label : null); // Set selected colegio's name or null if deselected
                }}
               /* onBlur={e =>
                  inadimpletes.forEach(item => {
                    if (item.label == e.target.value) {
                      console.log("Item ID:", item.id);
                      console.log("Item Label:", item.label);
                      console.log("Item idRoot:", item.idPastaInadimplenteGoogleDrive);
                      setIdLogoGoogleDrive(item.idPastaInadimplenteGoogleDrive)
                    }
                  })
                }*/
                getOptionLabel={(option) => option.label} // Adjust this based on your data structure
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField {...params} name="inadimplente" label="Inadimplente" variant='standard' />}
                fullWidth
                required
              />
              
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                color="secondary"
                name="alunos_tf"
                options={alunos}
                getOptionLabel={(option) => option.label} // Adjust this based on your data structure
                onChange={(e, value) => debito.setaluno(value.label)}
                sx={{ width: "100%", fontGrid: "small" }}
                renderInput={(params) => <TextField {...params} name="alunos" label="Aluno" variant='standard' />}
                fullWidth
                required
              />



                            </Stack>



                        </FormControl>
                    </Box>
                </Card>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                                <TableCell />
                                <TableCell>Inadimplente </TableCell>
                                <TableCell align="right">Nome do Filho</TableCell>
                                <TableCell align="right">Valor Atualizadoo&nbsp;(R$)</TableCell>
                                <TableCell align="right">Atualizar</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <Row key={row.name} row={row} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
     
        </Box>
    );
}

const rows2 = [
    {
        id: 1,
        // name: 'Damien',
        inadimplente: "Fulano da Silva",
        endereco: "Rua teste, " + "12321",
        cep: "08440-100",
        cidade_estado: "São Paulo - SP",
        data_envio: ""


    },

    {
        id: 5,
        // name: 'Damien',
        inadimplente: "Fulano da Silva",
        endereco: "Rua teste, " + "12321",
        cep: "08440-100",
        cidade_estado: "São Paulo - SP",
        data_envio: ""
    },
];