import React from "react";


let id;
let ano;
let mes;
let indice;

export default class Indice {

    constructor(){}

     //id;
    getid() {
        return this.id;
    }
    setid(value) {
        this.id = value;
    }
     //ano;
    getano() {
        return this.ano;
    }
    setano(value) {
        this.ano = value;
    }
     //indice;
    getindice() {
        return this.indice;
    }
    setindice(value) {
        this.indice = value;
    }
     //mes;
    getmes() {
        return this.mes;
    }
    setmes(value) {
        this.mes = value;
    }


}
