import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';


function handleSaveClick(){
  console.log("Ola");
}


export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}


const columns = [

  { 
    field: 'nomeResponsavelFinanceiro',
   headerName: 'nomeResponsavelFinanceiro',
    width: 80,
    editable: true
  },
  {
    field: 'valorAcordo',
    headerName: 'valorAcordo',
    width: 150,
    editable: true,
  },
  {
    field: 'valorPrimitivo',
    headerName: 'valorPrimitivo',
    width: 150,
    editable: true,
  },
 

];


const DataGridDebito = () => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "debitos", {
      method: 'GET',
      allowedHeaders: 'Content-Type'
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])
  console.log("tableData");
  console.log(tableData);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridDebito

 




 
