import React from "react";
import { Colegio } from '../DTO/Colegio.js';
import Requests from "../api/requests.js";


let colegio = new Colegio();
let req = new Requests();

export default class CrudAdvogado {

 

    constructor(value) {
        this.colegio = value;
   }



    cadastraColegioAPI(event){
    colegio = event;
    console.log(
      
      JSON.stringify({
        id:(parseInt(Math.random() * 100)),
        nomeColegio: colegio.getNomeColegio(),
        logoColegio: colegio.getlogoColegio(), 
        enderecoColegio: colegio.getenderecoColegio(), 
        cnpjColegio: colegio.getcnpjColegio(), 
        escritorio : colegio.getEscritorio(),
        emailColegio: colegio.getEmailColegio(),
        numerocolegio: colegio.getnumerocolegio(),
        cidadeColegio: colegio.getcidadeColegio(),
        estadoColegio: colegio.getestadoColegio(),
        bairroColegio: colegio.getbairroColegio(),
        cepColegegio: colegio.getcepcolegio(),
        complementoColegio: colegio.getcomplementocolegio(),
        banco: colegio.getbanco(),
        agencia:colegio.getagencia(),
        conta:colegio.getconta(),
        PIX:colegio.getPIX(),
        tipoConta:colegio.gettipoConta()

        
      })


    );
  
    req.requestPOSTparam(JSON.stringify({
      id:(parseInt(Math.random() * 100)),
      nomeColegio: colegio.getNomeColegio(),
      logoColegio: colegio.getlogoColegio(), 
      enderecoColegio: colegio.getenderecoColegio(), 
      cnpjColegio: colegio.getcnpjColegio(), 
      escritorio : colegio.getEscritorio(),
      emailColegio: colegio.getEmailColegio(),
      numerocolegio: colegio.getnumerocolegio(),
      cidadeColegio: colegio.getcidadeColegio(),
      estadoColegio: colegio.getestadoColegio(),
      bairroColegio: colegio.getbairroColegio(),
      cepColegio: colegio.getcepcolegio(),
      banco: colegio.getbanco(),
      agencia:colegio.getagencia(),
      conta:colegio.getconta(),
      PIX:colegio.getPIX(),
      tipoConta:colegio.gettipoConta()
    }), 'colegio');
    }

    incluiPastasColegioAdvocaciaAPI(nomeColegio, pastasColegio){
      console.log ('PASSO 1 - NOME COLEGIO + PASTAS + COLEGIO '); 
      console.log (nomeColegio); 
      console.log (pastasColegio); 
      console.log (colegio); 
  
     req.requestPUTparam(  'colegio', JSON.stringify({
      nomeColegio: colegio.getNomeColegio(),
      logoColegio: colegio.getlogoColegio(), 
      enderecoColegio: colegio.getenderecoColegio(), 
      cnpjColegio: colegio.getcnpjColegio(), 
      escritorio : colegio.getEscritorio(),
      emailColegio: colegio.getEmailColegio(),
      numerocolegio: colegio.getnumerocolegio(),
      cidadeColegio: colegio.getcidadeColegio(),
      estadoColegio: colegio.getestadoColegio(),
      bairroColegio: colegio.getbairroColegio(),
      complementoColegio: colegio.getcomplementocolegio(),
      cepColegio: colegio.getcepcolegio(),
      idLogoGoogleDrive: pastasColegio.getidLogoGoogleDrive(),
      pastaColegiosGoogleDrive: pastasColegio.getpastaColegiosGoogleDrive(),
      idPastaColegiosGoogleDrive: pastasColegio.getidPastaColegiosGoogleDrive(),
      idRootGoogleDrive: pastasColegio.getidRootGoogleDrive(),
      pastaInadimplenteGoogleDrive: pastasColegio.getpastaInadimplenteGoogleDrive(),
      idPastaInadimplenteGoogleDrive: pastasColegio.getidPastaInadimplenteGoogleDrive(), 
      conta: colegio.getconta(),
      agencia:colegio.getagencia(),
      banco: colegio.getbanco(),
      PIX: colegio.getPIX(),
      tipoConta: colegio.gettipoConta()
      }), nomeColegio);  
  
    }
     

  }
  
