
let id;
let ordem;
let descricao;
let dataVencimento;
let valor; // primitivo
let indiceVencimento;
let indiceAtual;
let indiceMes;
let subtotal;
let juros;
let total;
let valorDebito;
 

export default class ItemDebito {



    constructor() {
    
    }
  
    // Métodos Getter e Setter para id
    getid() {
      return this.id;
    }
    setid(value) {
      this.id = value;
    }
  
    // Métodos Getter e Setter para ordem
    getordem() {
      return this.ordem;
    }
    setordem(value) {
      this.ordem = value;
    }
  
    // Métodos Getter e Setter para descricao
    getdescricao() {
      return this.descricao;
    }
    setdescricao(value) {
      this.descricao = value;
    }
  
    // Métodos Getter e Setter para dataVencimento
    getdataVencimento() {
      return this.dataVencimento;
    }
    setdataVencimento(value) {
      this.dataVencimento = value;
    }
  
    // Métodos Getter e Setter para valor
    getvalor() {
      return this.valor;
    }
    setvalor(value) {
      this.valor = value;
    }
  
    getindiceAtual(){
        return this.indiceAtual;


    }
    setindiceAtual(value){
        this.indiceAtual = value;

    }

    getindiceMesl(){
        return this.indiceMes;


    }
    setindiceMes(value){
        this.indiceMes = value;

    }

    getsubtotal(){
        return this.subtotal;


    }
    setsubtotal(value){
        this.subtotal = value;

    }

    getjuros(){
        return this.juros;


    }
    setjuros(value){
        this.juros = value;

    }
    gettotal(){
        return this.total;


    }
    settotal(value){
        this.total = value;

    }
    getvalorDebito(){
        return this.valorDebito;


    }
    setvalorDebito(value){
        this.valorDebito = value;

    }
    
  }
  

  