import React from "react";



let id;
let colegio;
let nome;
let whatsapp;
let telefone;
let nacionalidade;
let rg;
let cpf;
let endereco;
let numero;
let complemento;
let bairro;
let cep;
let cidade;
let estado;
let email;
let genero;
let estadoCivil;
let profissao;


export default class SocioColegio {



  constructor() {

  }

    // id;
    getid() {
        return this.id;
    }
    setid(value) {
        this.id = value;
    }

    colegio;
    getcolegio() {
        return this.colegio;
    }
    setcolegio(value) {
        this.colegio = value;
    }
     //nomeResponsavelFinanceiro;
    getnome() {
        return this.nome;
    }
    setnome(value) {
        this.nome = value;
    }
     //whatsapp;
    getwhatsapp() {
        return this.whatsapp;
    }
    setwhatsapp(value) {
        this.whatsapp = value;
    }
     //telefone;
    gettelefone() {
        return this.telefone;
    }
    settelefone(value) {
        this.telefone = value;
    }
     //nacionalidade;
    getnacionalidade() {
        return this.nacionalidade;
    }
    setnacionalidade(value) {
        this.nacionalidade = value;
    }
   //  rg;
    getrg() {
        return this.rg;
    }
    setrg(value) {
        this.rg = value;
    }
     //cpf;
    getcpf() {
        return this.cpf;
    }
    setcpf(value) {
        this.cpf = value;
    }
     //endereco;
    getendereco() {
        return this.endereco;
    }
    setendereco(value) {
        this.endereco = value;
    }
     //numero;
    getnumero() {
        return this.numero;
    }
    setnumero(value) {
        this.numero = value;
    }
     //complemento;
    getcomplemento() {
        return this.complemento;
    }
    setcomplemento(value) {
        this.complemento = value;
    }
     //bairro;
    getbairro() {
        return this.bairro;
    }
    setbairro(value) {
        this.bairro = value;
    }
     //cep;
    getcep() {
        return this.cep;
    }
    setcep(value) {
        this.cep = value;
    }
     //cidade;
    getcidade() {
        return this.cidade;
    }
    setcidade(value) {
        this.cidade = value;
    }
    // estado;
    getestado() {
        return this.estado;
    }
    setestado(value) {
        this.estado = value;
    }
     //email;
    getemail() {
        return this.email;
    }
    setemail(value) {
        this.email = value;
    }
     //genero;
    getgenero() {
        return this.genero;
    }
    setgenero(value) {
        this.genero = value;
    }
    // estadoCivil;
    getestadoCivil() {
        return this.estadoCivil;
    }
    setestadoCivil(value) {
        this.estadoCivil = value;
    }
     ///profissao;
    getprofissao() {
        return this.profissao;
    }
    setprofissao(value) {
        this.profissao = value;
    }
     


}

