

let id;
let OAB;
let nomeAdvogado; 
let emailAdvogado;
let estadoCivil;
let nacionalidade;
let whatsApp;
let enderecoAdvogado;
let cep;
let cidade;
let complemento;
let estado;
let bairro;
let numero;
let cpfAdvogado;
let imagemAvatarAdv;
let escritorio;
let cargo;
let genero;


export class Advogado {



  constructor() {

  }
  //OAB
  getId() {
    return this.id;
  }
   setId(value) {
    this.id = value;
  }

//OAB
   getOAB() {
     return this.OAB;
   }
    setOAB(value) {
     this.OAB = value;
   }
//NomeAdvogado
    getNomeAdvogado() {
     return this.nomeAdvogado;
   }
    setNomeAdvogado(value) {
     this.nomeAdvogado = value;
   }
//emailAdvogado
    getEmailAdvogado() {
     return this.emailAdvogado;
   }
   setEmailAdvogado(value) {
     this.emailAdvogado = value;
   }
//estadoCivil,
   getEstadoCivil(){
    return this.estadoCivil;
   }

   setEstadoCivil(value){
     this.estadoCivil = value;
   }
//nacionalidade
   getNacionalidade(){
    return this.nacionalidade;
   }

   setNacionalidade(value){
    this.nacionalidade = value;
  }
//endereco
    getEnderecoAdvogado() {
     return this.enderecoAdvogado;
   }

    setEndereçoAdvogado(value) {
     this.enderecoAdvogado = value;
   }
//whatsApp
    getWhatsApp() {
      return this.whatsApp;
    }

    setWhatsApp(value) {
      this.whatsApp = value;
    }
    //complemento;
  getcomplemento() {
    return this.complemento;
  }
  setcomplemento(value) {
    this.complemento = value;
  }
//cpfAdvogado;

    getCpfAdvogado() {
     return this.cpfAdvogado;
   }
    setCpfAdvogado(value) {
     this.cpfAdvogado = value;
   }
 
//imagemAvatar
   getImagemAvatarAdv() {
    return this.imagemAvatarAdv;
  }
   setImagemAvatarAdv(value) {
    this.imagemAvatarAdv = value;
  }


  getEscritorio() {
    return this.escritorio;
  }
  setEscritorio(value) {
    this.escritorio = value;
  }
  
 // cargo;
  getcargo() {
    return this.cargo;
  }
  setcargo(value) {
    this.cargo = value;
  }


   //cep
   getcep() {
    return this.cep;
  }
  setcep(value) {
    this.cep = value;
  }
   //cidade
   getcidade() {
    return this.cidade;
  }
  setcidade(value) {
    this.cidade = value;
  }  
  //estado
  getestado() {
   return this.estado;
 }
 setestado(value) {
   this.estado = value;
 }
 //bairro
 getbairro() {
  return this.bairro;
}
setbairro(value) {
  this.bairro = value;
}
 //numero
 getnumero() {
  return this.numero;
}
setnumero(value) {
  this.numero = value;
}
 //genero
 getgenero() {
  return this.genero;
}
setgenero(value) {
  this.genero = value;
}

 }