import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

// Botão de deletar
export function DeleteButton({ id, handleDelete }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(id)}
    >
      
    </Button>
  );
}

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;
}

const DataGridInadimplente = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch(API_URL + "inadimplentes", {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))
      .catch((error) => console.error('Erro ao carregar os dados:', error));
  }, []);

  // Função de deletar dentro do componente
  const handleDelete = (id) => {
    fetch(`${API_URL}inadimplentes/${id}`, {
      method: 'DELETE',
    })
    .then(() => {
      // Remover o registro localmente após a exclusão
      setTableData((prevData) => prevData.filter((row) => row.id !== id));
    })
    .catch((error) => console.error('Erro ao deletar:', error));
  };

  const columns = [
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <DeleteButton id={params.row.id} handleDelete={handleDelete} />
      ),
    },
    { 
      field: 'nomeResponsavelFinanceiro',
      headerName: 'Responsável Financeiro',
      width: 300,
      editable: true,
    },
    { 
      field: 'idRootGoogleDrive',
      headerName: 'Google Drive',
      width: 350,
      editable: true,
    },
    {
      field: 'whatsapp',
      headerName: 'WhatsApp',
      width: 80,
      editable: true,
    },
    {
      field: 'telefone',
      headerName: 'Telefone',
      width: 80,
      editable: true,
    },
    {
      field: 'nacionalidade',
      headerName: 'Nacionalidade',
      width: 100,
      editable: true,
    },
    {
      field: 'rg',
      headerName: 'RG',
      width: 80,
      editable: true,
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 80,
      editable: true,
    },
    {
      field: 'endereco',
      headerName: 'Endereço',
      width: 150,
      editable: true,
    },
    {
      field: 'numero',
      headerName: 'Número',
      width: 20,
      editable: true,
    },
    {
      field: 'complemento',
      headerName: 'Complemento',
      width: 20,
      editable: true,
    },
    {
      field: 'bairro',
      headerName: 'Bairro',
      width: 80,
      editable: true,
    },
    {
      field: 'cep',
      headerName: 'CEP',
      width: 80,
      editable: true,
    },
    {
      field: 'cidade',
      headerName: 'Cidade',
      width: 80,
      editable: true,
    },
    {
      field: 'estado',
      headerName: 'Estado',
      width: 80,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: true,
    },
    {
      field: 'genero',
      headerName: 'Gênero',
      width: 80,
      editable: true,
    },
    {
      field: 'estadoCivil',
      headerName: 'Estado Civil',
      width: 80,
      editable: true,
    },
    {
      field: 'profissao',
      headerName: 'Profissão',
      width: 80,
      editable: true,
    },
    {
      field: 'endereco2',
      headerName: 'Endereço 2',
      width: 150,
      editable: true,
    },
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
        }}
      />
    </div>
  );
};

export default DataGridInadimplente;
