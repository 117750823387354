import React from "react";
import Debito from "../DTO/Debito";
import Requests from "../api/requests";
import { API_URL } from '../../src/constants';


let debito = new Debito();
let req = new Requests();

export default class CrudDebito {



  constructor(value) {
    this.debito = value;
  }



  async cadastraDebitoAPI(debito) {
    let req = new Requests();
    console.log("CHAMEI API de Cadastro de Débito"); 
    const { v4: uuidv4 } = require('uuid');
      
    console.log("INSERE OBJETO DE DEBITO");
    console.log( JSON.stringify({
      id: uuidv4(),      
      nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
      colegio: debito.getcolegio(),
      aluno: debito.getaluno(),
      itemDebito : debito.getitemDebito(),
      rootURL: debito.getRootUrlInadimplente(),
      total_debito_atualizado : debito.getTotal_debito_atualizado(),
      total_valor_primitivo: debito.getTotal_valor_primitivo(),
      total_subtotal: debito.getTotal_subtotal(),
      total_juros: debito.getTotal_juros()
 
 
    }));

  req.requestPOSTparam(JSON.stringify({
      id: uuidv4(),      
      nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
      colegio: debito.getcolegio(),
      aluno: debito.getaluno(),
      itemDebito : debito.getitemDebito(),
      rootURL: debito.getRootUrlInadimplente(),
      total_debito_atualizado : debito.getTotal_debito_atualizado(),
      total_valor_primitivo: debito.getTotal_valor_primitivo(),
      total_subtotal: debito.getTotal_subtotal(),
      total_juros: debito.getTotal_juros()
 
 
    }), 'debito'); 


    
  }

 /* cadastraNotificacaoDebitoAPI(debito){

    let req = new Requests();
    console.log("CHAMEI API de Cadastro de Notificacao"); 
    const { v4: uuidv4 } = require('uuid');
    req.requestPOSTparam(JSON.stringify({
      id: uuidv4(),   
      nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
      colegio: debito.getcolegio(),
      aluno: debito.getaluno(),
      urlRootInadimplente: debito.getRootUrlInadimplente(),
/*      total_debito_atualizado : debito.getTotal_debito_atualizado(),
      total_valor_primitivo: debito.getTotal_valor_primitivo(),
      total_subtotal: debito.getTotal_subtotal(),
      total_juros: debito.getTotal_juros()*/
 
  // }), 'debitosGestao_notificacao');

//  }*/
/*
async cadastraNotificacaoDebitoAPI(debito) {
  let req = new Requests();
  console.log("CHAMEI API de Cadastro de Notificacao");
  const { v4: uuidv4 } = require('uuid');

  const requestBody = {
    id: uuidv4(),
    nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
    colegio: debito.getcolegio(),
    aluno: debito.getaluno(),
    urlRootInadimplente: debito.getRootUrlInadimplente(),
  };

  try {
    const response = await req.requestPOSTparam(JSON.stringify(requestBody), 'debitosGestao_notificacao');
  
    if (response && response.status === 201) {
      // Item já existe, emitir alerta para o front-end
      alert("Item already exists: " + response.data.message);
    } else if (response && response.status == 200) {
      // Item cadastrado com sucesso
      console.log("Item cadastrado com sucesso");
    } else if (response && response.status == 204) {
      // Item cadastrado com sucesso
      console.log("Item cadastrado com sucesso com 204");
    }  else {
      // Resposta inválida, emitir alerta para o front-end
      console.error("Erro desconhecido ao cadastrar item: " ,response.status);
     // alert("Erro desconhecido ao cadastrar item");
    }
  } 
}
*/

async cadastraNotificacaoDebitoAPI(debito) {
  let req = new Requests();
  console.log("CHAMEI API de Cadastro de Notificacao");
  const { v4: uuidv4 } = require('uuid');

  const requestBody = {
    id: uuidv4(),
    nomeResponsavelFinanceiro: debito.getnomeResponsavelFinanceiro(),
    colegio: debito.getcolegio(),
    aluno: debito.getaluno(),
    urlRootInadimplente: debito.getRootUrlInadimplente(),
  };

  try {
    //const response = await req.requestPOSTparam(JSON.stringify(requestBody), 'debitosGestao_notificacao');
    
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('GET', 'POST', 'OPTIONS');


    const response = await fetch(API_URL + 'debitosGestao_notificacao', {
      method: 'POST',
      body: requestBody,
      headers: headers,
    });

    // Aguardar a resposta antes de processar
    await this.processResponse(response);
    
  } catch (error) {
    // Erro ao realizar a solicitação, emitir alerta para o front-end
    this.handleError(error);
  }
}

async processResponse(response) {
  if (response) {
    switch (response.status) {
      case 201:
        alert("Item already exists: " + response.data.message);
        break;
      case 200:
        console.log("Item cadastrado com sucesso");
        break;
      case 204:
        console.log("Item cadastrado com sucesso com 204");
        break;
      default:
        console.error("Erro desconhecido ao cadastrar item:", response.status, response.data);
        alert("Erro desconhecido ao cadastrar item. Código: " + response.status);
    }
  } else {
    console.error("Resposta indefinida ao cadastrar item");
    alert("Erro ao cadastrar item: resposta indefinida.");
  }
}

handleError(error) {
  console.error("Erro ao cadastrar item:", error);
  
  // Alerta mais genérico para o usuário
  if (error.response) {
    alert("Erro ao cadastrar item: " + (error.response.data.message || error.message));
  } else if (error.request) {
    alert("Erro ao cadastrar item: Não houve resposta do servidor.");
  } else {
    alert("Erro ao cadastrar item: " + error.message);
  }
}



  buscaDebitoAPI(colegio, inadimplente, aluno){
    let req = new Requests();

    return req.requestGETparamDebito('debito', inadimplente, colegio, aluno);
  }

  buscacolegioAPI() {
    let req = new Requests();
    return req.requestGETparamColegiosTextfield("colegios");
  }

  buscaInadimplenteAPI(colegio) {
    let req = new Requests();
    return req.requestGETparamColegiosInadimplentesTextfield("inadimplentesAlunosColegio", colegio);
  }

  requestGETparamAlunosTextfield(inadimplente) {
    let req = new Requests();
    console.log("inadimplente:" );
    console.log( inadimplente);
    return req.requestGETparamAlunosTextfield("alunos", inadimplente.nomeInadimplente, 
                                                        inadimplente.nomeAluno, 
                                                        inadimplente.colegio);
  }
}