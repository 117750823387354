import { gapi } from 'gapi-script';
import { API_URL } from '../../../constants';


export default class MergeAndEditDocument {
  async mergeDataInDocument(rootURLPasta, rootURLProcuracao, nomeArquivo) {
    console.log("PASTA: " +rootURLPasta);
    console.log("DOCS: " +rootURLProcuracao);
 
    try {
      console.log("Fazendo download do documento do Google Docs...");
      // Adding a 15-second delay before calling downloadGoogleDocsAsPDF
      await new Promise((resolve) => setTimeout(resolve, 5000)); // 15-second delay
    //  await this.exportAndOpenPdf(rootURLProcuracao);
     await this.downloadGoogleDocsAsPDF(rootURLProcuracao, rootURLPasta, nomeArquivo);
      console.log("PDF salvo com sucesso!");
    } catch (err) {
      console.error('Error fetching or saving PDF:', err);
    }
  }


  async exportAndOpenPdf(fileId) {
    try {
        const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

        // Construa a URL com os parâmetros fileId e accessToken
        const url = `${API_URL}pdf/${fileId}?accessToken=${accessToken}`;

        // Faça a solicitação GET usando a URL construída
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                // Adicione outros cabeçalhos necessários aqui
            }
        });

        // Lide com a resposta da solicitação aqui, se necessário

    } catch (error) {
        console.error('Erro ao exportar ou abrir PDF:', error);
        throw error;
    }
}

   async downloadGoogleDocsAsPDF(documentId, rootURLPasta, nomeArquivo) {
     const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
     const googleDocsUrl = `https://docs.google.com/document/d/${documentId}/export?format=pdf&access_token=${accessToken}`;
     
     const response = await fetch(googleDocsUrl, {
      method: 'GET',
      mode: 'no-cors',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
    });
     const blob = await response.blob();
     this.uploadPdfToDrive(blob, rootURLPasta,nomeArquivo)
     const fileName = nomeArquivo+'.pdf';
     const link = document.createElement('a');
     link.href = window.URL.createObjectURL(blob);
     link.download = fileName;
     link.click();
   } 
 
 


 async downloadGoogleDocsAsPDF(documentId, rootURLPasta, nomeArquivo) {
    try {
      const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
      const googleDocsUrl = `https://docs.google.com/document/d/${documentId}/export?format=pdf&access_token=${accessToken}`;
      console.log("PDF DOC")
      console.log(documentId);//filedId
      console.log(rootURLPasta);//pathId
      console.log(nomeArquivo);
      const response = await fetch(googleDocsUrl, {
        method: 'GET',
       // mode: 'no-cors',
        headers: new Headers({
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }),
      });

 

      if (!response.ok) {
        console.error(`Response failed with status ${response.status}: ${JSON.stringify(response.headers)}`);
        console.error(response);
        throw new Error(`Error while fetching document: Response status ${response.status}`);
      }

      const blob = await response.blob();
      console.log(blob);
      this.uploadPdfToDrive(blob, rootURLPasta, nomeArquivo);

      const fileName = nomeArquivo + '.pdf';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

    } catch (error) {
      console.error('Error fetching or saving the PDF:', error);
    }
  }




  async uploadPdfToDrive(pdfBlob, rootURLPasta, nomeArquivo) {

    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    if (!accessToken) {
      console.error('Access token not available');
      return;
    }
    const folderId = rootURLPasta;

    const fileMetadata = {
      name: nomeArquivo + '.pdf', // Nome do arquivo PDF no Google Drive
      parents: [folderId]
    };

    const form = new FormData();
    form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
    form.append('file', pdfBlob);

    const response = await fetch(`https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&access_token=${accessToken}`, {
      method: 'POST',
      body: form
    });

    if (!response.ok) {
      throw new Error('Failed to upload PDF to Google Drive');
    }
  }
}
