import React from "react";


let id;
let nomeResponsavelFinanceiro;
let colegio;
let aluno;
let dataEnvio;
let dataRecebimento;
let idPastaGoogle;

export default class ReceberAR {

    constructor(){}

   // Métodos GET
 getId() {
    return id;
  }
  
   getNomeResponsavelFinanceiro() {
    return nomeResponsavelFinanceiro;
  }
  
   getColegio() {
    return colegio;
  }
  
   getAluno() {
    return aluno;
  }
  
   getDataEnvio() {
    return dataEnvio;
  }
  
   getDataRecebimento() {
    return dataRecebimento;
  }
  
   getIdPastaGoogle() {
    return idPastaGoogle;
  }
  
  // Métodos SET
   setId(newId) {
    id = newId;
  }
  
   setNomeResponsavelFinanceiro(newNome) {
    nomeResponsavelFinanceiro = newNome;
  }
  
   setColegio(newColegio) {
    colegio = newColegio;
  }
  
   setAluno(newAluno) {
    aluno = newAluno;
  }
  
   setDataEnvio(newDataEnvio) {
    dataEnvio = newDataEnvio;
  }
  
   setDataRecebimento(newDataRecebimento) {
    dataRecebimento = newDataRecebimento;
  }
  
   setIdPastaGoogle(newIdPastaGoogle) {
    idPastaGoogle = newIdPastaGoogle;
  }


}
