import { DataGrid } from '@mui/x-data-grid';
import Requests from "../../api/requests";
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

// Botão de deletar
export function DeleteButton({ id, handleDelete }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(id)}
    >
      
    </Button>
  );
}

function handleSaveClick(){
  console.log("Ola");
}

let req = new Requests();

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}


 


const DataGridAluno = () => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "alunos")
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])
  console.log("tableData");
  console.log(tableData);



  const columns = [
    {
      field: 'actions',
      headerName: 'Ações',
      width: 150,
      renderCell: (params) => (
        <DeleteButton id={params.row.id} handleDelete={handleDelete} />
      ),
    },
    { 
      field: 'colegioAluno',
     headerName: 'Nome do Colegio',
      width: 150,
      editable: true
    },
    {
      field: 'inadimplenteAluno',
      headerName: 'Responsavel Financeiro',
      width: 150,
      editable: true,
    },
    {
      field: 'nomeAluno',
      headerName: 'Nome do Aluno',
      width: 150,
      editable: true,
    },
  
    {
      field: 'codigoAluno',
      headerName: 'Código do Aluno/ RGM/ Matricula',
      width: 150,
      editable: true,
    },
    {
      field: 'turmaAluno',
      headerName: 'Turma do Aluno',
      width: 150,
      editable: true,
    },
    {
      field: 'generoAluno',
      headerName: 'Genero do Aluno',
      width: 150,
      editable: true,
    },
  ];
    // Função de deletar dentro do componente
    const handleDelete = (id) => {
      fetch(`${API_URL}alunos/${id}`, {
        method: 'DELETE',
      })
      .then(() => {
        // Remover o registro localmente após a exclusão
        setTableData((prevData) => prevData.filter((row) => row.id !== id));
      })
      .catch((error) => console.error('Erro ao deletar:', error));
    };
  

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridAluno

 




 
