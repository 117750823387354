import { DataGrid } from '@mui/x-data-grid';
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';



function handleSaveClick(){
  console.log("Ola");
}


export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}




const columns = [

  { 
    field: 'nomeResponsavelFinanceiro',
   headerName: 'Responsavel Financeiro',
    width: 150,
    editable: true
  },
  {
    field: 'whatsapp',
    headerName: 'WhatsApp',
    width: 80,
    editable: true,
  },
  {
    field: 'telefone',
    headerName: 'telefone',
    width: 80,
    editable: true,
  },
  {
    field: 'nacionalidade',
    headerName: 'nacionalidade',
    width: 100,
    editable: true,
  },
  {
    field: 'rg',
    headerName: 'rg',
    width: 80,
    editable: true,
  },

  {
    field: 'cpf',
    headerName: 'cpf',
    width: 80,
    editable: true,
  },
  {
    field: 'endereco',
    headerName: 'endereco',
    width: 150,
    editable: true,
  },
  {
    field: 'numero',
    headerName: 'numero',
    width: 20,
    editable: true,
  },

  {
    field: 'complemento',
    headerName: 'complemento',
    width: 20,
    editable: true,
  },
  {
    field: 'bairro',
    headerName: 'bairro',
    width: 80,
    editable: true,
  },
  {
    field: 'cep',
    headerName: 'cep',
    width: 80,
    editable: true,
  },
  {
    field: 'cidade',
    headerName: 'cidade',
    width: 80,
    editable: true,
  },
  {
    field: 'estado',
    headerName: 'estado',
    width: 80,
    editable: true,
  },
  {
    field: 'email',
    headerName: 'email',
    width: 150,
    editable: true,
  },
  {
    field: 'genero',
    headerName: 'genero',
    width: 80,
    editable: true,
  },
  {
    field: 'estadoCivil',
    headerName: 'estadoCivil',
    width: 80,
    editable: true,
  },
  {
    field: 'profissao',
    headerName: 'profissao',
    width: 80,
    editable: true,
  },

  {
    field: 'endereco2',
    headerName: 'endereco2',
    width: 150,
    editable: true,
  }
];


const DataGridConsultaInadimplente = () => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "inadimplentes", {
      method: 'GET',
      mode: 'no-cors',
      allowedHeaders: 'Content-Type'
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])
  console.log("tableData");
  console.log(tableData);

  return (
    <div style={{ height: "100%", width: '100%'  }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={5}
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridConsultaInadimplente

 




 
