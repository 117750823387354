import useDrivePicker from 'react-google-drive-picker'
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';



function PickerGoogleDrive() {
  const [openPicker, authResponse] = useDrivePicker();
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = () => {
    openPicker({
      clientId: "527124533132-0jqja7pt7sfscuo1le69ffurah6lemad.apps.googleusercontent.com",
      developerKey: "AIzaSyBzZe7QrPg66V9vCTW9gnF6ZYLkITLyAao",
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button')
        }
        console.log(data)
      },
    })
  }



  return (
    <Box sx={{ justifyContent: "center", width: "100%" }} >
      <Button variant="outlined" startIcon={<CloudUploadIcon />} onClick={() => handleOpenPicker()}
        sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "80%" }} color="success">
        Upload
      </Button>
    </Box>


  );
}

export default PickerGoogleDrive;