import React, { useEffect } from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DataGrid, GridEditInputCell } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { API_URL } from '../../constants';
import UploadImageIco from '../googleApi/uploadImageIco';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// Função para validar o nome de usuário
let promiseTimeout;
function validateName(username) {
  const existingUsers = rows.map((row) => row.name.toLowerCase());

  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const exists = existingUsers.includes(username.toLowerCase());
      resolve(exists ? `${username} is already taken.` : null);
    }, Math.random() * 500 + 100); // Simula latência de rede
  });
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  return <NameEditInputCell {...params} />;
}

export default function CasosJudiciais() {
  const [tableData, setTableData] = useState([]);
  
  useEffect(() => {
    fetch(API_URL + "Judiciais")
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  const columns = [
    {
      field: 'colegio',
      headerName: 'Colegio',
      width: 250,
     // editable: true,
      //renderEditCell: renderEditName,
    },
    {
      field: 'nomeResponsavelFinanceiro',
      headerName: 'Responsável Financeiro',
      width: 250,
     // editable: true,
     // renderEditCell: renderEditName,
    },
    {
      field: 'aluno',
      headerName: 'Nome do Aluno',
      width: 250,
     // editable: true,
     // renderEditCell: renderEditName,
    },
  
    /*{
      field: 'upload',
      headerName: 'Comprovante de AR',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <CloudUploadIcon
          onClick={() => handleOpen(params.row)}
          style={{ cursor: 'pointer' }}
        />
      ),
    },*/
  ];

  const [open, setOpen] = React.useState(false);
  const [modalParams, setModalParams] = React.useState({});

  const handleOpen = (row) => {
    setModalParams(row); // Armazenando params em estado para uso no modal
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box height={400} width="100%">
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        isCellEditable={(params) => params.row.id === 5}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography variant="h6" component="h3">
            <b>Upload da AR</b>
          </Typography>
          <Typography sx={{ m: 2 }}>
            Para finalizar o fluxo de notificação, por favor, faça upload do comprovante de RECEBIMENTO da AR em PDF.
          </Typography>
          <Typography sx={{ m: 2 }}>
            Responsável Financeiro: <b>{modalParams.nomeResponsavelFinanceiro}</b>
          </Typography>
          <Typography sx={{ m: 2 }}>
            Pasta no Google Docs: <b>{modalParams.idPastaGoogle}</b>
          </Typography>

          {modalParams.idPastaGoogle && (
            <UploadImageIco pastaGoogle={modalParams.idPastaGoogle} nomeResponsavelFinanceiro={modalParams.nomeResponsavelFinanceiro} />
          )}

          <br />
        </Box>
      </Modal>
    </Box>
  );
}
