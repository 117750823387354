import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import PieChartComp from '../components/Charts/PieChart'
import BarChartComp from '../components/Charts/BarChart'
import BarChartSeriesComp from '../components/Charts/BarChartSeries'

const ChartHome = () => {


    return (

        <Card sx={{ width: '100%', m: 0, height: '100%' }}>
            <PieChartComp />
            <BarChartComp />
            <BarChartSeriesComp />

        </Card>
    );
};







export default ChartHome;
