import * as React from 'react';
import { styled } from '@mui/system';
import {Tabs} from '@mui/base/Tabs';
import {Tab} from '@mui/base/Tab';
import {TabsList} from '@mui/base/TabsList';
import {TabPanel} from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { tabClasses } from '@mui/base/Tab';
import CadastroInadimplente from './TabCadastrosInadimplentes';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ConferirDocumentos from './TabGestaoInadimplente/ConferirDocumentos';
import ListaCobrarInadimplentes from './ListaCobrarInadimplentes';
import Style from './StyledVariaveis.js';
import GerirContratos from './TabGestaoInadimplente/GerirContratos';
import ConferirAssinaturas from './TabGestaoInadimplente/ConferirAssinaturas';
import GerarEvidencias from './TabGestaoInadimplente/GerarEvidencias';
import EnviarNotificacao from './TabGestaoInadimplente/EnviarNotificacao';
import ReceberAR from  './TabGestaoInadimplente/ReceberAR';
import PagamentosAtrasados from  './TabPagamentos/PagamentosAtrasados';
import PagamentosPendentes from './TabPagamentos/PagamentosPendentes';
import CasosExtraJudiciais from './TabGestaoJudiciais/CasosJudiciais';


import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';



const ProSpan = styled('span')({
  display: 'inline-block',
  height: '1em',
  width: '1em',
  verticalAlign: 'middle',
  marginLeft: '0.3em',
  marginBottom: '0.08em',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: 'url(https://mui.com/static/x/pro.svg)',
});

function Label({
  componentName,
  valueType,
  isProOnly,
}: {
  componentName: string;
  valueType: string;
  isProOnly?: boolean;
}) {
  const content = (
    <span>
      <strong>{componentName}</strong> for {valueType} editing
    </span>
  );

  if (isProOnly) {
    return (
      <Stack direction="row" spacing={0.5} component="span">
        <Tooltip title="Included on Pro package">
          <a
            href="https://mui.com/x/introduction/licensing/#pro-plan"
            aria-label="Included on Pro package"
          >
            <ProSpan />
          </a>
        </Tooltip>
        {content}
      </Stack>
    );
  }

  return content;
}

export default function CommonlyUsedComponents() {
  return (
    <Box sx={{padding:3}}>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'DatePicker',
          'TimePicker',
          'DateTimePicker',
          'DateRangePicker',
        ]}
      >
       
        <DemoItem
          label={
            <Label
              componentName="DateRangePicker"
              valueType="date range"
              isProOnly
            />
          }
          component="DateRangePicker"
        >
          <DateRangePicker
            localeText={{
              start: '',
              end: '',
            }}
          />
        </DemoItem>
      </DemoContainer>

    </LocalizationProvider><br></br>
          <Button variant="contained">Gerar Relatório</Button>
          </Box>
  );
}