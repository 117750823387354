import * as React from 'react';
import { styled } from '@mui/system';
import {Tabs} from '@mui/base/Tabs';
import {TabsList} from '@mui/base/TabsList';
import {TabPanel} from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { tabClasses } from '@mui/base/Tab';
import {Tab} from '@mui/base/Tab';
import CadastroInadimplente from '../components/TabCadastroInadimplente/CadastroInadimplente';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CadastroDebito from './TabCadastroInadimplente/CadastroDebitoInadimplente';
import CadastroAluno from '../components/TabCadastroInadimplente/CadastroAluno';



const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '10px', transform: 'scale(0.9)', width: '100%'}}
    >
        
    </Box>
);


const card1 = (
    <React.Fragment>
        <CardContent> <CadastroInadimplente /></CardContent>
    </React.Fragment>
);

const card2 = (
    <React.Fragment>
        <CardContent>  <CadastroAluno /></CardContent>
    </React.Fragment>
);

const card3 = (
    <React.Fragment>
        <CardContent>  <CadastroDebito /></CardContent>
    </React.Fragment>
);


export default function TabCadastrosInadimplente() {

    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (

        <Card sx={{  width: '100%' }}>
            <CardContent>



                <Tabs defaultValue={1}>
                    <StyledTabsList>
                        
                        <StyledTab value={1}>Cadastro Inadimplente</StyledTab>
                        <StyledTab value={2}>Cadastro do Aluno</StyledTab>
                        <StyledTab value={3}>Cadastro Debito</StyledTab>
             
                    </StyledTabsList>
                    <StyledTabPanel value={1}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card1}</Card></Box>
                    </StyledTabPanel>
                    <StyledTabPanel value={2}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card2}</Card></Box>
                    </StyledTabPanel>
                    <StyledTabPanel value={3}>
                        <Box sx={{ width: '100%' }}><Card variant="outlined">{card3}</Card></Box>
                    </StyledTabPanel>
                </Tabs>

            </CardContent>

        </Card>




    );
}

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
};

const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
};

const green = {

    50: '#E8F8F5',
    100: '#D4EFDF',
    200: '#7DCEA0',
    300: '#afb8c1',
    400: '#52BE80',
    500: '#27AE60',
    600: '#229954',
    700: '#1E8449',
    800: '#196F3D',
    900: '#0B5345',
    1000: '#08362D',
}






const StyledTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 3px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${green[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.675rem;

`;

const StyledTabsList = styled(TabsList)(
    ({ theme }) => `
  min-width: 900px;
  background-color: ${green[800]};
  border-radius: 12px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);