import * as React from 'react';
import { styled } from '@mui/system';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { tabClasses } from '@mui/base/Tab';
import { Tab } from '@mui/base/Tab';

import CadastroInadimplente from './TabCadastrosInadimplentes';
import Box from '@mui/material/Box';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ConferirDocumentos from './TabGestaoInadimplente/ConferirDocumentos';
import ListaCobrarInadimplentes from './ListaCobrarInadimplentes';
import Style from './StyledVariaveis.js';
import GerirContratos from './TabGestaoInadimplente/GerirContratos';
import ConferirAssinaturas from './TabGestaoInadimplente/ConferirAssinaturas';
import GerarEvidencias from './TabGestaoInadimplente/GerarEvidencias';
import EnviarNotificacao from './TabGestaoInadimplente/EnviarNotificacao';
import ReceberAR from './TabGestaoInadimplente/ReceberAR';
import GeraTodosDocumentos from '../components/googleApi/CriaContratos/geraTodosDocumentos';
import Stack from '@mui/material/Stack';
const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '1px', transform: 'scale(0.8)' }}
  >

  </Box>
);

//const GeraTodosDocumentos = new GeraTodosDocumentos();



const card0 = (
  <React.Fragment>
    <CardContent  sx ={{minHeight:'700px'}}>
      <Typography variant="h6" component="h2">
        Instruções de Envio
      </Typography>
      <Typography variant="subtitle1" component="subtitle1">
        Este é o momento de Imprimir a notificação e enviar para os correios
      </Typography>
      <EnviarNotificacao />
    </CardContent>
  </React.Fragment>
);

const card5 = (
  <React.Fragment>
    <CardContent> <ReceberAR /></CardContent>

  </React.Fragment>
);

const card1 = (
  <React.Fragment>
    <CardContent>
      <Typography variant="h6" component="h2">
        Instruções de Contato com o cliente
      </Typography>
      <Typography variant="subtitle1" component="subtitle1">
        Meu nome é Mariana! Sou do setor jurídico do COLÉGIO X ou da ESCOLA Y.
        E quero muito que saiba, que apesar de fazer parte de um escritório de advocacia, fazendo atendimentos humanizados.
        Sempre ouvindo a outra parte e tentando ajudar.
        Diante disso, poderia me confirmar algumas informações    < br />
      </Typography>
      <ConferirDocumentos />
    </CardContent>

  </React.Fragment>
);

const card2 = (
  <React.Fragment>
    <CardContent>
      <Typography variant="h5" component="h5">
        Propostas Aprovadas Pendente Assinatura Contrato
      </Typography>
      <Typography variant="subtitle1" component="subtitle1">

        Seguem as propostas já aprovadas:
      </Typography>

      <GerirContratos /></CardContent>

  </React.Fragment>
);

const card3 = (
  <React.Fragment>
    <CardContent> <ConferirAssinaturas /></CardContent>

  </React.Fragment>
);
const card4 = (
  <React.Fragment>
    <CardContent> <GerarEvidencias /></CardContent>

  </React.Fragment>
);


export default function TabGestaoInadimplentes() {

  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (

    <Card sx={{ minWidth: '82%', minHeight: 900  }}>
      <CardContent>
        <Typography variant="h5" color="text.secondary" gutterBottom>
          Gestão de Inadimplentes<br />
        </Typography>

        <Tabs defaultValue={0}>
          <StyledTabsList>
            <StyledTab value={0}>Enviar Notificação</StyledTab>
            <StyledTab value={5}>Receber AR</StyledTab>
            <StyledTab value={1}>Conferir Dados do Cliente</StyledTab>
            <StyledTab value={2}>Gerar Contratos</StyledTab>
            <StyledTab value={4}>Gerar Evidencias das Convensas</StyledTab>
          </StyledTabsList>
          <StyledTabPanel value={0}>
            <Box sx={{ minWidth: 275 }}><Card variant="outlined">{card0}</Card></Box>
          </StyledTabPanel>
          <StyledTabPanel value={5}>
            <Box sx={{ minWidth: 275 }}><Card variant="outlined">{card5}</Card></Box>
          </StyledTabPanel>
          <StyledTabPanel value={1}>
            <Box sx={{ minWidth: 275 }}><Card variant="outlined">{card1}</Card></Box>
          </StyledTabPanel>
          <StyledTabPanel value={2}>
            <Box sx={{ minWidth: 275 }}><Card variant="outlined">{card2}</Card></Box>
          </StyledTabPanel>
          <StyledTabPanel value={3}>
            <Box sx={{ minWidth: 275 }}><Card variant="outlined">{card3}</Card></Box>
          </StyledTabPanel>
          <StyledTabPanel value={4}>
            <Box sx={{ minWidth: 275 }}><Card variant="outlined">{card4}</Card></Box>
          </StyledTabPanel>
        </Tabs>

      </CardContent>
      
    </Card>




  );
}

const blue = {
  50: '#F0F7FF',
  100: '#C2E0FF',
  200: '#80BFFF',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  700: '#0059B2',
  800: '#004C99',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const green = {

  50: '#E8F8F5',
  100: '#D4EFDF',
  200: '#7DCEA0',
  300: '#afb8c1',
  400: '#52BE80',
  500: '#27AE60',
  600: '#229954',
  700: '#1E8449',
  800: '#196F3D',
  900: '#0B5345',
  1000: '#08362D',
}



const StyledTab = styled(Tab)`
  font-family: IBM Plex Sans, sans-serif;
  color: white;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  padding: 3px;
  margin: 6px 6px;
  border: none;
  border-radius: 7px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: ${green[400]};
  }

  &:focus {
    color: #fff;
    outline: 3px solid ${green[200]};
  }

  &.${tabClasses.selected} {
    background-color: #fff;
    color: ${green[600]};
  }

  &.${buttonClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const StyledTabPanel = styled(TabPanel)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.675rem;

`;

const StyledTabsList = styled(TabsList)(
  ({ theme }) => `
  min-width: 900px;
  background-color: ${green[800]};
  border-radius: 12px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
  box-shadow: 0px 4px 8px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  `,
);