
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import Debito from '../../DTO/Debito.js';
import CrudDebito from '../../Service/CrudDebito.js';
import DataGridDebito from '../datagrids/DataGridCadastroDebito.js';
import Autocomplete from '@mui/material/Autocomplete';
import DataGridCalculoDebito from '../datagrids/DataGridCalculoDebito.js';
import DataGridDebitoLista from '../datagrids/DataGridDebitoLista.js';


const debito = new Debito();
const crudDebito = new CrudDebito();



export default function CadastroDebitoInadimplente() { 
  return (
    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
    }} >
          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
          <h2>Cadastro do Débito</h2>
              <DataGridCalculoDebito/> 
              <DataGridDebitoLista/>
          </Stack>
        </Box> 
     
  );
}
