
import {FormControl} from '@mui/base/FormControl';

import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import crudIndice from "../../Service/CrudIndice.js";
import Indice from "../../DTO/Indice.js";
import DataGridIndice from "../datagrids/DataGridIndice.js";



const indice = new Indice();
let crudIndices = new crudIndice();
function cadastraIndice() {
  crudIndices.cadastraIndiceAPI(indice);

}
//Indices CSV
//https://docs.google.com/spreadsheets/d/1P_3RNwLNrPTqrjdWHupe2kI0goK_F64Mv6-0XnPxYHw/edit#gid=0


export default function CadastroIndice() {




  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",


    }} >

      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',


      }} >



        <Box sx={{
          width: '60%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2

        }} >
          <FormControl defaultValue="">
            <Stack spacing={1} sx={{ marginLeft: 5, marginRight: 5, marginTop: 5, marginBottom: 5, align: "Center" }}>

              <h2>Cadastro do índice</h2>
                <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Indice"
                onChange={e => indice.setindice(e.target.value)}
                value={indice.getindice()}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Ano"
                onChange={e => indice.setano(e.target.value)}
                value={indice.getano()}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Mes"
                onChange={e => indice.setmes(e.target.value)}
                value={indice.getmes()}
                fullWidth
                required
              />


              <Box sx={{ justifyContent: "center", width: "100%" }} >
     
                <Button variant="outlined" onClick={cadastraIndice} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>

                <Button variant="outlined" onClick={cadastraIndice} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>

              <p> <br/><br/>O indice pode ser atualizado através da consultada nas tabelas abaixo: </p>
              <p> <a style={{color:'black'}} target="_blank" href='https://www.aasp.org.br/suporte-profissional/indices-economicos/indices-judiciais/tabela-depre-tabela-pratica-para-calculo-de-atualizacao-monetaria-dos-debitos-judiciais/'> <b>AASP</b> </a></p>
              <p> <a style={{color:'black'}} target="_blank" href= 'https://www.tjsp.jus.br/Download/Tabelas/TabelaDebitosJudiciais.pdf'><b>TJSP</b></a></p>
           
            </Stack>

          </FormControl>

        </Box>
        <Box sx={{ justifyContent: "right", width: "40%", position: "relative", flexGrow: 2 }} >
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%" }}
            image="https://www.grupocentralcobrancas.com.br/uploads/produto/2022-01-141642173036_fb39f44892a2d3fca8eb.png"
            alt="Live from space album cover"
          />
        </Box>
      </Card>


      <Divider variant="middle" sx={{ margin: 2 }} />




      <Card variant="outlined">
        <Box component="BxgridCadastroIndice" sx={{
          margin: "0px",
          p: 0,
          height: '90%',
          width: '100%',
          justifyContent: 'center',
          background: '#fff'
        }} >

          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h2>Indices Cadastrados</h2>

            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DataGridIndice />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>

  );

}
