
import { DataGrid } from '@mui/x-data-grid';
import Requests from "../../api/requests";
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';

 

function handleSaveClick(){
  console.log("Ola");
}

let req = new Requests();

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}


const columns = [

  { 
    field: 'OAB',
   headerName: 'OAB',
    width: 80,
    editable: true
  },
  {
    field: 'nomeAdvogado',
    headerName: 'Nome',
    width: 150,
    editable: true,
  },
  {
    field: 'emailAdvogado',
    headerName: 'Email',
    width: 150,
    editable: true,
  },
  {
    field: 'escritorio',
    headerName: 'escritorio',
    width: 150,
    editable: true,
  },
  {
    field: 'estadoCivil',
    headerName: 'Estado Civil',
    width: 100,
    editable: true,
  },
  {
    field: 'nacionalidade',
    headerName: 'Nacionalidade',
    width: 125,
    editable: true,
  },
  {
    field: 'whatsApp',
    headerName: 'WhatsApp',
    width: 100,
    editable: true,
  },
  {
    field: 'cpfAdvogado',
    headerName: 'CPF',
    width: 100,
    editable: true,
  },
  {
    field: 'enderecoAdvogado',
    headerName: 'Endereço',
    width: 150,
    editable: true,
  },
  {
    field: 'numero',
    headerName: 'numero',
    width: 150,
    editable: true,
  }, 
  {
    field: 'cepAdvogado',
    headerName: 'CEP',
    width: 100,
    editable: true,
  },
  {
    field: 'cidadeAdvogado',
    headerName: 'Cidade',
    width: 100,
    editable: true,
  },
  {
    field: 'estadoAdvogado',
    headerName: 'Estado',
    width: 100,
    editable: true,
  },
  {
    field: 'bairroAdvogado',
    headerName: 'Bairro',
    width: 100,
    editable: true,
  },
  {
    field: 'editar',
    headerName: 'Editar',
    width: 100,
    editable: true,
  },
  {
    field: 'deletar',
    headerName: 'Deletar',
    width: 100,
    editable: true,
  },
  

];


const DataGridDemo = () => {

  const [tableData, setTableData] = useState([])

  useEffect(() => {
    fetch(API_URL + "advogados",{
      method: 'GET',
      allowedHeaders: 'Content-Type'
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])

  console.log(tableData)

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridDemo

 




 
