import { gapi } from 'gapi-script';
import { API_URL } from '../../../constants';

const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES = 'https://www.googleapis.com/auth/documents';

const meses = [
  'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
  'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
];

const dataAtual = new Date();
const dataFormatada = `${dataAtual.getDate()}/${dataAtual.getMonth() + 1}/${dataAtual.getFullYear()}`; // Corrigido para mes 1-12

async function mergeDataInDocument(colegio, inadimplente, aluno) {
  try {
    const response = await fetch(`${API_URL}debito/${colegio}/${inadimplente}/${aluno}`, {
      method: 'GET',
      mode: 'cors'
    });

    if (!response.ok) {
      const errorData = await response.json();
      console.error(`Erro: ${response.status} - ${response.statusText}`, errorData);
      throw new Error(`Erro ao buscar dados: ${response.statusText}`);
    }
    const json = await response.json();
    const itemDebito = json.itemDebito || [];

    const debito = itemDebito.map((item, index) => ([
      { range: `Atualização de Valores!C${9 + index}`, values: [[index + 1]] },
      { range: `Atualização de Valores!D${9 + index}`, values: [[item.descricao]] },
      { range: `Atualização de Valores!E${9 + index}`, values: [[item.dataVencimento]] },
      { range: `Atualização de Valores!F${9 + index}`, values: [[item.valor]] }
    ])).flat();

    const cabecalho = [
      { range: "Atualização de Valores!D2", values: [[json.colegio]] },
      { range: "Atualização de Valores!D3", values: [[json.nomeResponsavelFinanceiro]] },
      { range: "Atualização de Valores!D4", values: [[json.aluno]] },
      { range: "Atualização de Valores!E5", values: [[dataFormatada]] },
      { range: "Atualização de Valores!G5", values: [[1]] }
    ];

    console.log("Debito:", debito);
    console.log("Cabecalho:", cabecalho);

    setTimeout(() => geraPlanilha(colegio, inadimplente, aluno, cabecalho, debito), 3000);
  } catch (error) {
    console.error('Erro ao processar dados do débito:', error);
  }
}

async function geraPlanilha(colegio, inadimplente, aluno, cabecalho, debito) {
  const pastainadimplente = document.getElementById("idRootInadimplente").value;
  const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
  const fileId = '1B1VcdkXvT_r1xMxs9nXungrvsaw186I-H0KVE6_wI5o';

  const requestData = {
    title: 'Planilha',
    name: '02.PLANILHA',
    parents: [pastainadimplente]
  };

  try {
    const res = await fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    });

    if (!res.ok) throw new Error(`Erro ao copiar arquivo: ${res.statusText}`);

    const val = await res.json();
    const newDocumentId = val.id;

    await gapi.client.load('sheets', 'v4');
    await gapi.client.sheets.spreadsheets.values.batchUpdate({
      spreadsheetId: newDocumentId,
      resource: {
        data: [cabecalho, debito],
        valueInputOption: "RAW"
      }
    });

    await fetch(`${API_URL}debito/${colegio}/${inadimplente}/${aluno}/${newDocumentId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    });

    await fetch(`${API_URL}inadimplentesPlanilha/${colegio}/${inadimplente}/${aluno}/${newDocumentId}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
    });

    window.open(`https://docs.google.com/spreadsheets/d/${newDocumentId}`, '_blank');
    exportarParaExcel(newDocumentId);

        // Adicionar um evento load para atualizar a tela
          window.location.reload(); // Recarregar a página após abrir a nova planilha

  } catch (error) {
    console.error("Erro ao gerar planilha:", error);
  }
}

async function exportarParaExcel(spreadsheetId) {
  const url = `https://www.googleapis.com/drive/v3/files/${spreadsheetId}/export?mimeType=application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
  const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${accessToken}`,
      }
    });

    if (!response.ok) throw new Error('Erro ao exportar para Excel');

    const blob = await response.blob();
    const a = document.createElement('a');
    const urlBlob = window.URL.createObjectURL(blob);
    a.href = urlBlob;
    a.download = '02.PLANILHA.xlsx';
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.error('Erro ao exportar para Excel:', error);
  }
}

export default function MergeAndEditPlanilha(colegio, inadimplente, aluno) {
  mergeDataInDocument(colegio, inadimplente, aluno);
}
