import React from "react";
 
let aaa;

export default class ValidaTextField {



    constructor(aaa) {
        this.aaa = aaa;
    }


        
  validacaoEmail(field) {
    let usuario = field.substring(0, field.indexOf("@"));
    let dominio = field.substring(field.indexOf("@")+ 1, field.length);
    
    if ((usuario.length >=1) &&
        (dominio.length >=3) &&
        (usuario.search("@")==-1) &&
        (dominio.search("@")==-1) &&
        (usuario.search(" ")==-1) &&
        (dominio.search(" ")==-1) &&
        (dominio.search(".")!=-1) &&
        (dominio.indexOf(".") >=1)&&
        (dominio.lastIndexOf(".") < dominio.length - 1)) {
   // document.getElementById("msgemail").innerHTML="E-mail válido";
  //  alert("E-mail valido");
    }
    else{
  //  document.getElementById("msgemail").innerHTML="<font color='red'>E-mail inválido </font>";
    alert("E-mail invalido");
    }
    }

     validaCNPJ(cnpj) {

   


      cnpj = cnpj.replace(/[^\d]+/g,'');

      if (cnpj.length !== 14) {
        return false;
      }
    
      // Verifica se todos os dígitos são iguais
      if (/^(\d)\1+$/.test(cnpj)) {
        return false;
      }
    
/*
      // Validação do primeiro dígito verificador
      let soma = 0;
      for (let i = 0; i < 12; i++) {
        soma += parseInt(cnpj.charAt(i)) * (12 - i);
      }
      let resto = (soma % 11);
      let digito1 = (resto < 2) ? 0 : 11 - resto;
    
      if (parseInt(cnpj.charAt(12)) !== digito1) {
        return false;
      }
    
  
      // Validação do segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 13; i++) {
        soma += parseInt(cnpj.charAt(i)) * (13 - i);
      }
      resto = (soma % 11);
      let digito2 = (resto < 2) ? 0 : 11 - resto;

    
    
      return parseInt(cnpj.charAt(13)) === digito2;
      */
     return true;
    }
    


}