
import { FormControl } from '@mui/base/FormControl';
import Button from '@mui/material/Button';
import { TextField, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { StyledEngineProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react'
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import BusinessIcon from '@mui/icons-material/Business';

import EscritorioAdvocacia from '../../DTO/EscritorioAdvocacia.js';
import crudEscritorioAdvocacia from '../../Service/CrudEscritorio.js';
import DataGridEscritorio from '../datagrids/DataGridCadastroEscritorio.js';
import ValidaTextField from '../Helper/ValidaTextField.js';
import CreateFolderID from '../googleApi/Cria Pastas /createFolderIDEscritorio.js';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';

const escritorioAdvocacia = new EscritorioAdvocacia();
const crudescr = new crudEscritorioAdvocacia();



const steps = [
  {
    label: 'Passo 1 - Cadastro do Escritório',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Passo 2 - Crie uma pasta para o escritório',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Passo 3 - Selecione o arquivo e faça upload no logo do Escritório:',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

function CepSearch() {
  const [cep, setCep] = useState('');
  const [address, setAddress] = useState({});

  const fetchCepData = () => {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        setAddress(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  escritorioAdvocacia.setcep(address.cep);
  escritorioAdvocacia.setEnderecoEscritorio(address.logradouro);
  escritorioAdvocacia.setbairro(address.bairro);
  escritorioAdvocacia.setcidade(address.localidade);
  escritorioAdvocacia.setestado(address.uf);

  const limpaCep = () => {
    setAddress("");
    setCep("");
  };






  return (
    <div>
      <TextField
        label="CEP"
        value={cep}
        onChange={(e) => setCep(e.target.value)}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '30%' }}
      />
             <Button variant="standard" onClick={fetchCepData}
        sx={{ marginTop: 2, marginLeft: 0, justifyContent: "center", width: "5%" }}
        color="success" endIcon={<SendIcon />}> </Button>

      <TextField
        label="Numero do Endereço"
        color='secondary'
        value={escritorioAdvocacia.getnumero()}
        onChange={e => escritorioAdvocacia.setnumero(e.target.value)}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '20%', marginLeft: 2 }}
      />
      <TextField
        label="Complemento"
        color='secondary'
        value={escritorioAdvocacia.getcomplemento()}
        onChange={e => escritorioAdvocacia.setcomplemento(e.target.value)}
        variant="standard"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <BusinessIcon />
            </InputAdornment>
          ),
        }}
        sx={{ width: '30%', marginLeft: 2 }}
      />
      <div>
        {address.cep && (
          <div>
            <h6>Informações do Endereço:</h6>
            <p>CEP: {address.cep}</p>
            <p>Logradouro: {address.logradouro}</p>
            <p>Bairro: {address.bairro}</p>
            <p>Cidade: {address.localidade}</p>
            <p>Estado: {address.uf}</p>

          </div>
        )}

      </div>
    </div>


  );
}


function validaEmail(email) {
  const validacao = new ValidaTextField("");
  validacao.validacaoEmail(email)
  escritorioAdvocacia.setemailEscritorio(email);

}
function validaCNPJ(cnpj) {
  const validacao = new ValidaTextField("");
  const retornoValidacao = validacao.validaCNPJ(cnpj);

  if (retornoValidacao) {
    console.log('CNPJ válido');
  } else {
    alert('CNPJ inválido');
  }
  escritorioAdvocacia.setcnpjEscritorio(cnpj);
}


function limpaform() {
  Array.from(document.querySelectorAll("input")).forEach(
    input => (input.value = "")
  );
}


function cadastraEscritorioAdvocacia() {
  crudescr.cadastraEscritorioAdvocaciaAPI(escritorioAdvocacia);
  CadastroEscritorioAdvocacia();
}

export default function CadastroEscritorioAdvocacia() {


  return (

    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",
    }} >
      <Card variant="outlined" sx={{
        width: '100%',
        justifyContent: "left",
        flexDirection: 'row',
        display: 'flex',
      }} >
        <Box sx={{
          width: '60%',
          justifyContent: "left",
          position: "relative",
          flexGrow: 2
        }} >
          <FormControl defaultValue="">
            <Stack spacing={0.4} sx={{ marginLeft: 2, marginRight: 2, marginTop: 1, marginBottom: 1, align: "Center" }}>
              <h5>Cadastro de Escritório de Advocacia</h5>
              <br /><br /> <br />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Nome do Escritório"
                name="Escritorio"
                onChange={e => escritorioAdvocacia.setNomeEscritorio(e.target.value)}
                value={escritorioAdvocacia.getNomeEscritorio()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="Email"
                onBlur={e => validaEmail(String(e.target.value))}
                value={escritorioAdvocacia.getemailEscritorio()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <MailIcon />
                    </InputAdornment>
                  ),
                }}
                fullWidth
                required
              />
              <TextField
                type="text"
                variant='standard'
                color='secondary'
                label="CNPJ do Escritório"
                onBlur={e => validaCNPJ(e.target.value)}
                value={escritorioAdvocacia.getcnpjEscritorio()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIndIcon />
                    </InputAdornment>
                  )
                }}
                fullWidth
              />
              <CepSearch />  <br />
              <Box sx={{ justifyContent: "center", width: "100%" }} >
                <Button size="small" variant="outlined" onClick={cadastraEscritorioAdvocacia} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Cadastrar</Button>
                <Button size="small" variant="outlined" onClick={limpaform} sx={{ marginTop: 2, marginLeft: 6, justifyContent: "center", width: "35%" }} color="success" endIcon={<SendIcon />}>Limpar</Button>
              </Box>
            </Stack>
          </FormControl>
        </Box>
        <Box sx={{ justifyContent: "right", width: "30%", position: "relative", flexGrow: 2 }} >
          <CardMedia
            component="img"
            sx={{ width: "100%", height: "100%" }}
            image="https://www.promad.adv.br/blog/wp-content/uploads/2019/07/7-passos-primordiais-para-montar-um-escritorio-de-advocacia.jpg"
            alt="Live from space album cover"
          />
        </Box>
      </Card>
      <Divider variant="middle" sx={{ margin: 2 }} />
      <CreateFolderID />
      <Card variant="outlined">
        <Box sx={{
          margin: "0px",
          p: 0,
          height: '90%',
          width: '100%',
          justifyContent: 'center',
          background: '#fff'
        }} >

          <Stack spacing={1} sx={{ margin: 2, align: "Center" }}>
            <h5>Escritório de Advocacia Cadastrados</h5>
            <React.StrictMode>
              <StyledEngineProvider injectFirst>
                <DataGridEscritorio />
              </StyledEngineProvider>
            </React.StrictMode>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
}
