import React from "react";

let id;
let nomeColegio;
let logoColegio;
let enderecoColegio;
let cepcolegio;
let numerocolegio;
let cidadeColegio;
let estadoColegio;
let bairroColegio;
let complementoColegio;
let cnpjColegio;
let escritorioColegio;
let emailColegio;
let logoGoogleDrive;
let idLogoGoogleDrive;
let pastaColegiosGoogleDrive;
let idPastaColegiosGoogleDrive;
let idRootGoogleDrive;
let pastaInadimplenteGoogleDrive;
let idPastaInadimplenteGoogleDrive;
let agencia;
let conta ; 
let tipoConta;
let banco;
let PIX;

export class Colegio {


  constructor() {

  }

    getid() {
        return this.id;
    }
    setid(value) {
        this.id = value;
    }
    getNomeColegio() {
        return this.nomeColegio;
    }
    setNomeColegio(value) {
        this.nomeColegio = value;
    }
    getlogoColegio() {
        return this.logoColegio;
    }
    setlogoColegio(value) {
        this.logoColegio = value;
    }
    getenderecoColegio() {
        return this.enderecoColegio;
    }
    setenderecoColegio(value) {
        this.enderecoColegio = value;
    }
    getcnpjColegio() {
        return this.cnpjColegio;
    }
    setcnpjColegio(value) {
        this.cnpjColegio = value;
    }


    getEscritorio() {
        return this.escritorioColegio;
    }
    setEscritorio(value) {
        this.escritorioColegio = value;
    }

    getEmailColegio(){
        return this.emailColegio;
    }

    setEmailColegio(value){
        this.emailColegio = value;
    }

    getcepcolegio(){
        return this.cepcolegio;
    }

    setcepcolegio(value){
        this.cepcolegio = value;
    }
    getnumerocolegio(){
        return this.numerocolegio;
    }
    
    getcomplementocolegio() {
        return this.complemento;
    }
    setcomplementocolegio(value) {
        this.complemento = value;
    }

    setnumerocolegio(value){
        this.numerocolegio = value;
    }
    getcidadeColegio(){
        return this.cidadeColegio;
    }

    setcidadeColegio(value){
        this.cidadeColegio = value;
    }
    getestadoColegio(){
        return this.estadoColegio;
    }

    setestadoColegio(value){
        this.estadoColegio = value;
    }
    getbairroColegio(){
        return this.estadoColegio;
    }

    setbairroColegio(value){
        this.estadoColegio = value;
    }
 
//PASTA DO LOGO
getlogoGoogleDrive() {
    return this.logoGoogleDrive;
}
setlogoGoogleDrive(value) {
    this.logoGoogleDrive = value
}
getidLogoGoogleDrive() {
    return this.idLogoGoogleDrive;
}
setidLogoGoogleDrive(value) {
    this.idLogoGoogleDrive = value
}
//PASTA DO COLEGIO
getpastaColegiosGoogleDrive() {
    return this.pastaColegiosGoogleDrive;
}
setpastaColegiosGoogleDrive(value) {
    this.pastaColegiosGoogleDrive = value
}
getidPastaColegiosGoogleDrive() {
    return this.idPastaColegiosGoogleDrive;
}
setidPastaColegiosGoogleDrive(value) {
    this.idPastaColegiosGoogleDrive = value
}
//PASTA ROOT DO ESCRITORIO
getidRootGoogleDrive(value){
    return  this.idRootGoogleDrive;

}
setidRootGoogleDrive(value){
    this.idRootGoogleDrive = value

}
 //PASTA DO INADIMPLENTE
 getpastaInadimplenteGoogleDrive() {
    return this.pastaInadimplenteGoogleDrive;
}
setpastaInadimplenteGoogleDrive(value) {
    this.pastaInadimplenteGoogleDrive = value
}
getidPastaInadimplenteGoogleDrive() {
    return this.idPastaInadimplenteGoogleDrive;
}
setidPastaInadimplenteGoogleDrive(value) {
    this.idPastaInadimplenteGoogleDrive = value
}
//let agencia;
getidPastaInadimplenteGoogleDrive() {
    return this.idPastaInadimplenteGoogleDrive;
}
setidPastaInadimplenteGoogleDrive(value) {
    this.idPastaInadimplenteGoogleDrive = value
}
//let conta ; 
getconta() {
    return this.conta;
}
setconta(value) {
    this.conta = value
}
//let tipoConta;
gettipoConta() {
    return this.tipoConta;
}
settipoConta(value) {
    this.tipoConta = value
}
//let banco;
getbanco() {
    return this.banco;
}
setbanco(value) {
    this.banco = value
}
//let PIX;
getPIX() {
    return this.PIX;
}
setPIX(value) {
    this.PIX = value
}
//let agencia;
getagencia() {
    return this.agencia;
}
setagencia(value) {
    this.agencia = value
}


}