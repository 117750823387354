import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import Debito from '../../DTO/Debito';
import Requests from "../../api/requests";
import SortIcon from '@mui/icons-material/Sort';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { API_URL } from '../../constants';



import {
  GridRowModes,
  DataGrid,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { parseConfigFileTextToJson } from 'typescript';



export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}

const roles = ['Market', 'Finance', 'Development'];
const randomRole = () => {
  return randomArrayItem(roles);
};

let debito = new Debito;

const initialRows = [

  {
    id: 1,
    ordem: "1",
    descricao: "Exemplo de Mensalidade",
    dataVencimento: "10/05/2022",
    valor: 600,
    valorDebito: 800
  },

];

let indiceMes, indiceAtual;
function setIndiceMes(value) {
  indiceMes = value;
}
function getIndiceMes() {
  return indiceMes;
}

function setIndiceAtual(value) {
  indiceAtual = value;
}
function getIndiceAtual() {
  return indiceAtual;
}



function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, name: '', age: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Adicione mais um item
      </Button>
    </GridToolbarContainer>
  );
}

export default function DataGridConsultaDebito() {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
      alert("aqui");
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    alert("editei");

  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    alert(id);

  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    alert("deletei");

  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };

    let _ano, _mes, _anoAtual, _mesAtual, _subtotal, total, _diaAtual, _mesAtualIndice;

    //calcula datas 
    const today = new Date();
    _ano = ((updatedRow.dataVencimento).substring(6, 10)); // parseInt("1070");
    _mes = ((updatedRow.dataVencimento).substring(3, 5));// parseInt("10");
    _anoAtual = today.getFullYear(); // parseInt("2023");
    _mesAtual = parseInt(String(today.getMonth() + 1).padStart(2, '0')); //parseInt("07");
    _diaAtual = parseInt(String(today.getDate()).padStart(2, '0'));
    _mesAtualIndice = _diaAtual < 16 ? _mesAtual - 1 : _mesAtual;
    _mesAtualIndice = String(_mesAtualIndice < 10 ? "0" + _mesAtualIndice : _mesAtualIndice);

    console.log("Dia Atual");
    console.log(_diaAtual);
    console.log("Mes AtualIndice");
    console.log(_mesAtualIndice);
    console.log("Mes Atual");
    console.log(_mesAtual);
    console.log("Ano Atual");
    console.log(_anoAtual);
    console.log("Rota");
    console.log(API_URL + "indices/" + _ano + "/" + _mes);
    console.log(API_URL + "indices/" + _anoAtual + "/" + _mesAtualIndice);

    //Calcula Indice

    fetch(API_URL + "indices/" + _ano + "/" + _mes, {
      method: 'GET',
      mode: 'cors'
    })
      .then((response) => response.json())
      .then((json) => setIndiceMes(JSON.stringify(json)))

    fetch(API_URL + "indices/" + _anoAtual + "/" + _mesAtualIndice, {
      method: 'GET',
      mode: 'cors'
    })
      .then((response) => response.json())
      .then((json) => setIndiceAtual(JSON.stringify(json)))



    setTimeout(() => {
      _ano = parseInt(_ano);
      _mes = parseInt(_mes);// parseInt("10");
      _anoAtual = parseInt(_anoAtual); // parseInt("2023");
      _mesAtual = parseInt(_mes); //parseInt("07");

      let _indiceMes = JSON.parse(getIndiceMes());
      let _indiceAtual = JSON.parse(getIndiceAtual());
      let _valor = parseFloat(updatedRow.valor);
      let _juros;



      // _mesAtual= 7;
      //atualiza indices que vem do banco de dados com a data atual e passada
      updatedRow.indiceMes = _indiceMes.indice;
      updatedRow.indiceAtual = _indiceAtual.indice;
      _indiceMes = (_indiceMes.indice).toString().replace(",", ".");
      _indiceMes = parseFloat(_indiceMes);
      _indiceAtual = (_indiceAtual.indice).toString().replace(",", ".");
      _indiceAtual = parseFloat(_indiceAtual);

      //calcula o subtotal
      _subtotal = parseFloat((_valor / _indiceMes) * _indiceAtual);
      updatedRow.subtotal = _subtotal;

      //Calcula Juros
      //Calcula quantidade de meses entre anos 
      _juros = (parseFloat(_anoAtual) - parseFloat(_ano)) * 12;
      //Calcula quantidade de meses e adiciona com os anos 
      _juros = (_juros + (_mesAtual) - (_mes));
      console.log('Quantidade de meses');
      console.log(_juros);
      //transforma juros em porcentagem 
      _juros = parseFloat((_juros / 100));
      console.log('Calcula Porcentagem encima');
      console.log(_juros);
      //calcula juros encima do subtotal
      _juros = (_juros * _subtotal);
      console.log('Multiplica juros por subtotal');
      console.log(_juros);
      //Atualiza juros
      updatedRow.juros = parseFloat(_juros.toFixed(2));

      //total
      total = (_subtotal + _juros)
      updatedRow.total = parseFloat(total.toFixed(2));
    }

      , 100);




    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));


    console.log(updatedRow);

    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };
  const currencyFormatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const columns = [
    {
      field: 'ordem',
      headerName: 'Ordem',
      width: 80,
      editable: true
    },
    {
      field: 'descricao',
      headerName: 'Título',
      type: 'string',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      valueOptions: ['Mensalidade', 'Uniforme', 'Vendas', 'Atividade Extracurricular', 'Papelaria', 'Material Didático'],

    },
    {
      field: 'dataVencimento',
      headerName: 'Data de Vencimento',
      type: 'String',
      width: 150,
      editable: true,
    },
    {
      field: 'valor',
      headerName: 'Valor Primitivo',
      type: 'number',
      width: 100,
      editable: true,
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },
    },

    {
      field: 'indiceMes',
      headerName: 'Indice do Mês',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',

    },
    {
      field: 'indiceAtual',
      headerName: 'Indice Atual',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',

    },
    {
      field: 'subtotal',
      headerName: 'SubTotal',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },

    },
    {
      field: 'juros',
      headerName: 'Juros',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },

    },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      editable: false,
      type: 'number',
      headerClassName: 'super-app-theme--header',
      valueFormatter: ({ value }) => {
        if (!value) {
          return value;
        }
        return currencyFormatter.format(value);
      },


    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 400,
        width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
        '& .super-app-theme--header': {
          backgroundColor: '#F9CEC4',
        },

      }}
    >
      <DataGridPremium
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: GridToolbar,
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        initialState={{
          aggregation: {
            model: {
              subtotal: 'sum',
              valor: 'sum',
              juros: 'sum',
              total: 'sum'

            },
          },
        }}
      />
    </Box>
  );


}