import { DataGrid } from '@mui/x-data-grid';
import Requests from "../../api/requests";
import React, { useState, useEffect } from 'react'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';



function handleSaveClick(){
  console.log("Ola");
}

let req = new Requests();

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid(){
  return <ExpandLessIcon className="icon" />;

}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}



const columns = [

  { 
    field: 'NomeSocio',
   headerName: 'Nome do Socio',
    width: 150,
    editable: true
  },
  {
    field: 'Colegio',
    headerName: 'Nome do Colégio',
    width: 150,
    editable: true,
  },
/*  {
    field: 'whatsapp',
    headerName: 'Whatsapp',
    width: 100,
    editable: true,
  },
  {
    field: 'telefone',
    headerName: 'Telefone',
    width: 100,
    editable: true,
  },*/
  {
    field: 'nacionalidade',
    headerName: 'Nacionalidade',
    width: 100,
    editable: true,
  },
  {
    field: 'rg',
    headerName: 'RG',
    width: 100,
    editable: true,
  },
  {
    field: 'cpf',
    headerName: 'CPF',
    width: 100,
    editable: true,
  }
 /* {
    field: 'email',
    headerName: 'Email',
    width: 100,
    editable: true,
  },
  
  {
    field: 'endereco',
    headerName: 'Endereço',
    width: 150,
    editable: true,
  },
  {
    field: 'numero',
    headerName: 'numero',
    width: 100,
    editable: true,
  },
  ,
  {
    field: 'bairro',
    headerName: 'Bairro',
    width: 100,
    editable: true,
  },
  {
    field: 'cep',
    headerName: 'CEP',
    width: 100,
    editable: true,
  }
  ,
  {
    field: 'cidade',
    headerName: 'Cidade',
    width: 100,
    editable: true,
  },
  {
    field: 'estado',
    headerName: 'Estado',
    width: 100,
    editable: true,
  }*/
];
 
 

const DataGridCadastraSocioColegio = () => {
  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "socioColegios", {
      method: 'GET',
      allowedHeaders: 'Content-Type'
    })
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])
  console.log("tableData");
  console.log(tableData);

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        checkboxSelection
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  )
}

export default DataGridCadastraSocioColegio

 




 
