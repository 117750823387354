import React, { useEffect } from 'react'
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DataGrid, GridEditInputCell } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { createContext } from 'react';
import CrudDebitoGestao from '../../Service/CrudDebitoGestao';
import Procuracao from '../googleApi/CriaContratos/mesclaDocumentosProcuracao';
import PlanilhaItem from '../googleApi/CriaContratos/mesclaDocumentosPlanilhaItem';
import Notificacao from '../googleApi/CriaContratos/mesclaDocumentosNotificacao';
import Envelope from '../googleApi/CriaContratos/mesclaDocumentosEnvelope';
import EtiquetaDestinatario from '../googleApi/CriaContratos/mesclaDocumentosEtiqueta';
import Monitoria from '../googleApi/CriaContratos/mesclaDocumentosMonitoria';
import Envelope02 from '../googleApi/CriaContratos/mesclaDocumentosEnvelope02';
import MinutaAcordo from '../googleApi/CriaContratos/mesclaDocumentosMinutaAcordo';
import Cobranca from '../googleApi/CriaContratos/mesclaDocumentosCobranca';
import Cumprimento from '../googleApi/CriaContratos/mesclaDocumentosCumprimentoSentenca';
import EtiquetaLote from '../googleApi/CriaContratos/mesclaDocumentosEtiquetaLote';
import RelatorioLote from '../googleApi/CriaContratos/mesclaDocumentosRelatorioLote';
import GeraPDF from '../googleApi/CriaContratos/mesclaDocumentosPDF';
import CrudReceberAR from '../../Service/CrudReceberAR';
import RelatorioFinanceiroLot from '../googleApi/CriaContratos/mesclaDocumentosRelatorioFinaceiroLote';

import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';

import { API_URL } from '../../constants';
import Requests from "../../api/requests";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'; // Ícone do Google Drive
import { IconButton } from '@mui/material'; // Botão de ícone do Material-UI


//Modal
//https://backefront.com.br/como-fazer-upload-arquivo-react/#:~:text=Quando%20desenvolvemos%20formul%C3%A1rios%20em%20nossos,o%20que%20tiver%20que%20subir!


import {
  GridRowModes,
  GridToolbar,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from '@mui/x-data-grid-generator';
import { parseConfigFileTextToJson } from 'typescript';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { data } from '../impressaoDocumentos/data';
import { Impressao } from '../impressaoDocumentos/impressao';
import { ImpressaoNotificacao } from '../impressaoDocumentos/notificacao';
import Modal from '@mui/material/Modal';


pdfMake.vfs = pdfFonts.pdfMake.vfs;


// Botão de deletar
export function DeleteButton({ id, handleDelete }) {
  return (
    <Button
      variant="standard"
      color="secondary"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(id)}
    >
      
    </Button>
  );
}

/*
function gerarRelatoriosEtiquetas(tableData){
  const etiqueta = new EtiquetaLote();
  const relatorio = new RelatorioLote();
 console.log(tableData);
  if (tableData.length > 0) {
    let params= new Array();
    for (let i = 0; i < tableData.length; i++) {
      params.push({
        row: {
          nomeResponsavelFinanceiro: tableData[i].nomeResponsavelFinanceiro,
          colegio: tableData[i].colegio,
          aluno: tableData[i].aluno,
          urlRootInadimplente: tableData[i].urlRootInadimplente
        }
      })
    }
    console.log(params);    
      etiqueta.mergeDataInDocument(params, "");
      relatorio.mergeDataInDocument(params, "");    
  }
} */


function gerarRelatoriosEtiquetas(tableData) {
  const etiqueta = new EtiquetaLote();
  const relatorio = new RelatorioLote();
  const relatorioFinanceiro = new RelatorioFinanceiroLot();
  
  console.log(tableData);

  if (tableData.length > 0) {
    // Agrupar os parâmetros por colegio
    const paramsPorColegio = {};

    tableData.forEach(item => {
      const colegio = item.colegio;

      if (!paramsPorColegio[colegio]) {
        paramsPorColegio[colegio] = [];
      }

      paramsPorColegio[colegio].push({
        row: {
          nomeResponsavelFinanceiro: item.nomeResponsavelFinanceiro,
          colegio: item.colegio,
          aluno: item.aluno,
          urlRootInadimplente: item.urlRootInadimplente
        }
      });
    });

    // Iterar sobre cada grupo de parâmetros por colegio
    for (const colegio in paramsPorColegio) {
      const params = paramsPorColegio[colegio];
      
      console.log(`Gerando relatório para o colégio: ${colegio}`);
      
      // Chamar mergeDataInDocument para o relatório
      relatorio.mergeDataInDocument(params, "", colegio);
      etiqueta.mergeDataInDocument(params, "", colegio);
      relatorioFinanceiro.mergeDataInDocument(params, "", colegio);

    }


  }
}



/*


async function exportPdf(fileId) {
  const contrato = new GeraPDF();
  contrato.mergeDataInDocument(fileId)
} */

function gerarTodosDocumentos(tableData) {

  console.log(tableData);
  let i = 0;
  let alunos = '';

  function processarProximoItem() {
    // Verifica se ainda há elementos a serem processados
    if (i < tableData.length) {
      let item = tableData[i];
      console.log("Processando item " + i + ": ", item);

  
      if (item.urlRootInadimplente != null && item.urlRootInadimplente != "" && item.urlRootInadimplente.length > 0) {
        let params = {
          row: {
            nomeResponsavelFinanceiro: item.nomeResponsavelFinanceiro,
            colegio: item.colegio,
            aluno: item.aluno,
            urlRootInadimplente: item.urlRootInadimplente
          }
        };
        

        handleImprimirProcuracao(params, "FECHADO");
        handleImprimirEnvelope(params, "FECHADO");
      //  handleImprimirEnvelope02(params, "FECHADO");
        handleImprimirMonitoria(params, "FECHADO");
        handleImprimirNotificacao(params, "FECHADO");
        handleImprimirCobranca(params, "FECHADO");
        handleImprimirMinutaAcordo(params, "FECHADO");
        handleImprimirCumprimento(params, "FECHADO");

       // const userResponse = window.prompt("Processando item " + i + ": " + item + "Press 'OK' to continue processing the next item, or 'Cancel' to stop.");
       alunos +=( "Aluno: "+ i + " " + item.aluno + "\n" );
         

       
       if(tableData.length-1 == i && i>0){
        alert("Todos documentos foram processados\n" + alunos);

       }
      } else {
        alert("O inadimplente: " + item.aluno + " não foi cadastrado corretamente, está sem a pasta cadastrada");
      }

      i++; // Passa para o próximo item

      // Chama a função novamente após 15 segundos
      setTimeout(processarProximoItem, 15000);
    }


  }

  // Inicia o processamento do primeiro item
  processarProximoItem();
  alert("Foram gerados todos os documentos para os alunos: </br>"+ alunos);

}





function documentosGerados(tableData) {
  let req = new Requests();
  let crudReceberAR = new CrudReceberAR();
    req.requestPUTparamDocumentosGeradosAtualizar('contrato');
    crudReceberAR.cadastraReceberARAPI(tableData);
    window.location.reload();
  }

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

//teste 
const classeImpressao = new Impressao(data);
const documento = classeImpressao.PreparaDocumento();
const crudGestaoDebito = new CrudDebitoGestao;
/*
//notificacao
const classeImpressaoNotificacao = new ImpressaoNotificacao(data);
const documentoNotificacaoPF = classeImpressaoNotificacao.PreparaDocumento();
*/

// const rows =  crudGestaoDebito.buscaGestaoDebito();


const StyledBox = styled(Box)(({ theme }) => ({
  height: 400,
  width: '100%',
  '& .MuiDataGrid-cell--editable': {
    backgroundColor: theme.palette.mode === 'dark' ? '#376331' : 'rgb(217 243 190)',
    '& .MuiInputBase-root': {
      height: '100%',
    },
  },
  '& .Mui-error': {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
    color: theme.palette.mode === 'dark' ? '#ff4343' : '#750f0f',
  },
}));

let promiseTimeout;
function validateName(username) {
  /*const existingUsers = rows.map((row) => row.name.toLowerCase());

  return new Promise((resolve) => {
    promiseTimeout = setTimeout(() => {
      const exists = existingUsers.includes(username.toLowerCase());
      resolve(exists ? `${username} is already taken.` : null);
    }, Math.random() * 500 + 100); // simulate network latency
  });*/
}

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

function NameEditInputCell(props) {
  const { error } = props;

  return (
    <StyledTooltip open={!!error} title={error}>
      <GridEditInputCell {...props} />
    </StyledTooltip>
  );
}

function renderEditName(params) {
  //console.log("ACOLA");
  //console.log(params.row);
  //selectTableData.push(params.row);
  //console.log(selectTableData);
  return <NameEditInputCell {...params} />;
}



export default function EnviarNotificacao() {
  const preProcessEditCellProps = async (params) => {
    const errorMessage = await validateName(params.props.value.toString());
   // alert('aqui');
   // console.log()
    return { ...params.props, error: errorMessage };
  };

  const [cardFile, setCardFile] = useState();
  const handleUploadFile = (e: any) => console.log(e.target.files[0]);

  const [procuracaoIco, setPocuracaoIco] = React.useState("green");

 // Função de deletar dentro do componente
 const handleDelete = (id) => {
  fetch(`${API_URL}debitosGestao_notificacao/${id}`, {
    method: 'DELETE',
  })
  .then(() => {
    // Remover o registro localmente após a exclusão
    setTableData((prevData) => prevData.filter((row) => row.id !== id));
  })
  .catch((error) => console.error('Erro ao deletar:', error));
};


  const columns = [ 
    {
      field: 'deletar',
      headerName: 'Deletar',
      width: 80,
      renderCell: (params) => (
        <DeleteButton id={params.row.id} handleDelete={handleDelete} />
      ),
    },
    {
      field: 'Contratos',
      headerName: 'Gerar Todos Contratos',
      width: 80,
      renderCell: (params) => {
        // Criar um array com o objeto da linha selecionada
        const tableDataRowArray = [params.row]; // Apenas a linha selecionada como um array
  
        return (
          <IconButton
            onClick={() => gerarTodosDocumentos(tableDataRowArray)} // Passar o array com a linha selecionada
            color="primary"
          >
            <CloudUploadIcon />
          </IconButton>
        );
      },
    },
    {
      field: 'urlRootInadimplente',
      headerName: 'Pasta Google Drive',
      type: 'string',
      width: 100,
      renderCell: (params) => {
        const url = params.value; // Obter o valor da URL
  
        // Verifica se a URL da pasta está disponível
        if (url) {
          return (
            <IconButton
              onClick={() => window.open(`https://drive.google.com/drive/folders/${url}`, '_blank')}
              color="primary"
            >
              <DriveFolderUploadIcon /> {/* Ícone do Google Drive */}
            </IconButton>
          );
        } else {
          return (
            <Typography color="textSecondary" variant="body2">
              Indisponível
            </Typography>
          );
        }
      },
    },
    {
      field: 'colegio',
      headerName: 'Colégio',
      width: 250,
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditName,

    },
    {
      field: 'nomeResponsavelFinanceiro',
      headerName: 'Responsavel Financeiro',
      width: 250,
      editable: false,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },
    {
      field: 'aluno',
      headerName: 'Aluno',
      width: 250,
      editable: false,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },
  /*  {
      field: 'dataEnvio',
      headerName: 'dataEnvio',
      type: 'string',
      width: 110,
      editable: true,
      preProcessEditCellProps,
      renderEditCell: renderEditName,
    },*/

 
 
  /*  {
      field: 'salvar',
      type: 'actions',
      headerName: 'Salvar',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<SaveIcon />}
            label="Salvar"
            className="textPrimary"
            onClick={() =>salvar(params)}
            color="inherit"

          />
        ];
      },
    },*/

  /*  {
      field: 'planilha',
      type: 'actions',
      headerName: 'Planilha',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          //  <MesclaDocumentos/>
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            sx={{ color: procuracaoIco }}
            label="Imprimir Procuração"
            className="textPrimary"
            onClick={() => handleImprimirPlanilha(params)
            }
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
            color="inherit"
          />
        ];
      },
    },*/
   {
      field: 'planilha',
      type: 'actions',
      headerName: 'Planilha',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          //  <MesclaDocumentos/>
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            sx={{ color: procuracaoIco }}
            label="Imprimir Procuração"
            className="textPrimary"
            onClick={() => handleImprimirPlanilha(params)
            }
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
            color="inherit"
          />
        ];
      },
    },
    {
      field: 'procuracao',
      type: 'actions',
      headerName: 'Procuração',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          //  <MesclaDocumentos/>
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            sx={{ color: procuracaoIco }}
            label="Imprimir Procuração"
            className="textPrimary"
            onClick={() => handleImprimirProcuracao(params)
            }
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}
            color="inherit"
          />
        ];
      },
    },
    {
      field: 'notificacao',
      type: 'actions',
      headerName: 'Notificacao',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Procuração"
            className="textPrimary"
            onClick={() => handleImprimirNotificacao(params)}

            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}

            color="inherit"
          />
        ];
      },
    },
    {
      field: 'etiquetaAR',
      type: 'actions',
      headerName: 'EtiquetaAR',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Procuração"
            className="textPrimary"
            onClick={() => handleImprimiEtiqueta(params)}

            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}

            color="inherit"
          />
        ];
      },
    },
    {
      field: 'Cobranca',
      type: 'actions',
      headerName: 'Cobranca',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Procuração"
            className="textPrimary"
            onClick={() =>handleImprimirCobranca(params)} //05
             //06}

            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}

            color="inherit"
          />
        ];
      },
    },
    {
      field: 'MinutaAcordo',
      type: 'actions',
      headerName: 'Minuta cordo',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Procuração"
            className="textPrimary"
            onClick={() => handleImprimirMinutaAcordo(params)}

            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}

            color="inherit"
          />
        ];
      },
    },
    {
      field: 'envelope',
      type: 'actions',
      headerName: 'Envelope',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Envelope"
            className="textPrimary"
            onClick={() => handleImprimirEnvelope(params)}
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}                 
            color="inherit"
          />
        ];
      },
    },
    {
      field: 'cumprimento',
      type: 'actions',
      headerName: 'Cumprimento',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Cumprimento"
            className="textPrimary"
            onClick={() => handleImprimirCumprimento(params)}
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}                 
            color="inherit"
          />
        ];
      },
    },
    {
      field: 'envelope02',
      type: 'actions',
      headerName: 'Envelope 02',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Envelope 02"
            className="textPrimary"
            onClick={() => handleImprimirEnvelope02(params)}
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}                 
            color="inherit"
          />
        ];
      },
    },
    {
      field: 'monitoria',
      type: 'actions',
      headerName: 'Monitoria',
      width: 80,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<LocalPrintshopIcon />}
            label="Monitoria"
            className="textPrimary"
            onClick={() => handleImprimirMonitoria(params)}
            // onClick={() => pdfMake.createPdf(documento).open({}, window.open('', '_blank'))}                 
            color="inherit"
          />
        ];
      },
    },

  
  ];

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  React.useEffect(() => {
    return () => {
      clearTimeout(promiseTimeout);
    };
  }, []);


  const [tableData, setTableData] = useState([])
  useEffect(() => {
    fetch(API_URL + "debitosGestao")
      .then((data) => data.json())
      .then((data) => setTableData(data))

  }, [])

 

    // Função para lidar com a mudança nas linhas selecionadas
    const handleSelectionModelChange = (selectedRows) => {
      // selectedRows contém os índices das linhas selecionadas
      // Use esses índices para obter os dados das linhas selecionadas
      console.log(selectedRows);
     // const selectedData = selectedRows.map((index) => tableData[index]);
      // Atualize a variável tableData com os dados das linhas selecionadas
     // setTableData(selectedData);
    };
 
  
  return (
    <StyledBox>

      <Stack spacing={2} direction="row">
        <Button variant="contained" color="success" onClick={() => gerarTodosDocumentos(tableData)} >Gerar Documentos</Button>
        <Button variant="contained" color="success" onClick={() => documentosGerados(tableData)} >Atualizar Docs Gerados</Button>
        <Button variant="contained" color="success" onClick={() => gerarRelatoriosEtiquetas(tableData)} >Relatório e Etiquetas</Button>
        <Button variant="contained" color="success" onClick={() => window.open("https://drive.google.com/drive/folders/1q8bqAKT2rWWd4YS8wox5sxeJKkOUGfwM", '_blank')}  >Google Drive</Button>      
      </Stack>

      
      <DataGrid
         sx ={{height:'900px'}}
        rows={tableData}
        columns={columns} 
        pageSize={8}
       // onSelectionModelChange={alert("a")}
       // selectionModel={alert("a")}
      // rowSelectionModel={alert("a")}
     //  isCellEditable={(params) => params.row.id === 5}
       //getSelectedRows={alert("a")}
       // checkboxSelection
      />
    </StyledBox>
  );
}

let selectTableData = new Array;

const salvar= (params, open) => {
  console.log(params.row);

}

const handleImprimirProcuracao = (params, open) => {
  const contrato = new Procuracao();
  console.log("CHAMEI PROCURACAO");
  console.log(params.row.urlRootInadimplente);

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;
  const urlRootInadimplente = params.row.urlRootInadimplente;

  console.log(colegio, inadimplente, aluno);
  contrato.mergeDataInDocument(urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};


const handleImprimirPlanilha = (params, open) => {
  const planilha = new PlanilhaItem(); 
  console.log("CHAMEI PLANILHA");
  console.log(params.row.urlRootInadimplente);

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;
  const urlRootInadimplente = params.row.urlRootInadimplente;

  console.log(colegio, inadimplente, aluno);
  planilha.mergeDataInDocument(urlRootInadimplente, colegio, inadimplente, aluno, "OPEN");


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};


const handleImprimirCobranca = (params, open) => {
  const contrato = new Cobranca;
  console.log("CHAMEI COBRANCA");
  console.log(params.row.urlRootInadimplente);

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;
  const urlRootInadimplente = params.row.urlRootInadimplente;

  console.log(colegio, inadimplente, aluno);
  contrato.mergeDataInDocument(urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};



const handleImprimirCumprimento = (params, open) => {
  const contrato = new Cumprimento;
  console.log("CHAMEI Cumprimento");
  console.log(params.row.urlRootInadimplente);

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;
  const urlRootInadimplente = params.row.urlRootInadimplente;

  console.log(colegio, inadimplente, aluno);
  contrato.mergeDataInDocument(urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};

const handleImprimirEnvelope = (params, open) => {
  const contrato = new Envelope();

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;

  contrato.mergeDataInDocument(params.row.urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};

const handleImprimirEnvelope02 = (params, open) => {
  const contrato = new Envelope02();

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;

  contrato.mergeDataInDocument(params.row.urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};

const handleImprimirNotificacao = (params, open) => {
  const contrato = new Notificacao();

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;

  contrato.mergeDataInDocument(params.row.urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};


const handleImprimirMinutaAcordo = (params, open) => {
  const contrato = new MinutaAcordo();

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;

  contrato.mergeDataInDocument(params.row.urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};

const handleImprimiEtiqueta = (params, open) => {
  const contrato = new EtiquetaDestinatario();

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;



  contrato.mergeDataInDocument(params.row.urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};


const handleImprimirMonitoria = (params, open) => {
  const contrato = new Monitoria();

  // Aqui, você pode acessar os dados da linha clicada usando params.row
  console.log(params.row);
  const inadimplente = params.row.nomeResponsavelFinanceiro;
  const colegio = params.row.colegio;
  const aluno = params.row.aluno;

  contrato.mergeDataInDocument(params.row.urlRootInadimplente, colegio, inadimplente, aluno, open);


  // Aqui você pode chamar a função para imprimir a procuração ou fazer qualquer outra coisa que desejar
  // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
};




