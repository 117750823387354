import './App.css';
  import AppBarMenu from './components/AppBarMenu';
//import PDFGenerator from './components/googleApi/PDFGenerator';
//import ExportToHTML from './components/googleApi/geraHTML';


import logo from './logo.svg';
import Router from './components/Rotas/routes';
import React, { useEffect } from 'react';
//import LoginButton from "./components/googleApi/login";
//import LogoutButton from "./components/googleApi/logout";
//import CreateFolder from "./components/googleApi/createFolder";
import { gapi } from 'gapi-script';
//import CreateDocumentFromTemplate from "./components/googleApi/createFromTemplate";
//import ManageDocuments from "./components/googleApi/manageDocuments";
//import MesclaDocumentos from "./components/googleApi/mesclaDocumentos";
//import MesclaSheets from './components/googleApi/mesclaDocumentsSheetsInadimplente';
//import criaPlanilhaSheets from './components/googleApi/criaPlanilhaSheets';

const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES_DOCS = 'https://www.googleapis.com/auth/documents'; // QUANDO CRIA O DOCUMENTO
const SCOPES = 'https://www.googleapis.com/auth/drive'; // QUANDO VOU GERAR A CÓPIA e PRA CRIAR UMA PASTA


function App() {
 useEffect(() => {
    gapi.load('client:auth2', () => {
      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        scope: SCOPES,
      }).then(() => {
        const authInstance = gapi.auth2.getAuthInstance();
        if (!authInstance.isSignedIn.get()) {
          authInstance.signIn(); // Isso deve abrir a janela de login do Google
        }
      });
    });
  }, []);



  return (
    <div>
         <AppBarMenu />

         <Router />
       
    </div>
  );
}

export default App;
