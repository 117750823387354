import React from "react";
import Indice from "../DTO/Indice";
import Requests from "../api/requests";


let indice = new Indice();
let req = new Requests();

export default class CrudAdvogado {

 

    constructor(value) {
        this.indice = value;
   }



    cadastraIndiceAPI(event){
    let req = new Requests();
    
    indice = event;
    const { v4: uuidv4 } = require('uuid');
  
    req.requestPOSTparam(JSON.stringify({
      id: uuidv4(),
      ano: indice.getano(),
      mes: indice.getmes(), 
      mes_ano: indice.getmes() + "/" + indice.getano(),
      indice: indice.getindice(), 
    }), 'indice');
  

  }
  
}