import React from "react";



let id;
let nomeEscritorio;
let advogados;
let logoEscritorio;
let enderecoEscritorio;
let cnpj;
let emailEscritorio;
let numero;
let cidade;
let estado;
let complemento;
let cep;
let pastaEscritorioGoogleDrive;
let idPastaEscritorioGoogleDrive;
let logoGoogleDrive;
let idLogoGoogleDrive;
let pastaColegiosGoogleDrive;
let idPastaColegiosGoogleDrive;

export default class EscritorioAdvocacia {

    constructor() { }

    getId() {
        return this.id;
    }

    setId(value) {
        this.id = value;
    }

    getNomeEscritorio() {
        return this.nomeEscritorio;
    }

    setNomeEscritorio(value) {
        this.nomeEscritorio = value;
    }

    getAdvogados() {
        return this.advogados;
    }

    setAdvogados(value) {
        this.advogados = value;
    }


    getEnderecoEscritorio() {
        return this.enderecoEscritorio;
    }


    setEnderecoEscritorio(value) {
        this.enderecoEscritorio = value;
    }

    getLogoEscritorio() {
        return this.logoEscritorio;
    }

    setLogoEscritorio(value) {
        this.logoEscritorio = value;
    }

    getcnpjEscritorio() {
        return this.cnpj;
    }
    setcnpjEscritorio(value) {
        this.cnpj = value;

    }

    // email;
    getemailEscritorio() {
        return this.emailEscritorio;
    }
    setemailEscritorio(value) {
        this.emailEscritorio = value;
    }


    getnumero() {
        return this.numero;
    }
    setnumero(value) {
        this.numero = value
    }

    getcidade() {
        return this.cidade;
    }
    setcidade(value) {
        this.cidade = value
    }
    getestado() {
        return this.estado;
    }
    setestado(value) {
        this.estado = value
    }
    getcep() {
        return this.cep;
    }
    setcep(value) {
        this.cep = value
    }
    getcomplemento() {
        return this.complemento;
    }
    setcomplemento(value) {
        this.complemento = value
    }
    getbairro() {
        return this.bairro;
    }
    setbairro(value) {
        this.bairro = value
    }

    //PASTA DO ESCRITORIO
    getpastaEscritorioGoogleDrive() {
        return this.pastaEscritorioGoogleDrive;
    }
    setpastaEscritorioGoogleDrive(value) {
        this.pastaEscritorioGoogleDrive = value
    }
    getidPastaEscritorioGoogleDrive() {
        return this.idPastaEscritorioGoogleDrive;
    }
    setidPastaEscritorioGoogleDrive(value) {
        this.idPastaEscritorioGoogleDrive = value
    }
    //PASTA DO LOGO
    getlogoGoogleDrive() {
        return this.logoGoogleDrive;
    }
    setlogoGoogleDrive(value) {
        this.logoGoogleDrive = value
    }
    getidLogoGoogleDrive() {
        return this.idLogoGoogleDrive;
    }
    setidLogoGoogleDrive(value) {
        this.idLogoGoogleDrive = value
    }
    //PASTA DO COLEGIO
    getpastaColegiosGoogleDrive() {
        return this.pastaColegiosGoogleDrive;
    }
    setpastaColegiosGoogleDrive(value) {
        this.pastaColegiosGoogleDrive = value
    }
    getidPastaColegiosGoogleDrive() {
        return this.idPastaColegiosGoogleDrive;
    }
    setidPastaColegiosGoogleDrive(value) {
        this.idPastaColegiosGoogleDrive = value
    }


}
