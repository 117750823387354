import { DataGrid } from '@mui/x-data-grid';
import Requests from "../../api/requests";
import React, { useState, useEffect } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SortIcon from '@mui/icons-material/Sort';
import { API_URL } from '../../constants';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; // Ícone de planilha

// Botão de deletar
export function DeleteButton({ id, handleDelete }) {
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<DeleteIcon />}
      onClick={() => handleDelete(id)}
    >
      {/* Optional: Add text here if desired */}
    </Button>
  );
}

function handleSaveClick() {
  console.log("Ola");
}

let req = new Requests();

export function SortedDescendingIcon() {
  return <ExpandMoreIcon className="icon" />;
}

export function SortedAscendingIcon() {
  return <ExpandLessIcon className="icon" />;
}

export function testegrid() {
  return <ExpandLessIcon className="icon" />;
}

export function UnsortedIcon() {
  return <SortIcon className="icon" />;
}

const DataGridDebitoLista = () => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetch(API_URL + "inadimplentes")
      .then((data) => data.json())
      .then((data) => setTableData(data));
  }, []);

  const columns = [
    {
      field: 'colegio',
      headerName: 'Nome do Colegio',
      width: 150,
      editable: true
    },
    {
      field: 'nomeResponsavelFinanceiro',
      headerName: 'Responsavel Financeiro',
      width: 150,
      editable: false,
    },
    {
      field: 'nomeAluno',
      headerName: 'Nome do Aluno',
      width: 150,
      editable: false,
    },
    {
      field: 'idPlanilha',
      headerName: 'Planilha',
      width: 150,
      renderCell: (params) => {
        const hasValue = params.value; // Check if there's a value

        return (
          <Button
            variant="contained"
            color="primary"
            startIcon={<InsertDriveFileIcon />} // Ícone de planilha
            onClick={hasValue ? () => window.open(`https://docs.google.com/spreadsheets/d/${params.value}`, '_blank') : undefined}
            disabled={!hasValue} // Disable button if there's no value
          >
            {hasValue ? 'Open' : 'Unavailable'} {/* Optional: Text based on availability */}
          </Button>
        );
      },
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Excluir',
      width: 80,
      renderCell: (params) => {
        const hasValue = params.row.idPlanilha; // Check if there's a planilha ID
        return hasValue ? (
          <DeleteButton id={params.row.idPlanilha} handleDelete={handleDelete} />
        ) : null; // Do not render button if no planilha ID
      },
    },
  ];

  // Função de deletar dentro do componente
  const handleDelete = (idPlanilha) => {
    fetch(`${API_URL}debito/${idPlanilha}`, {
      method: 'DELETE',
    })
      .then(() => {
        // Remover o registro localmente após a exclusão
        setTableData((prevData) => prevData.filter((row) => row.idPlanilha !== idPlanilha));
      })
      .catch((error) => console.error('Erro ao deletar:', error));
  };

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={tableData}
        columns={columns}
        pageSize={8}
        disableRowSelectionOnClick
        slots={{
          columnSortedDescendingIcon: SortedDescendingIcon,
          columnSortedAscendingIcon: SortedAscendingIcon,
          columnUnsortedIcon: UnsortedIcon,
          testegrid: testegrid
        }}
      />
    </div>
  );
}

export default DataGridDebitoLista;
