import React from "react";

import { API_URL } from '../../src/constants';

class requests extends React.Component {


  setaState(value) {
    this.valor = value;
    // console.log("DENTRO");
    // console.log(this.valor);

  }

  getValor() {

    //console.log("GET VALOR");
    //console.log(this.valor);
    return this.valor;
  }

  /*requestPOSTparam(jsonbody, rota) {
    //  let _path = 'http://localhost:4000/';
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('GET', 'POST', 'OPTIONS');

    console.log("AQUIIII");
    if (jsonbody != null) {
      console.log(jsonbody);
      console.log(API_URL + rota);
      fetch(API_URL + rota, {
        method: 'POST',
        body: jsonbody,
        headers: {
          'Content-Type': 'application/json',
          // Adicione outros cabeçalhos necessários aqui
        }
      })
        .then((response) => {
          if (response.status === 201) {
            alert("Item já existe no banco de dados");
          } else {
            if (!response.ok) {
              if (response.status === 201) {

                alert("Item já existe no banco de dados");
              }
              throw new Error(`Erro na requisição: ${response.status} ${response.statusText}`);
            } else {

              alert("Cadastrado com sucesso");
              return response.json(response);

            }
          }
          return response.json();
        })
        .catch((error) => alert('Erro na requisição: ' + error.message));
      //  .then((json) => console.log(json))
      //  .catch((error) => console.log('Authorization failed: ' + error.message));
    }
  }*/

/*
  async requestPOSTparam(jsonbody, rota) {
    if (!jsonbody) {
      throw new Error("O corpo da requisição não pode ser nulo.");
    }
  
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    });
  
    try {
      console.log("Enviando requisição para:", API_URL + rota);
      console.log("Corpo da requisição:", jsonbody);
  
      const response = await fetch(API_URL + rota, {
        method: 'POST',
        body: jsonbody,
        headers: headers,
      });
  
      // Verifica se a resposta é ok
      if (!response.ok) {
        const errorMessage = `Erro na requisição: ${response.status} ${response.statusText}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
  
      // Retorna o JSON da resposta
      const data = await response.json();
      return data;
  
    } catch (error) {
      console.error("Erro ao realizar a requisição:", error);
      throw new Error('Erro na requisição: ' + error.message);
    }
  }*/
  
  async requestPOSTparam(jsonbody, rota) {
    if (!jsonbody) {
      throw new Error("O corpo da requisição não pode ser nulo.");
    }
  
   /* const headers = new Headers({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }); */

    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('GET', 'POST', 'OPTIONS');

  
    try {
      console.log("Enviando requisição para:", API_URL + rota);
      console.log("Corpo da requisição:", jsonbody);
  
      const response = await fetch(API_URL + rota, {
        method: 'POST',
        body: jsonbody,
        headers: headers,
      });
  
      // Verifica se a resposta é ok
      if (!response.ok) {
        const errorMessage = `Erro na requisição: ${response.status} ${response.statusText}`;
        console.error(errorMessage);
        throw new Error(errorMessage);
      }
  
      // Retorna o JSON da resposta
      const data = await response.json();
      return data;
  
    } catch (error) {
      console.error("Erro ao realizar a requisição:", error);
      
      if (error instanceof TypeError) {
        // Erros de rede ou de CORS
        throw new Error('Erro na requisição: Não foi possível acessar o servidor. Verifique a URL ou a conexão.');
      } else {
        throw new Error('Erro na requisição: ' + error.message);
      }
    }
  }
  


  async requestGETparamEscritorioAdvTextfield(rota) {
    try {
      //    const _path = 'http://localhost:4000/';
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      return fetch(API_URL + rota,
        {
          headers: headers
        }
      )
        .then(async (response) => {
          const jsonData = await response.json();

          // Processa o JSON para criar o novo formato
          const escritoriosAdvogados = jsonData.map(item => ({
            label: item.nomeEscritorio, // Suponho que a propriedade seja nomeEscritorio
            id: item.id, // Suponho que a propriedade seja id
            idPastaRootColegio: item.idPastaColegiosGoogleDrive
          }));

          // Atualiza o estado (ou armazena o valor onde necessário)
          this.setState({ escritoriosAdvogados });
          return escritoriosAdvogados; // Retorna os dados no novo formato
        });
    } catch (error) {
      console.log('Authorization failed: ' + error.message);
      return null; // Retorna nulo em caso de erro
    }
  }


  async requestGETparamDebito(rota, inadimplente, colegio, aluno) {
    try {
      //   const _path = 'http://localhost:4000/';
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      return fetch(API_URL + rota + '/' + colegio + '/' + inadimplente + '/' + aluno,
        {
          method: 'GET',
          headers: headers
        }
      )
        .then(async (response) => {
          const jsonData = await response.json();
          console.log(jsonData);
          return jsonData; // Retorna os dados no novo formato
        });
    } catch (error) {
      console.log('Authorization failed: ' + error.message);
      return null; // Retorna nulo em caso de erro
    }
  }



  async requestGETDebitoGestao(rota) {
    try {
      // const _path = 'http://localhost:4000/';
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      return fetch(API_URL + rota,
        {
          method: 'GET',
          headers: headers
        }
      )
        .then(async (response) => {
          const jsonData = await response.json();
          console.log(jsonData);

          const gestaoDebitos = jsonData.map(item => ({
            id: item.id,
            colegio: item.colegio,
            nomeResponsavelFinanceiro: item.nomeResponsavelFinanceiro,
            aluno: item.aluno,
            endereco: item.endereco,
            cep: item.cep,
            estado: item.estado,
            dataEnvio: item.dataEnvio,
            procuracao: item.procuracao,
            procuracaourl: item.procuracaourl

          }));
          console.log("AQUIIIII");
          console.log(gestaoDebitos);

          return gestaoDebitos; // Retorna os dados no novo formato
        });
    } catch (error) {
      console.log('Authorization failed: ' + error.message);
      return null; // Retorna nulo em caso de erro
    }
  }

  async requestGETparamColegiosTextfield(rota) {
    try {
      //  const _path = 'http://localhost:4000/';
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      return fetch(API_URL + rota,
        {
          method: 'GET',
          headers: headers
        }
      )
        .then(async (response) => {
          const jsonData = await response.json();

          // Processa o JSON para criar o novo formato
          const colegios = jsonData.map(item => ({
            label: item.nomeColegio, // Suponho que a propriedade seja nomeEscritorio
            id: item.id, // Suponho que a propriedade seja id
            idPastaRootColegio: item.idPastaInadimplenteGoogleDrive
          }));

          // Atualiza o estado (ou armazena o valor onde necessário)
          this.setState({ colegios });
          return colegios; // Retorna os dados no novo formato
        });
    } catch (error) {
      console.log('Authorization failed: ' + error.message);
      return null; // Retorna nulo em caso de erro
    }
  }


  async requestGETparamColegiosInadimplentesTextfield(rota, colegio) {
    try {
      // const _path = 'http://localhost:4000/';
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      console.log(API_URL + rota + "/" + colegio);
      return fetch(API_URL + rota + "/" + colegio,
        {
          method: 'GET',
          headers: headers
        }
      )
        .then(async (response) => {
          const jsonData = await response.json();
          console.log("JD");
          console.log(jsonData);
          if (jsonData != null) {
            // const jsonArray = jsonData;

            // Processa o JSON para criar o novo formato
            /*  const inadimplentes = jsonData.map(item => (
            {
               label: item?.nomeResponsavelFinanceiro || '',
               id: item?.id || '',
               idPastaInadimplenteGoogleDrive: item?.idPastaInadimplenteGoogleDrive || ''
             }
             ));*/
            const inadimplentes = new Array();
            for (let i = 0; i < jsonData.length; i++) {
              console.log("jsonData[i]");
              console.log(jsonData[i]);

              console.log(jsonData[i].nomeResponsavelFinanceiro);

              inadimplentes.push({
                label: jsonData[i].nomeResponsavelFinanceiro || '',
                id: jsonData[i].id || '',
                nomeAluno: jsonData[i].nomeAluno  || '',
                colegio: colegio,
                idPastaInadimplenteGoogleDrive: jsonData[i].idPastaInadimplenteGoogleDrive || ''
              })
            }

            console.log("MONTEI O ARRAY ");
            console.log(inadimplentes);

            // Atualiza o estado (ou armazena o valor onde necessário)
            this.setState({ inadimplentes });
            return inadimplentes; // Retorna os dados no novo formato
          }
        });
    } catch (error) {
      console.log('Authorization failed: ' + error.message);
      return null; // Retorna nulo em caso de erro
    }
  }



  async requestGETparamAlunosTextfield(rota, inadimplente, aluno, colegio) {
    try {
      //  const _path = 'http://localhost:4000/';
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');

      console.log(API_URL + rota + "/" + inadimplente + "/" + aluno+ "/" +  colegio);
      return fetch(API_URL + rota + "/" + inadimplente +  "/" + aluno+ "/" +  colegio  ,
        {
          method: 'GET',
          headers: headers
        }
      )
        .then(async (response) => {
          const jsonData = await response.json();
          console.log("JD");
          console.log(jsonData);
          if (jsonData != null) {
            // const jsonArray = [jsonData];
            const alunos = new Array();
            // Processa o JSON para criar o novo formato
            for (let i = 0; i < jsonData.length; i++) {

              
              alunos.push({
                label: jsonData[i].nomeAluno, // Suponho que a propriedade seja nomeEscritorio
                id: jsonData[i].id, // Suponho que a propriedade seja id
              });

            }

            /*  const alunos = jsonArray.map(item => ({
                label: item.nomeAluno, // Suponho que a propriedade seja nomeEscritorio
                id: item.id, // Suponho que a propriedade seja id
              }));*/
              console.log("alunos");

            console.log(alunos);
            // Atualiza o estado (ou armazena o valor onde necessário)
            this.setState({ alunos });
            return alunos; // Retorna os dados no novo formato
          }
        });
    } catch (error) {
      console.log('Authorization failed: ' + error.message);
      return null; // Retorna nulo em caso de erro
    }
  }


  requestGETOne(rota, nomeEscritorio) {

    //  let url = 'http://localhost:4000/'+rota+'/'+nomeEscritorio;
    let url = API_URL + rota + '/' + nomeEscritorio;

    console.log('PASSO 2 - ROTA');
    console.log(API_URL);

    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('GET', 'POST', 'OPTIONS');

    fetch(url, {
      method: 'GET',
      mode: 'no-cors',
      headers: headers,
      allowedHeaders: 'Content-Type'
    })
      .then((response) => response.json())
      .then(json => {
        // Assuming that setaState and getValor are methods of the class
        this.setaState(JSON.stringify(json));
        console.log('JSON.stringify(json)');

        console.log(JSON.stringify(json));

        return JSON.stringify(json);
      })
      .catch((error) => console.log('Authorization failed: ' + error.message));

  }



  requestPUTparam(rotaPUT, objetoAtualizado, nome) {
    console.log("ATUALIZADO OBJ");
    console.log(objetoAtualizado);
    //FAZ O PUT AQUI DENTRO
    // let urlPUT = 'http://localhost:4000/'+rotaPUT+'/'+nome;     
    let urlPUT = API_URL + rotaPUT + '/' + nome;
    console.log(urlPUT);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('PUT', 'POST', 'OPTIONS');

    console.log(objetoAtualizado);

    fetch(urlPUT, {
      method: 'PUT',
      body: objetoAtualizado,
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => console.log("PUT REALIZADO:"+JSON.stringify(json)))
      .catch((error) => console.log('Authorization failed: ' + error.message));



  }

  requestPUTparamDocumentosGeradosAtualizar(rotaPUT) {


    let urlPUT = API_URL + rotaPUT;
    console.log("PUT DO CONTRATO")
    console.log(urlPUT);
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('PUT', 'POST', 'OPTIONS');

    fetch(urlPUT, {
      method: 'PUT',
      headers: headers
    })
      .then((response) => response.json())
      .then((json) => console.log(JSON.stringify(json)))
      .catch((error) => console.log('Authorization failed: ' + error.message));



  }








}

export default requests;
