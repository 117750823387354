import React from "react";
import EscritorioAdvocacia from "../DTO/EscritorioAdvocacia";
import Requests from "../api/requests";


let escritorioAdv = new EscritorioAdvocacia();
let req = new Requests();

export default class CrudAdvogado {

 

    constructor(value) {
        this.escritorioAdv = value;
   }

 /*   buscaEscritorioAPI(nomeEscritorio) {
      let req = new Requests();
     return req.requestGETOne("escritorios",nomeEscritorio);
    } */

    buscaEscritoriosAPI() {
      let req = new Requests();
     return req.requestGETparamEscritorioAdvTextfield("escritorios");
    }



     cadastraEscritorioAdvocaciaAPI(event){
    let req = new Requests();
    console.log(event);
    escritorioAdv = event;
    const { v4: uuidv4 } = require('uuid');
    id: uuidv4(),
    
    req.requestPOSTparam(JSON.stringify({
      id: uuidv4(),      
      nomeEscritorio: escritorioAdv.getNomeEscritorio(),
      logoEscritorio: escritorioAdv.getLogoEscritorio(), 
      enderecoEscritorio: escritorioAdv.getEnderecoEscritorio(), 
      cnpjEscritorio: escritorioAdv.getcnpjEscritorio(), 
      emailEscritorio: escritorioAdv.getemailEscritorio(),
      numeroEscritorio: escritorioAdv.getnumero(),
      cidadeEscritorio: escritorioAdv.getcidade(),
      estadoEscritorio: escritorioAdv.getestado(),
      cepEscritorio: escritorioAdv.getcep(),
      complementoEscritorio: escritorioAdv.getcomplemento()

    }), 'escritorio');
  

  }

  incluiPastasEscritorioAdvocaciaAPI(nomeEscritorio, pastasEscritorio){
    console.log ('PASSO 1 - NOME ESCRITORIO + PASTAS + ESCRITORIO '); 
    console.log (nomeEscritorio); 
    console.log (pastasEscritorio); 
    console.log (escritorioAdv); 



     req.requestPUTparam(  'escritorio', JSON.stringify({
      nomeEscritorio: escritorioAdv.getNomeEscritorio(),
      logoEscritorio: escritorioAdv.getLogoEscritorio(), 
      enderecoEscritorio: escritorioAdv.getEnderecoEscritorio(), 
      cnpjEscritorio: escritorioAdv.getcnpjEscritorio(), 
      emailEscritorio: escritorioAdv.getemailEscritorio(),
      numeroEscritorio: escritorioAdv.getnumero(),
      cidadeEscritorio: escritorioAdv.getcidade(),
      estadoEscritorio: escritorioAdv.getestado(),
      cepEscritorio: escritorioAdv.getcep(), 
      complementoEscritorio: escritorioAdv.getcomplemento(),
      pastaEscritorioGoogleDrive: pastasEscritorio.getpastaEscritorioGoogleDrive(),
      idPastaEscritorioGoogleDrive: pastasEscritorio.getidPastaEscritorioGoogleDrive(),
      logoGoogleDrive:pastasEscritorio.getlogoGoogleDrive(),
      idLogoGoogleDrive:pastasEscritorio.getidLogoGoogleDrive(),
      pastaColegiosGoogleDrive: pastasEscritorio.getpastaColegiosGoogleDrive(),
      idPastaColegiosGoogleDrive:pastasEscritorio.getidPastaColegiosGoogleDrive(),


    }), nomeEscritorio); 
  


  }
  
}