import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { gapi } from 'gapi-script';
import TextField from '@mui/material/TextField';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import { API_URL } from '../../constants';


const UploadImageIco = ({ pastaGoogle, inadimplente }) => {
  const handleFileUpload = (event) => {
    const selectedImage = event.target.files[0];

console.log("inadimplente");
console.log(inadimplente);

console.log("PAsta selecionada");
console.log(pastaGoogle);

console.log("Image selecionada");
console.log(selectedImage);
    if (selectedImage) {
      const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
      const metadata = {
        name: selectedImage.name,
        mimeType: selectedImage.type,
        parents: [pastaGoogle]
      };

      const formData = new FormData();
      formData.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      formData.append('file', selectedImage);

      fetch('https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('File uploaded:', data);

          alert("Arquivo Salvo com sucesso, e Responsavel financeiro enviado para a proxima etapa");
          fetch( API_URL + "receberAR/"+ pastaGoogle , {
            method: 'PUT',
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            body: formData,
          })

          window.location.reload();
        })
        .catch((error) => {
          console.error('Error uploading file:', error);
        });
    }
  };

  return (
    <Box sx={{ justifyContent: "center", width: "100%" }} >
      <br />      <br />
    {/*  <label
        htmlFor="file-upload"
        style={{
          color: '#4CAF50',
          padding: '10px 3x`0px',
          fontSize: '15px',
          border: 1,
          cursor: 'pointer',
          width: '50%',
          marginLeft: 10,
          marginRight: 30
        }}
      >
        <b><a>CLIQUE AQUI</a></b> e selecione uma imagem
        
      </label> */}
      <Button
        variant="outlined"
        color="success"
        startIcon={<UploadFileIcon />}
        component="label"
        htmlFor="file-upload"
        style={{
          width: '70%',
          marginLeft: 10,
          marginRight: 30
        }}
      >
        <b>Upload de Imagem</b>
      </Button>
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={handleFileUpload}
        style={{
          display: 'none'
        }}
      />
    </Box>
  );
};

export default UploadImageIco;
