import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { gapi } from 'gapi-script';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { API_URL } from '../../../constants';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Inadimplente from '../../../DTO/Inadimplente';
import GeraPDF from '../CriaContratos/mesclaDocumentosPDF';



const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES = 'https://www.googleapis.com/auth/documents';

const pdf = new GeraPDF();


export default class MergeAndEditDocument {
//  export default function MergeAndEditDocument(rootURL, colegio, inadimplente, aluno) {


async   mergeDataInDocument(rootURL, colegio, inadimplente, aluno, open) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const rootFolderId = rootURL;
    const fileId = '1upEFJonJ_2cfu64sRGl1hrOFbf0t83rggPPCZjjUz6M';
    const meses = [
      'janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho',
      'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'
    ];
    
    const dataAtual = new Date();
    const dia = dataAtual.getDate();
    const mes = meses[dataAtual.getMonth()];
    const ano = dataAtual.getFullYear();
    
    const dataFormatada = `${dia} de ${mes} de ${ano}`;
  // const contrato = populaContrato(colegio, inadimplente, aluno); // Chama a função dentro do useEffect
     

      
      // Criação do template
    

      const requestData = {
        title: 'Notificação',
        name: '03.NOTIFICACAO',
        parents: [rootFolderId],
      };

      console.log(API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno) ;
      try {
        const resposta = await fetch(API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno, {
          method: 'GET',
          mode: 'cors'
        })
          .then((response) => response.json())
          .then((json) => {

            const contrato = json;
            if (!contrato || !contrato.aluno || !contrato.inadimplente || !contrato.inadimplente.nomeResponsavelFinanceiro) {
              console.log("PROBLEMA NA CHAMADA: " +  API_URL+"contrato/" + colegio + "/" + inadimplente + "/" + aluno );  
              console.log('CONTRATO');
              console.log(contrato);
              return;
            }
                  console.log('CONTRATO');
                  console.log (contrato) ;
                  console.log('DADOS DO COLEGIO');
                  console.log(contrato.colegio.nomeColegio) 
                                ///////////CHAMA API GOOGLEAPI
                                fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
                                  method: 'POST',
                                  headers: new Headers({
                                    Authorization: `Bearer ${accessToken}`,
                                    'Content-Type': 'application/json',
                                  }),
                                  body: JSON.stringify(requestData),
                                })
                                  .then((res) => res.json())
                                  .then((val) => {
                                    console.log(val);

                                    const newDocumentId = val.id; // ID do novo documento criado

                                    // Carregar a API do Google Docs
                                    gapi.client.load('https://docs.googleapis.com/$discovery/rest?version=v1')
                                      .then(() => {
                                        // Substituir variáveis no documento
                                        gapi.client.docs.documents.batchUpdate({
                                          documentId: newDocumentId,
                                        requests: [
                                          /*    {
                                                insertInlineImage: {
                                                  location: {
                                                    index: 1, // O índice onde você deseja inserir a imagem
                                                  },
                                                  uri: 'https://uploaddeimagens.com.br/images/004/621/915/original/Elizabeth_Logo.png?1695942550', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                                                // uri: '../../../../public/Imagens/Elizabeth_Logo.png',
                                                //contentUri: 'https://drive.google.com/file/d/1pEbQd6raRMFBM8XJiZFo4oRQjze0JZA4/view?usp=sharing', // Substitua 'URL_DA_IMAGEM_DO_GOOGLE_DRIVE' pela URL de compartilhamento da imagem no Google Drive
                                                objectSize: {
                                                    width: {
                                                      magnitude: 150, // Largura da imagem em unidades de pixel
                                                      unit: 'PT', // Unidade de medida (pixels)
                                                    },
                                                    height: {
                                                      magnitude: 150, // Altura da imagem em unidades de pixel
                                                      unit: 'PT', // Unidade de medida (pixels)
                                                    },
                                                  },
                                                },
                                              },*/
                                              {
                                                replaceAllText: {
                                                  containsText: {
                                                    text: '<<Logo Escritorio>>', // Texto a ser substituído
                                                    matchCase: false,
                                                  },
                                                  replaceText: " ", // Novo texto a ser inserido
                                                },
                                              },
                                            
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Colegio>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: contrato.colegio.nomeColegio|| "", // Novo texto a ser inserido
                                              },
                                            },
                                           
                                           
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Nome Completo>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: contrato.inadimplente.nomeResponsavelFinanceiro|| "", // Novo texto a ser inserido
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Endereço>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText:  contrato.inadimplente.endereco|| "", // Novo texto a ser inserido
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<número>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText:contrato.inadimplente.numero|| "", // Novo texto a ser inserido
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<complemento>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: contrato.inadimplente.complemento|| "", // Novo texto a ser inserido
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Bairro>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText:  contrato.inadimplente.bairro|| "", // Novo texto a ser inserido
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<CEP>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText:  contrato.inadimplente.cep|| "", // Novo texto a ser inserido
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Cidade>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: contrato.inadimplente.cidade|| "",  
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Estado>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: contrato.inadimplente.estado|| "",  
                                              },
                                            },
                                             
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Escritorio>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: contrato.escritorio.nomeEscritorio|| "",  
                                              },
                                            },
                                            {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<data>>', // Texto a ser substituído
                                                  matchCase: false,
                                                },
                                                replaceText: dataFormatada|| "", // Novo texto a ser inserido
                                              },
                                            },
                                          /*  {
                                              insertInlineImage: {
                                                location: {
                                                  index: 1790, // O índice onde você deseja inserir a imagem
                                                },
                                                 uri: 'https://uploaddeimagens.com.br/images/004/465/674/original/Screenshot_2023-05-14_at_02.43.00.png', // Substitua 'URL_DA_IMAGEM' pela URL da sua imagem
                                              
                                              objectSize: {
                                                  width: {
                                                    magnitude: 400, // Largura da imagem em unidades de pixel
                                                    unit: 'PT', // Unidade de medida (pixels)
                                                  },
                                                  height: {
                                                    magnitude: 30, // Altura da imagem em unidades de pixel
                                                    unit: 'PT', // Unidade de medida (pixels)
                                                  },
                                                },
                                              },
                                            } , */
                                          {
                                              replaceAllText: {
                                                containsText: {
                                                  text: '<<Footer do Escritório>>', // Texto a ser substituído
                                                  matchCase: true,
                                                },
                                                replaceText: '', // Deixe vazio para remover o texto
                                              },
                                            },
                                          ],
                                        }) 
                                          .then(function (response) {
                                            // Manipule a resposta aqui
                                            pdf.mergeDataInDocument(rootURL, response.result.documentId, "O3.NOTIFICACAO")

                                            if(open!= "FECHADO"){
                                            console.log('pagina criaca');
                                            console.log(response.result.documentId);
                                      
                                            const newWindow = window.open("https://docs.google.com/document/d/" + response.result.documentId, '_blank');
                                          //  pdfMake.createPdf("https://docs.google.com/document/d/" + response.result.documentId).open({}, window.open('', '_blank'));


                                            }

                                            console.log("Variável substituída com sucesso", response);
                                          })
                                          .catch(function (err) {
                                            console.error("Erro ao substituir variável", err);
                                          });
                                      });
                                  });
                                      ///////FINALIZA BUSCA API 
                        });
                          return resposta;
                     } catch (error) {
                              console.error(error);
                     }
    }

  /*return (
    <Stack direction="row" spacing={2}>
      <Button variant="outlined" onClick={mergeDataInDocument}>
      <LocalPrintshopIcon />
      </Button>
    </Stack>
  );*/
//}

 }
