export const data = [
    {
      nome: "Mousepad",
      qtdEstoque: 4,
      qtdVendido: 10,
    },
    {
      nome: "Teclado",
      qtdEstoque: 8,
      qtdVendido: 12,
    },
    {
      nome: "Monitor",
      qtdEstoque: 2,
      qtdVendido: 14,
    },
    {
      nome: "Mouse",
      qtdEstoque: 15,
      qtdVendido: 32,
    }
  ];