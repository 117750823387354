import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { TextField, Stack, Divider } from '@mui/material';
import { gapi } from 'gapi-script';
import EscritorioAdvocacia from '../../../DTO/EscritorioAdvocacia.js';
import Box from '@mui/material/Box';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import UploadImage from '../uploadImage.js';
import { cpuUsage } from 'process';
import { Colegio } from '../../../DTO/Colegio.js';
import CrudColegio from '../../../Service/CrudColegio.js';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';

function CreateFolderIDColegio() {

  const [idLogoGoogleDrive, setIdLogoGoogleDrive] = useState('');
  const colegio = new Colegio();
  const escritorioAdvocacia = new EscritorioAdvocacia();
  const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
  const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
  const SCOPES = 'https://www.googleapis.com/auth/drive';

  //BUSCA PARA DO ESCRITORIO COMO ROOT
  function createFolderInRoot() {

    // Autenticação
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

    // Dados da nova pasta
    const newFolderName = colegio.getpastaColegiosGoogleDrive(); // Nome da nova pasta
    //BUSCAR ESCRITORIO 

    const rootFolderId = document.getElementsByName('idRootEscritorio')[0].value;
    colegio.setidRootGoogleDrive(rootFolderId);
    // ID da pasta raiz

    //CRIA PASTA DO COLEGIO
    const requestData = {
      mimeType: 'application/vnd.google-apps.folder',
      name: newFolderName,
      parents: [rootFolderId],
    };
    fetch('https://www.googleapis.com/drive/v3/files', {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((pastaGD) => {
        console.log("ID DA PASTA COLEGIO");
        console.log(pastaGD);
        colegio.setpastaColegiosGoogleDrive(pastaGD.name);
        colegio.setidPastaColegiosGoogleDrive(pastaGD.id);

        //CRIA PASTA DOS LOGOS 
        const requestData = {
          mimeType: 'application/vnd.google-apps.folder',
          name: "Logos " + newFolderName,
          parents: [colegio.getidPastaColegiosGoogleDrive()],
        };

        fetch('https://www.googleapis.com/drive/v3/files', {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(requestData),
        })
          .then((res) => res.json())
          .then((logos) => {
            console.log("ID DA PASTA LOGOS");
            console.log(logos);
            alert("Pasta LOGOS do Colégio criada no Google Drive - " + newFolderName);
            colegio.setidLogoGoogleDrive(logos.id);
            colegio.setlogoGoogleDrive(logos.name);
            setIdLogoGoogleDrive(logos.id);

            //CRIA PASTA DOS INDIMPLENTES VINCULADOS AO ESCRITORIO 

            const requestData = {
              mimeType: 'application/vnd.google-apps.folder',
              name: "INADIMPLENTES",
              parents: [colegio.getidPastaColegiosGoogleDrive()],
            };
            fetch('https://www.googleapis.com/drive/v3/files', {
              method: 'POST',
              headers: new Headers({
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify(requestData),
            })
              .then((res) => res.json())
              .then((cols) => {
                console.log("ID DA PASTA INADIMPLENTES");
                console.log(cols);
                alert("Pasta INADIMPLENTES");
                colegio.setidPastaInadimplenteGoogleDrive(cols.id);
                colegio.setpastaInadimplenteGoogleDrive(cols.name);

                //  const crudescr = new crudEscritorioAdvocacia();
                const crudcolegio = new CrudColegio();
                crudcolegio.incluiPastasColegioAdvocaciaAPI(document.getElementsByName('Colegio')[0].value, colegio)


              })
              .catch((error) => {
                console.error('Error:', error);
              })
          });


      })
      .catch((error) => {
        console.error('Error:', error);
      });

    console.log(escritorioAdvocacia);

  }



  return (
    <Box sx={{
      width: '99%',
      justifyContent: "Center",
      p: 0,
      background: "#f3f6f37d",
    }} >
      <Divider>
        <Chip label="Passo 2" />
      </Divider><br />
      <Box sx={{ justifyContent: "center", width: "100%" }} >
        <Card variant="outlined" sx={{
          width: '100%',
          justifyContent: "left",
          flexDirection: 'row',
          display: 'flex',
        }} >
          <Box sx={{
            width: '80%',
            justifyContent: "left",
            position: "relative",
          }} >
              <Stack spacing={0.4} sx={{ marginLeft: 5, marginRight: 2, marginTop: 2, marginBottom: 2, align: "Center" }}>
              <Box sx={{ justifyContent: "center", width: "100%" }} >
                <h5 sx={{ width: "100%" }}>Crie uma pasta do Colégio:</h5>
                <TextField
                  type="text"
                  variant='standard'
                  color='secondary'
                  label="Nome da Pasta do Colégio"
                  onChange={e => colegio.setpastaColegiosGoogleDrive(e.target.value)}
                  value={colegio.getpastaColegiosGoogleDrive()}
                  sx={{ marginTop: 0, marginLeft: 0, justifyContent: "center", width: "60%" }}
                />
                <Button variant="outlined" startIcon={<CreateNewFolderIcon />} onClick={() => createFolderInRoot()}
                  sx={{ marginTop: 0, marginLeft: 1, justifyContent: "center", width: "20%" }} color="success">
                  Cria Pasta
                </Button>
              </Box>
            </Stack>
            <Box sx={{ align: "middle", width: "100%" }} >
              <TextField
                label="ID do Logo no Google Drive"
                variant="standard"
                name="idLogo"
                value={idLogoGoogleDrive}
                sx={{ display: "none" }}
                fullWidth
                disabled // Desabilita a edição
              />
            </Box></Box></Card>
        <br /> <br />
        <Divider>
          <Chip label="Passo 3" />
        </Divider><br />
        <Box sx={{ justifyContent: "center", width: "100%" }} >
          <Card variant="outlined" sx={{
            width: '100%',
            justifyContent: "left",
            flexDirection: 'row',
            display: 'flex',
          }} >
            <Box sx={{
              width: '80%',
              justifyContent: "left",
              position: "relative",
            }} >
              <Stack spacing={0.4} sx={{ marginLeft: 5, marginRight: 2, marginTop: 2, marginBottom: 2, align: "Center" }}>
                <Box sx={{ justifyContent: "center", width: "100%" }} >
                  <h5 sx={{ width: "100%" }}>Selecione o arquivo e faça upload no logo do Colégio:</h5>
                   <UploadImage />
                   <p style={{  width: "100%", marginLeft: 15}}>Dimensões: 300px X 200px</p> 
                </Box></Stack></Box></Card></Box>
      </Box>
    </Box>
  );
}

export default CreateFolderIDColegio;
