import React from "react";
import { Advogado } from '../DTO/Advogado.js';
import Requests from "../api/requests.js";
import Inadimplente from '../DTO/Inadimplente.js';

let ina = new Inadimplente();
let req = new Requests(); 

export default class CrudInadimplente {

 

    constructor(value) {
        this.ina = value;
   }


cadastraInadimplenteAPI(value) {
const { v4: uuidv4 } = require('uuid');

const id =  uuidv4();
ina = value;
 req.requestPOSTparam(JSON.stringify({
id: id,
nomeResponsavelFinanceiro: ina.getnomeResponsavelFinanceiro().trim(),
colegio: ina.getcolegio(),
whatsapp: ina.getwhatsapp(),
telefone: ina.gettelefone(),
nacionalidade: ina.getnacionalidade(),
rg: ina.getrg(),
cpf: ina.getcpf(),
endereco: ina.getendereco(),
numero: ina.getnumero(),
complemento: ina.getcomplemento(),
bairro: ina.getbairro(),
cep: ina.getcep(),
cidade: ina.getcidade(),
estado: ina.getestado(),
email: ina.getemail(),
genero: ina.getgenero(),
estadoCivil: ina.getestadoCivil(),
profissao: ina.getprofissao(),
endereco2: ina.getendereco2(),
foro: ina.getforo()
}), 'inadimplente');

console.log("CRIACAO INADIMPLENTE");
console.log(id);
return id;
  }



  buscacolegioAPI() {
    let req = new Requests();
    return req.requestGETparamColegiosTextfield("colegios");
  }



  incluiPastasInadimplenteAdvocaciaAPI(nomeInadimplente, pastasInadimplente){
    console.log ('PASSO 1 - NOME COLEGIO + PASTAS + COLEGIO '); 
    console.log (nomeInadimplente); 
    console.log (pastasInadimplente); 
    ina = pastasInadimplente;
    console.log (ina); 

   req.requestPUTparam(  'inadimplente', JSON.stringify({
    id: ina.getid(),
    nomeResponsavelFinanceiro: ina.getnomeResponsavelFinanceiro(),
    whatsapp: ina.getwhatsapp(), 
    telefone: ina.gettelefone(), 
    nacionalidade : ina.getnacionalidade(),
    rg: ina.getrg(),
    cpf: ina.getcpf(),
    endereco: ina.getendereco(),
    numero: ina.getnumero(),
    complemento: ina.getcomplemento(),
    bairro: ina.getbairro(),
    cep: ina.getcep(),
    cidade:ina.getcidade(),
    estado: ina.getestado(),
    email: ina.getemail(),
    genero: ina.getgenero(),
    estadoCivil: ina.getestadoCivil(),
    profissao: ina.getprofissao(),
    endereco2: ina.getendereco2(),
    idRootGoogleDrive: pastasInadimplente.getidRootGoogleDrive(),
    pastaInadimplenteGoogleDrive: pastasInadimplente.getpastaInadimplenteGoogleDrive(),
    idPastaInadimplenteGoogleDrive: pastasInadimplente.getidPastaInadimplenteGoogleDrive(),
    }), ina.getnomeResponsavelFinanceiro());  

 
 
  }

}