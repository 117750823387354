import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { TextField, Stack, Divider } from '@mui/material';
import { gapi } from 'gapi-script';
import EscritorioAdvocacia from '../../../DTO/EscritorioAdvocacia.js';
import Box from '@mui/material/Box';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import crudEscritorioAdvocacia from '../../../Service/CrudEscritorio.js';
import UploadImage from '../uploadImage.js';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import { FormControl } from '@mui/base/FormControl';
import InputAdornment from '@mui/material/InputAdornment';

function CreateFolderButton() {

  const [idLogoGoogleDrive, setIdLogoGoogleDrive] = useState('');
  const escritorioAdvocacia = new EscritorioAdvocacia();

  const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
  const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
  const SCOPES = 'https://www.googleapis.com/auth/drive';


  function createFolderInRoot() {

    // Autenticação
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;

    // Dados da nova pasta
    const newFolderName = escritorioAdvocacia.getpastaEscritorioGoogleDrive(); // Nome da nova pasta
    const rootFolderId = '1-BIpg7E28M1atjcSWBZro8vK6lmAVsoZ'; // ID da pasta raiz

    //CRIA PASTA DO ESCRITORIO
    const requestData = {
      mimeType: 'application/vnd.google-apps.folder',
      name: newFolderName,
      parents: [rootFolderId],
    };
    fetch('https://www.googleapis.com/drive/v3/files', {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((pastaGD) => {
        console.log("ID DA PASTA ESCRITORIO");
        console.log(pastaGD);
        escritorioAdvocacia.setpastaEscritorioGoogleDrive(pastaGD.name);
        escritorioAdvocacia.setidPastaEscritorioGoogleDrive(pastaGD.id);

        //CRIA PASTA DOS LOGOS 
        const requestData = {
          mimeType: 'application/vnd.google-apps.folder',
          name: "Logos do Escritório de Advocacia - " + newFolderName,
          parents: [escritorioAdvocacia.getidPastaEscritorioGoogleDrive()],
        };

        fetch('https://www.googleapis.com/drive/v3/files', {
          method: 'POST',
          headers: new Headers({
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          }),
          body: JSON.stringify(requestData),
        })
          .then((res) => res.json())
          .then((logos) => {
            console.log("ID DA PASTA LOGOS");
            console.log(logos);
            alert("Pasta de LOGOS do escritório foi criada no Google Drive");
            escritorioAdvocacia.setidLogoGoogleDrive(logos.id);
            escritorioAdvocacia.setlogoGoogleDrive(logos.name);
            setIdLogoGoogleDrive(logos.id);

            //CRIA PASTA DOS COLEGIOS VINCULADOS AO ESCRITORIO 

            const requestData = {
              mimeType: 'application/vnd.google-apps.folder',
              name: "Colégios",
              parents: [escritorioAdvocacia.getidPastaEscritorioGoogleDrive()],
            };
            fetch('https://www.googleapis.com/drive/v3/files', {
              method: 'POST',
              headers: new Headers({
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              }),
              body: JSON.stringify(requestData),
            })
              .then((res) => res.json())
              .then((cols) => {
                console.log("ID DA PASTA COLEGIOS");
                console.log(cols);
                alert("Pasta dos COLEGIOS vinculados a esse escritório foi criada no Google Drive");
                escritorioAdvocacia.setidPastaColegiosGoogleDrive(cols.id);
                escritorioAdvocacia.setpastaColegiosGoogleDrive(cols.name);

                //CHAMA AQUI A API
                console.log(document.getElementsByName('Escritorio')[0].value);
                const crudescr = new crudEscritorioAdvocacia();
                crudescr.incluiPastasEscritorioAdvocaciaAPI(document.getElementsByName('Escritorio')[0].value, escritorioAdvocacia);


              })
              .catch((error) => {
                console.error('Error:', error);
              })
          });


      })
      .catch((error) => {
        console.error('Error:', error);
      });

    console.log(escritorioAdvocacia);

  }

  return (
    <Box sx={{
      width: '100%',
      justifyContent: "Center",
      p: 1,
    }} >
      <Divider>
        <Chip label="Passo 2" />
      </Divider><br />
      <Box sx={{ justifyContent: "center", width: "100%" }} >
        <Card variant="outlined" sx={{
          width: '100%',
          justifyContent: "left",
          flexDirection: 'row',
          display: 'flex',
        }} >
          <Box sx={{
            width: '80%',
            justifyContent: "left",
            position: "relative",
          }} > 
            <FormControl defaultValue="">
              <Stack spacing={0.4} sx={{ marginLeft: 5, marginRight: 2, marginTop: 2, marginBottom: 2, align: "Center" }}>
                <h5 >Crie uma pasta do Escritório:</h5>
                <br />
                <Box sx={{ align: "middle", width: "100%" }} >
                  <TextField
                    type="text"
                    variant='standard'
                    color='secondary'
                    label="Nome da Pasta do Escritório"
                    onChange={e => escritorioAdvocacia.setpastaEscritorioGoogleDrive(e.target.value)}
                    value={escritorioAdvocacia.getpastaEscritorioGoogleDrive()}
                    sx={{ marginTop: 2, marginLeft: 0, justifyContent: "center", width: "60%" }}
                  />
                  <Button size="small" variant="outlined" startIcon={<CreateNewFolderIcon />} onClick={() => createFolderInRoot()}
                    sx={{ marginTop: 2, marginLeft: 1, justifyContent: "center", width: "20%" }} color="success">
                    Cria Pasta
                  </Button>
                  <TextField
                    label="ID do Logo no Google Drive"
                    variant="standard"
                    sx={{display: "none"}}
                    name="idLogo"
                    value={idLogoGoogleDrive}
                    fullWidth
                    disabled // Desabilita a edição
                  />
                </Box>
              </Stack>
            </FormControl>
          </Box>
        </Card></Box>
      <br /><br />
      <Divider  >
        <Chip label="Passo 3 " />
      </Divider> <br />
      <Box sx={{ justifyContent: "center", width: "100%" }} >
        <Card variant="outlined" sx={{
          width: '100%',
          justifyContent: "left",
          flexDirection: 'row',
          display: 'flex',
        }} >
          <Box sx={{
            width: '80%',
            justifyContent: "left",
            position: "relative",
          }} >
            <FormControl defaultValue="">
              <Stack spacing={0.4} sx={{ marginLeft: 5, marginRight: 2, marginTop: 2, marginBottom: 2, align: "Center" }}>
                <h5 sx={{ width: "100%" }}>Selecione o arquivo e faça upload no logo do Escritório:</h5>
                <UploadImage />
                <p sx={{ display: "flex", width: "30%", marginLeft:5 }}>Dimensões: 300px X 200px</p> 
              </Stack></FormControl>  </Box></Card></Box>
    </Box>
  );
}

export default CreateFolderButton;
