import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { gapi } from 'gapi-script';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { API_URL } from '../../../constants';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Inadimplente from '../../../DTO/Inadimplente';



const CLIENT_ID = "440613332756-5pia3b0tvcau1gvdjo3u5ht2lu8u1jk9.apps.googleusercontent.com";
const API_KEY = "AIzaSyD0hs5KvW7gPnAkCG-tA6sQwoHRxlaQNVY";
const SCOPES = 'https://www.googleapis.com/auth/documents';
let colegio;

export default class MergeAndEditDocument {
  async mergeDataInDocument(params, open, colegio) {
    const accessToken = gapi.auth2.getAuthInstance().currentUser.get().getAuthResponse().access_token;
    const fileId = '1ra2nLRTnxIgv7dA_IO2PJk6EcP67NNSybEzf15AVAGU';
  

    console.log(params);

    let req = new Array();

    for (let i = 0; i < params.length; i++) {
      if (!(params[i].row.colegio && params[i].row.nomeResponsavelFinanceiro && params[i].row.aluno)) {
        console.log("Dados insuficientes para gerar o relatório.");
        continue;
      }

     // colegio = params[0].row.colegio;

      console.log(API_URL + "contrato/" + params[i].row.colegio + "/" + params[i].row.nomeResponsavelFinanceiro + "/" + params[i].row.aluno);
      const resposta = await fetch(API_URL + "contrato/" + params[i].row.colegio + "/" + params[i].row.nomeResponsavelFinanceiro + "/" + params[i].row.aluno, {
        method: 'GET',
        mode: 'cors'
      })
        .then((response) => response.json())
        .then((json) => {
          let contrato = json;
          if (!contrato || !contrato.aluno || !contrato.inadimplente || !contrato.inadimplente.nomeResponsavelFinanceiro) {
            console.log("PROBLEMA NA CHAMADA: " +  API_URL+"contrato/" + params[i].row.colegio + "/" + params[i].row.nomeResponsavelFinanceiro + "/" + params[i].row.aluno);  
            return;
          }

          req.push(
            {
              replaceAllText: {
                containsText: {
                  text: '<<id' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: ""+i,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<nome'+[i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.nomeResponsavelFinanceiro,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<numero_aluno' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.aluno.codigoAluno,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<valor_primitivo' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.debito.total_valor_primitivo,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<custas_pagas' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: " ",
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<debito_atualizado' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.debito.total_debito_atualizado,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<nome_aluno' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.aluno.nomeAluno,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<endereco' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.endereco,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<numero' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.numero,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<complemento' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.complemento,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<bairro' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.bairro,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<cidade' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.cidade,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<Estado' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.estado,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<cep' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.cep,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<email' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.email,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<profissao' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.profissao,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<rg' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.rg,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<cpf' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.cpf,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<telefone' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.telefone,
              },
            },
            {
              replaceAllText: {
                containsText: {
                  text: '<<whatsapp' + [i] + '>>',
                  matchCase: false,
                },
                replaceText: contrato.inadimplente.whatsapp,
              },
            });
        })
    }
    
    console.log(req);

    const requestData = {
      title: 'Relatorio - '+colegio,
      name: 'Relatorio - ' + colegio,
    //  parents: ['1q8bqAKT2rWWd4YS8wox5sxeJKkOUGfwM'],
      parents: ['1Huk5xvm_WynB7xaUg7W_6CeqWXnf7ghW'],
    };

    fetch(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(requestData),
    })
      .then((res) => res.json())
      .then((val) => {
        const newDocumentId = val.id;
        gapi.client.load('https://docs.googleapis.com/$discovery/rest?version=v1')
          .then(() => {
            gapi.client.docs.documents.batchUpdate({
              documentId: newDocumentId,
              requests: req
            })
              .then(function (response) {
                if (open != "FECHADO") {
                  const newWindow = window.open("https://docs.google.com/document/d/" + response.result.documentId, '_blank');
                }
                console.log("Variável substituída com sucesso", response);
              })
              .then(function (response) {
                console.log("documento", newDocumentId);
                  console.log("params:" , params);
                for (let i = 0; i < params.length; i++) {
                  if (!(params[i].row.colegio && params[i].row.nomeResponsavelFinanceiro && params[i].row.aluno)) {
                    console.log("Dados insuficientes para gerar o relatório.");
                    continue;
                  }
                
                const resp = fetch(API_URL + "inadimplentes/" + params[i].row.colegio  
                                  + "/" + params[i].row.nomeResponsavelFinanceiro 
                                  + "/" + params[i].row.aluno 
                                  + "/" + newDocumentId, {
                  method: 'PUT', // Especificando o método PUT
                  headers: {
                    'Content-Type': 'application/json', // Especificando o tipo de conteúdo como JSON, se necessário
                    // Aqui você pode adicionar outros headers, se necessário
                  },
                  // Aqui você pode adicionar o corpo da requisição, se necessário
                });
        
              }
              })
          });
      })
      
  }
}
